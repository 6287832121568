<template>
  <div v-if="showModal" class="modal-overlay">
    <div class="modal-dialog">
      <div class="modal-content">
        <h4 class="modal-title">{{ modalTitle }}</h4>
        <hr/>
        <form class="row g-3 p-3">
          <div class="form-group row">
            <label class="col-sm-2 col-form-label">{{ t('MONITOR.ELE_FENCE[1]') }}</label>
            <div class="col-sm-10" v-if="showLabel">
              <label class="col-form-label d-flex">{{ modalData.deviceSerialNumber }}</label>
            </div>
            <div class="col-sm-10" v-else>
              <select class="form-select" v-model="selectDeviceId">
                <option v-for="(device, index) in targetOptions" :key="index" :value="device.deviceId">{{ device.deviceSerialNumber }}{{ deviceServiceExpiredHint(device) }}</option>
              </select>
            </div>
          </div>
          <div class="form-group row mt-2">
            <label class="col-sm-2 col-form-label">{{ t('MONITOR.MARKED_POINT_TABLES[2]') }}</label>
            <div class="col-sm-4">
              <select class="form-select" v-model="targetType">
                <option v-for="(value, index) in configData.targetType" :key="index" :value="index">{{ t(value) }}</option>
              </select>
            </div>
            <div class="col-sm-5" v-if="targetType === 2">
              <input type="text" class="col-sm-5 form-control" v-model="targetName">
            </div>
          </div>
          <div class="form-group row mt-2" v-if="enableAddressToGPS">
            <label class="col-sm-2 col-form-label">{{ t('MONITOR.MODAL_MARKER_TYPE') }}</label>
            <div class="col">
              <select class="form-select" v-model="markerPointType">
                <option v-for="(value, index) in configData.markerPointType" :key="index" :value="index">{{ t(value) }}</option>
              </select>
            </div>
          </div>
          <div class="form-group row mt-2" v-if="markerPointType === 1">
            <label class="col-sm-2 col-form-label">{{ t('MONITOR.MARKER_POINT') }}</label>
            <div class="col">
              <input type="text" class="col-sm-5 form-control" :placeholder="t('MONITOR.ADDREDS_TO_GPS_HINT')" v-model="addressName">
            </div>
            <button type="button" class="col-sm-2 btn btn-primary addressToGPS" :disabled="isExpired" @click="onCovertToGPSClickEvent">{{ t('GENERAL.ADDRESS_TO_GPS') }}</button>
          </div>
          <div class="form-group row mt-2" v-if="markerPointType === 0">
            <label class="col-sm-2 col-form-label">{{ t('MONITOR.MARKED_POINT_TABLES[3]') }}</label>
            <div class="col">
              <input type="text" class="col-sm-5 form-control" v-model="inputLocate" disabled :placeholder="t('MONITOR.DRAW_MAP_HINT')">
            </div>
            <button type="button" class="col-sm-2 btn btn-primary" :disabled="isExpired" @click="onDrawMapClickEvent">{{ t('GENERAL.DRAW_MAP') }}</button>
          </div>
          <div class="form-group row mt-2">
            <label class="col-sm-2 col-form-label">{{ t('MONITOR.MARKED_POINT_TABLES[4]') }}</label>
            <div class="col">
              <textarea class="form-control inputTextArea" id="description" rows="5" maxlength="280" v-model="noteContent"/>
            </div>
          </div>
        </form>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" @click="onCancelClickEvent">{{ t('GENERAL.CLOSE') }}</button>
          <button type="button" class="btn btn-primary" :disabled="isExpired" @click="onSubmitClickEvent">{{ submitButtonName }}</button>
        </div>
      </div>
    </div>
  </div>
  <modal-marker-point-map ref="ModalDrawMarkerPointDialog" @modalCallback="onMarkerMapCallback"/>
</template>

<script>
import { ref } from '@vue/reactivity'
import { useI18n } from 'vue-i18n'
import { computed, inject, watch } from '@vue/runtime-core'
import { useStore } from 'vuex'
import ModalMarkerPointMap from '../ModalMarkerPointMap.vue'
export default {
  components: { ModalMarkerPointMap },
  emits: ['modalCallback'],
  setup (props, { emit }) {
    const { t } = useI18n()
    const store = useStore()
    const configData = inject('configData')
    const tools = inject('tools')

    const ModalDrawMarkerPointDialog = ref()

    const showModal = ref(false)
    const isEditor = ref(false)
    const isExpired = ref(false)

    const showLabel = computed(() => isEditor.value)

    const enableAddressToGPS = computed(() => store.getters['setting/getEnableAddressToGPS'])

    const modalTitle = computed(() => {
      return (isEditor.value ? t('GENERAL.EDITOR') : t('GENERAL.ADD')) + t('MONITOR.MODAL_MARKER_POINT')
    })

    const submitButtonName = computed(() => {
      return isEditor.value ? t('GENERAL.SAVE') : t('GENERAL.ADD')
    })

    const targetOptions = computed(() => store.getters['clientAccount/accountDeviceList'])
    const selectDeviceId = ref(0)
    const targetType = ref(0)
    const targetName = ref('')
    const markerPointType = ref(0)
    const addressName = ref('')
    const inputLocate = computed(() => {
      return (locatePosition.value.latitude === 0 && locatePosition.value.longitude === 0) ? '' : locatePosition.value.latitude + ',' + locatePosition.value.longitude
    })
    const locatePosition = ref({
      latitude: 0,
      longitude: 0
    })
    const noteContent = ref('')

    const errorContent = ref('')

    const modalData = ref({})

    // const disableDrawMapWithExpired = computed(() => {
    //   const device = targetOptions.value.find(item => item.deviceSerialNumber === modalData.value.deviceSerialNumber)
    //   return tools.CheckDeviceExpired(device)
    // })

    function checkSelectDeviceExpired () {
      const device = targetOptions.value.find(item => item.deviceId === selectDeviceId.value)
      isExpired.value = tools.CheckDeviceExpired(device)
    }

    function deviceServiceExpiredHint (device) {
      return tools.CheckDeviceExpired(device) ? t('MODAL.SERVICE_EXPIRED') : ''
    }

    function showModalWithData (editor, data) {
      isEditor.value = editor
      if (isEditor.value) {
        setModalData(data)
      } else {
        clearModalData()
      }
      showModal.value = true
    }

    function showModalWithDeviceId (deviceId) {
      isEditor.value = false
      clearModalData()
      selectDeviceId.value = deviceId
      showModal.value = true
    }

    function clearModalData () {
      modalData.value = {}
      locatePosition.value.latitude = 0
      locatePosition.value.longitude = 0
      markerPointType.value = 0
      selectDeviceId.value = targetOptions.value[0].deviceId
      targetType.value = 0
      targetName.value = ''
      noteContent.value = ''
    }

    function setModalData (item) {
      modalData.value = Object.assign({}, item)
      locatePosition.value.latitude = modalData.value.latitude ?? 0
      locatePosition.value.longitude = modalData.value.longitude ?? 0
      markerPointType.value = 0
      targetType.value = 2
      targetName.value = modalData.value.alias ?? ''
      noteContent.value = modalData.value.remark ?? ''
    }

    function onModalCancel () {
      showModal.value = false
    }

    function onModalSubmit () {
      if (locatePosition.value.latitude === 0 || locatePosition.value.longitude === 0) {
        return
      }
      const data = {
        alias: targetType.value === 2 ? targetName.value : t(configData.targetType[targetType.value]),
        latitude: locatePosition.value.latitude,
        longitude: locatePosition.value.longitude,
        remark: noteContent.value
      }
      if (isEditor.value) {
        modalData.value = { ...modalData.value, ...data }
      }
      emit('modalCallback', isEditor.value, isEditor.value ? modalData.value.id : selectDeviceId.value, isEditor.value ? modalData.value : data)
      showModal.value = false
    }

    function onDrawMapClickEvent () {
      ModalDrawMarkerPointDialog.value.showModalWithData((locatePosition.value.latitude !== 0 && locatePosition.value.longitude !== 0), locatePosition.value)
    }

    function getDeviceSerialNumber () {
      let device = targetOptions.value.find(item => item.deviceId === selectDeviceId.value)
      if (!device) {
        device = Object.assign({}, { deviceSerialNumber: targetOptions.value[0].deviceSerialNumber })
      }
      return device.deviceSerialNumber
    }

    function onCovertToGPSClickEvent () {
      if (addressName.value.length > 0) {
        errorContent.value = ''
        const addressData = {
          deviceSerialNumber: getDeviceSerialNumber(),
          address: addressName.value
        }
        store.dispatch('covertAddressToGps', addressData).then((res) => {
          locatePosition.value.latitude = res.data.latitude
          locatePosition.value.longitude = res.data.longitude
          markerPointType.value = 0
        }).catch((err) => {
          errorContent.value = err.errMsg
        })
      } else {
        errorContent.value = t('ERROR_MSG.MARKER_ADDRESS_TO_GPS_EMPTY')
      }
    }

    function onMarkerMapCallback (markerData) {
      locatePosition.value = Object.assign({}, markerData)
    }

    watch(() => selectDeviceId.value, () => {
      checkSelectDeviceExpired()
    })

    return {
      t,
      enableAddressToGPS,
      ModalDrawMarkerPointDialog,
      deviceServiceExpiredHint,
      isExpired,
      showLabel,
      modalData,
      configData,
      targetType,
      markerPointType,
      locatePosition,
      inputLocate,
      targetName,
      noteContent,
      selectDeviceId,
      targetOptions,
      showModal,
      modalTitle,
      addressName,
      submitButtonName,
      // disableDrawMapWithExpired,
      showModalWithData,
      onModalCancel,
      onModalSubmit,
      onDrawMapClickEvent,
      onCovertToGPSClickEvent,
      onMarkerMapCallback,
      showModalWithDeviceId
    }
  },
  methods: {
    onCancelClickEvent () {
      this.onModalCancel()
    },
    onSubmitClickEvent () {
      this.onModalSubmit()
    }
  }
}
</script>

<style lang="stylus" scoped>
.addressToGPS {
  font-size: 0.8rem
}
</style>
