<template>
  <div v-if="showModal" class="modal-overlay">
    <div class="modal-dialog">
      <div class="modal-content">
        <h4 class="modal-title">{{ t('MONITOR.DRAW_ELECTONIC_FENCE') }}</h4>
        <hr/>
        <form class="row g-3 p-3">
          <div class="form-group row justify-content-md-center">
            <label class="col-sm-2 col-form-label">{{ t('MONITOR.DRAW_TYPE_TITLE') }}</label>
            <div class="col-sm-5">
              <select class="form-select" v-model="selectDrawType" :disabled="disableSelect" @change="onSelectChangedEvent">
                <option v-for="(type, index) in configData.drawElectronicFenceType" :key="index" :value="index">{{ t(type) }}</option>
              </select>
            </div>
            <button v-if="enableMapDraw" type="button" class="col-sm-1 btn btn-warning" @click="onDrawClickEvent">{{ t('GENERAL.FINISH') }}</button>
            <button v-else type="button" class="col-sm-1 btn btn-primary" @click="onDrawClickEvent">{{ t('GENERAL.DRAW_MAP') }}</button>
          </div>
          <div class="row mt-2">
            <div ref="MapTalksMap" class="base-map justify-content-center mx-3 mapContent" id="modal-map"></div>
          </div>
        </form>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" :disabled="enableMapDraw" @click="onCancelClickEvent">{{ t('GENERAL.CLOSE') }}</button>
          <button type="button" class="btn btn-primary" :disabled="disableSumbitButton" @click="onSubmitClickEvent">{{ t('GENERAL.SAVE') }}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, ref } from '@vue/reactivity'
import { useI18n } from 'vue-i18n'
import { computed, inject } from '@vue/runtime-core'
import { Loader } from '@googlemaps/js-api-loader'
import { getDistance, getCenter } from 'geolib'
import { useStore } from 'vuex'
export default {
  emits: ['modalCallback'],
  setup (props, { emit }) {
    const { t } = useI18n()
    const store = useStore()
    const configData = inject('configData')

    const modalData = ref({})

    const showModal = ref(false)
    const isEditor = ref(false)
    const selectDrawType = ref(0)

    const disableSumbitButton = computed(() => {
      switch (selectDrawType.value) {
        case 0:
          return fenceRadius.value === 0 || enableMapDraw.value
        case 1:
          return fenceCoords.value.length < 3 || enableMapDraw.value
      }
    })

    const enableMapDraw = ref(false)
    const disableSelect = computed(() => enableMapDraw.value || isEditor.value)

    const mapCenter = computed(() => store.getters['mapData/mapCenter'])
    const mapZoom = computed(() => store.getters['mapData/mapZoom'])

    const fenceCircle = ref()
    const fenceCenter = ref({})
    const fenceSecond = ref({})
    const fenceRadius = ref(0)

    const fencePolygon = ref()
    const fenceCoords = ref([])
    const fenceMarkers = ref([])

    const mapStates = reactive({
      google: null,
      map: null,
      markers: null
    })

    const initMap = async () => {
      const loader = new Loader({
        apiKey: configData.googleMapAPIKey,
        version: 'weekly',
        libraries: ['places'],
        language: 'zh-TW'
      })
      mapStates.google = await loader.load()
      mapStates.map = new mapStates.google.maps.Map(document.getElementById('modal-map'), {
        clickableIcons: false,
        center: mapCenter.value,
        zoom: mapZoom.value,
        disableDefaultUI: true
      })
      fenceCircle.value = new mapStates.google.maps.Circle({
        strokeColor: '#FF0000',
        strokeOpacity: 0.5,
        strokeWeight: 2,
        fillColor: '#FF0000',
        fillOpacity: 0.25,
        clickable: false
      })
      fencePolygon.value = new mapStates.google.maps.Polygon({
        strokeColor: '#FF0000',
        strokeOpacity: 0.5,
        strokeWeight: 2,
        fillColor: '#FF0000',
        fillOpacity: 0.25,
        clickable: false
      })
      if (isEditor.value) {
        switch (selectDrawType.value) {
          case 0:
            drawCircle()
            break
          case 1:
            drawPolygonPath()
            break
        }
      }
    }

    function drawCircle () {
      const latLng = new mapStates.google.maps.LatLng(modalData.value.data.coordinates[0])
      mapStates.map.setCenter(latLng)
      mapStates.map.setZoom(12)
      fenceCircle.value.setMap(mapStates.map)
      fenceCircle.value.setCenter(latLng)
      fenceCircle.value.setOptions({ radius: modalData.value.data.radius })
    }

    function drawPolygonPath () {
      clearPolygonFence()
      modalData.value.data.coordinates.forEach(coord => {
        const latLng = new mapStates.google.maps.LatLng(coord)
        fenceCoords.value.push(latLng)
        const marker = new mapStates.google.maps.Marker({
          position: latLng,
          map: mapStates.map
        })
        fenceMarkers.value.push(marker)
      })
      fencePolygon.value.setPath(fenceCoords.value)
      fencePolygon.value.setMap(mapStates.map)
      const center = getCenter(modalData.value.data.coordinates)
      mapStates.map.setZoom(12)
      mapStates.map.setCenter(new mapStates.google.maps.LatLng(center.latitude, center.longitude))
    }

    function onDrawClickEvent () {
      switch (selectDrawType.value) {
        case 0:
          onDrawCircleFence()
          break
        case 1:
          onDrawPolygonFence()
          break
      }
      enableMapDraw.value = !enableMapDraw.value
    }

    function onSelectChangedEvent (event) {
      clearCircleFence()
      clearPolygonFence()
    }

    function clearPolygonFence () {
      fenceCoords.value.length = 0
      fencePolygon.value.setPath(fenceCoords.value)
      fenceMarkers.value.forEach(marker => {
        marker.setVisible(false)
        marker.setMap(null)
      })
      fenceMarkers.value.length = 0
    }

    function onDrawPolygonFence () {
      if (enableMapDraw.value) {
        mapStates.google.maps.event.clearListeners(mapStates.map, 'click')
        mapStates.map.setOptions({ gestureHandling: 'auto' })
      } else {
        clearCircleFence()
        clearPolygonFence()
        fenceCoords.value.length = 0
        mapStates.map.addListener('click', (mapsMouseEvent) => {
          const marker = new mapStates.google.maps.Marker({
            position: mapsMouseEvent.latLng,
            map: mapStates.map
          })
          fenceMarkers.value.push(marker)
          mapStates.map.setOptions({ gestureHandling: 'none' })
          fenceCoords.value.push(mapsMouseEvent.latLng.toJSON())
          fencePolygon.value.setPath(fenceCoords.value)
          fencePolygon.value.setMap(mapStates.map)
        })
      }
    }

    function clearCircleFence () {
      fenceCircle.value.setMap(null)
      fenceRadius.value = 0
      fenceCenter.value = {}
    }

    function onDrawCircleFence () {
      if (enableMapDraw.value) {
        mapStates.google.maps.event.clearListeners(mapStates.map, 'mousedown')
        mapStates.google.maps.event.clearListeners(mapStates.map, 'mouseup')
      } else {
        clearCircleFence()
        clearPolygonFence()
        mapStates.map.addListener('mouseup', () => {
          mapStates.map.setOptions({ gestureHandling: 'auto' })
          mapStates.google.maps.event.clearListeners(mapStates.map, 'mousemove')
        })
        mapStates.map.addListener('mousedown', (mapsMouseEvent) => {
          mapStates.map.setOptions({ gestureHandling: 'none' })
          fenceCenter.value = Object.assign({}, mapsMouseEvent.latLng.toJSON())
          fenceCircle.value.setCenter(mapsMouseEvent.latLng.toJSON())
          fenceCircle.value.setMap(mapStates.map)
          fenceCircle.value.setOptions({ radius: 0 })
          mapStates.map.addListener('mousemove', (moveEvent) => {
            fenceSecond.value = Object.assign({}, moveEvent.latLng.toJSON())
            fenceRadius.value = getDistanceByGeo(moveEvent.latLng.toJSON())
            fenceCircle.value.setOptions({ radius: fenceRadius.value })
          })
        })
      }
    }

    function getDistanceByGeo (geoPos) {
      return getDistance(
        { latitude: fenceCenter.value.lat, longitude: fenceCenter.value.lng },
        { latitude: geoPos.lat, longitude: geoPos.lng }
      )
    }

    function showModalWithData (editor, data) {
      isEditor.value = editor
      if (isEditor.value) {
        modalData.value = Object.assign({}, data.value)
        selectDrawType.value = data.value.fenceType - 1
      }
      showModal.value = true
      initMap()
    }

    function onModalCancel () {
      showModal.value = false
    }

    function onModalSubmit () {
      let data = {
        fenceType: (selectDrawType.value + 1)
      }
      switch (selectDrawType.value) {
        case 0: {
          const coordsList = []
          coordsList.push(fenceCenter.value)
          coordsList.push(fenceSecond.value)
          data = { ...data, ...{ data: { coordinates: coordsList, radius: fenceRadius.value } } }
          break
        }
        case 1:
          data = { ...data, ...{ data: { coordinates: fenceCoords.value } } }
          break
      }
      emit('modalCallback', data)
      showModal.value = false
    }

    return {
      t,
      modalData,
      fenceCenter,
      fenceSecond,
      fenceRadius,
      configData,
      enableMapDraw,
      disableSumbitButton,
      disableSelect,
      showModal,
      selectDrawType,
      fencePolygon,
      fenceCoords,
      showModalWithData,
      onModalCancel,
      onModalSubmit,
      onDrawClickEvent,
      onSelectChangedEvent
    }
  },
  methods: {
    onCancelClickEvent () {
      this.onModalCancel()
    },
    onSubmitClickEvent () {
      this.onModalSubmit()
    }
  }
}
</script>

<style lang="stylus" scoped>
.modal-overlay {
  z-index: 5005
  .modal-dialog {
    min-width: 90%
    .map {
      min-width:80%
    }
    .mapContent {
      width: 100%
      height: calc(100vh - 25rem)
    }
  }
}
</style>
