export const errorMessage = (status) => {
  let msg = ''
  switch (status) {
    case 400:
      msg = '請求錯誤(400)'
      break
    case 401:
      msg = '未授權，請重新登錄(401)'
      break
    case 403:
      msg = '拒絕訪問(403)'
      break
    case 404:
      msg = '請求失敗(404)'
      break
    case 408:
      msg = '請求逾時(408)'
      break
    case 500:
      msg = '伺服器錯誤(500)'
      break
    case 501:
      msg = '伺服器無法辨識請求(501)'
      break
    case 502:
      msg = '網路錯誤(502)'
      break
    case 503:
      msg = '伺服器當前無法處理請求(503)'
      break
    case 504:
      msg = '網路請求超時(504)'
      break
    case 505:
      msg = 'HTTP版本不支援(505)'
      break
    default:
      msg = '請檢查網路或連繫管理員!' + '(' + status + ')'
      break
  }
  return msg
}

export const codeError = (data) => {
  let errMsg = ''
  switch (data.code) {
    default:
      errMsg = '(' + data.code + ')' + data.message
      break
  }
  return errMsg
}
