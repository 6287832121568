import { request } from '../../api/axios'

const state = {
  settingDataList: []
}

const getters = {
  getSettingDataList (state) {
    return state.settingDataList
  },
  getClientShowOverSpeed (state) {
    return state.settingDataList.length === 0 ? false : state.settingDataList[2].value === '1'
  },
  getClientShowOffline (state) {
    return state.settingDataList.length === 0 ? false : state.settingDataList[3].value === '1'
  },
  getClientCouponEnable (state) {
    return state.settingDataList.length === 0 ? false : state.settingDataList[4].value === '1'
  },
  getReflashDeviceFreq (state) {
    return state.settingDataList.length === 0 ? 15 : parseInt(state.settingDataList[5].value)
  },
  getEnableAddressToGPS (state) {
    return state.settingDataList.length === 0 ? false : state.settingDataList[0].value === '1'
  }
}

const mutations = {
  setSettingDataList (state, data) {
    state.settingDataList.length = 0
    data.rows.forEach(element => {
      state.settingDataList.push(element)
    })
  }
}

const actions = {
  async getAdminSettingData ({ rootState }, data = {}) {
    let params = {
      userToken: rootState.userToken
    }
    params = { ...params, ...data }
    return request('/api/getSystemSettings', JSON.stringify(params))
  },
  async updateAdminSettingData ({ rootState }, data) {
    let params = {
      userToken: rootState.userToken
    }
    params = { ...params, ...data }
    return request('/api/editSystemSetting', JSON.stringify(params))
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
