import 'maptalks/dist/maptalks.css'
import * as maptalks from 'maptalks'

const state = {
  mapObj: null
}

const getters = {
  getMapTalks (state) {
    return state.mapObj
  }
}

const mutations = {
  setMapObj (state, map) {
    state.mapObj = Object.assign({}, map)
  }
}

const actions = {
  async mapInit ({ state, commit }, { mapName }) {
    if (state.mapObj === null) {
      const map = new maptalks.Map(mapName, {
        center: [121.492740, 25.071658],
        zoom: 17,
        minZoom: 1,
        maxZoom: 19,
        baseLayer: new maptalks.GroupTileLayer('base-map', [
          new maptalks.TileLayer('white-map', {
            urlTemplate: 'http://www.google.com/maps/vt?lyrs=m@189&gl=cn&x={x}&y={y}&z={z}&scale=2',
            crossOrigin: 'Anonymous',
            subdomains: [0, 1, 2, 3],
            visible: true
          })
        ])
      })
      commit('setMapObj', map)
      return map
    }
    return state.mapObj
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
