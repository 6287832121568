import { request } from '../../../api/axios'

const state = {
  pageIndex: 0,
  pageRows: 50,
  total: 0,
  reportList: []
}

const getters = {
  getReportList (state) {
    return state.reportList
  },
  getReportDataById: (state) => (id) => {
    return state.reportList.find(data => data.id === id)
  },
  pageIndex (state) {
    return state.pageIndex
  },
  pageRows (state) {
    return state.pageRows
  },
  totalCount (state) {
    return state.total
  }
}

const mutations = {
  setReportList (state, data) {
    state.reportList.length = 0
    state.total = data.total
    data.rows.forEach(element => {
      state.reportList.push(element)
    })
  },
  setPageIndex (state, index) {
    state.pageIndex = index
  }
}

const actions = {
  async getReportList ({ state, rootState }, searchKey = '') {
    const params = {
      userToken: rootState.userToken,
      pageIndex: searchKey === '' ? state.pageIndex : 0,
      pageRows: searchKey === '' ? state.pageRows : 100,
      keyword: searchKey
    }
    return request('/api/getReports', JSON.stringify(params))
  },
  async updateReportStatus ({ rootState }, data) {
    let params = {
      userToken: rootState.userToken
    }
    params = { ...params, ...data }
    return request('/api/editReport', JSON.stringify(params))
  },
  async addReportData ({ rootState }, data) {
    let params = {
      userToken: rootState.userToken
    }
    params = { ...params, ...data }
    return request('/api/addReport', JSON.stringify(params))
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
