import Select from 'vue-select/src/components/Select.vue';
<template>
  <div class="leftSide">
    <div class="row bg-secondary p-1">
      <div class="row">
        <div class="col mx-1 border">
          <input class="col-auto mx-1" type="radio" name="searchAccountType" id="device" :value="0" v-model="searchAccountType"/>
          <label class="col-auto" for="device">{{ t('ADMIN_ANNOUNCEMENT.MODAL_SEARCH_OPTION[1]') }}</label>
        </div>
        <div class="col mx-1 border">
          <input class="col-auto mx-1" type="radio" name="searchAccountType" id="fleet" :value="1" v-model="searchAccountType"/>
          <label class="col-auto" for="fleet">{{ t('ADMIN_ANNOUNCEMENT.MODAL_SEARCH_OPTION[0]') }}</label>
        </div>
      </div>
      <div v-if="searchAccountType === 0" class="row">
        <v-select class="col mx-1 select bg-light" label="deviceSerialNumber" :options="deviceOptions"
          v-model="selectDeviceItem" @search="onDeviceSearchInputOption" v-on:keydown.enter="onAddItemClickEvent" />
        <button type="button" class="col-sm-3 btn btn-sm btn-primary"
          @click="onAddItemClickEvent">{{ t('GENERAL.ADD')
          }}</button>
      </div>
      <div v-else class="row">
        <v-select class="col mx-1 select bg-light" label="account" :options="fleetOptions"
          v-model="selectFleetItem" @search="onFleetSearchInputOption" v-on:keydown.enter="onSearchItemClickEvent" />
        <button type="button" class="col-sm-3 btn btn-sm btn-primary" @click="onSearchItemClickEvent">{{ t('GENERAL.SEARCH')
        }}</button>
      </div>
    </div>
    <div class="col overflow-auto border border-2 bg-secondary deviceList">
      <div v-if="searchFleetAccount !== undefined" class="col border border-2 bg-white">
        <div class="row">
          <label class="col">{{ t('ADMIN_ANNOUNCEMENT.MODAL_SEARCH_OPTION[0]') }} : {{ searchFleetAccount }}</label>
          <div class="col-auto d-flex m-1 justify-content-end delete" @click="clearSearchFleet">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-square-fill text-danger"
              viewBox="0 0 16 16">
              <path
                d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2zm3.354 4.646L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 1 1 .708-.708z" />
            </svg>
          </div>
        </div>
        <div class="col overflow-auto border border-2 fleetDeviceList">
          <div v-for="(item, index) in searchFleetDeviceList" class="col align-items-center d-flex item" :key="index">
            <customer-fleet-device-view :item="item" @onAddDeviceClickEvent="onAddFleetDeviceEvent"/>
          </div>
        </div>
      </div>
      <div class="row m-1">
        <div v-for="(item, index) in watchDeviceList" class="col align-items-center d-flex item" :key="index">
          <customer-device-view :item="item" @onSelectItemCallback="onUpdateItemSelectEvent"
            @onDeleteItemCallback="onDeleteItemClickEvent" @onMenuCallback="onRightClickEvent" />
        </div>
      </div>
    </div>
    <div class="col bg-dark footer pt-1">
      <button type="button" class="col-sm-8 btn btn-sm btn-danger" @click="onServiceStopClick"
        :disabled="selectWatchDeviceList.length === 0">{{ t('GENERAL.STOP_SERVICE') }}</button>
    </div>
  </div>
  <right-click-device-menu :posX="menuLeft" :posY="menuTop" ref="RightClickDeviceMenuContext"
    @rightMenuCallback="onMenuCallback" />
  <modal-device-view ref="ModalDeviceViewDialog" @updateCallback="onDeviceUpdateCallback" />
  <modal-confirm ref="ModalConfirmDialog" @modalCallback="onOneStepStopConfirmCallback" />
  <modal-error-msg ref="ModalErrorDialg" />
</template>

<script>
import { reactive, ref } from '@vue/reactivity'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'
import { computed, inject, onMounted, onUnmounted, watch } from '@vue/runtime-core'
import ModalErrorMsg from '../../../components/Modal/ModalErrorMsg.vue'
import RightClickDeviceMenu from './RightClickDeviceMenu.vue'
import CustomerDeviceView from '../../../components/AdminPages/CustomerDeviceView.vue'
import CustomerFleetDeviceView from '../../../components/AdminPages/CustomerFleetDeviceView.vue'
import ModalDeviceView from '../../../components/Modal/ModalDeviceView.vue'
import ModalConfirm from '../../../components/Modal/ModalConfirm.vue'
export default {
  components: { ModalErrorMsg, RightClickDeviceMenu, CustomerDeviceView, ModalDeviceView, ModalConfirm, CustomerFleetDeviceView },
  setup () {
    const { t } = useI18n()
    const store = useStore()
    const tools = inject('tools')

    const ModalErrorDialg = ref()
    const ModalDeviceViewDialog = ref()
    const ModalConfirmDialog = ref()
    const RightClickDeviceMenuContext = ref()
    const menuTop = ref(0)
    const menuLeft = ref(0)

    const reflashTime = computed(() => store.getters['setting/getReflashDeviceFreq'])
    const searchAccountType = ref(0)

    const watchDeviceList = computed(() => store.getters['adminCustomer/getWatchDeviceList'])
    const selectWatchDeviceList = computed(() => store.getters['adminCustomer/getSelectWatchList'])
    const deviceOptions = reactive([])
    const fleetOptions = ref([])
    const selectDeviceItem = ref({})
    const selectFleetItem = ref({})

    const searchKeywordValue = ref('')

    const searchFleetAccount = ref()
    const searchFleetDeviceList = ref([])

    const reflashDevice = ref()
    const startReflashData = ref(true)

    function startReflashWatchDeviceData () {
      startReflashData.value = true
      reflashDevice.value = setInterval(() => {
        if (startReflashData.value) {
          watchDeviceList.value.forEach(device => {
            reloadDeviceData(device.deviceSerialNumber)
          })
        } else {
          clearInterval(reflashDevice.value)
        }
      }, (reflashTime.value * 1000))
    }

    function getSettingData () {
      store.dispatch('setting/getAdminSettingData').then((res) => {
        store.commit('setting/setSettingDataList', res.data)
        startReflashWatchDeviceData()
      }).catch((err) => {
        console.log(err)
        showErrModal(err)
      })
    }

    function onDeleteItemClickEvent (item) {
      store.commit('adminCustomer/removeItemDevice', item)
    }

    const getDeviceOptions = (value, loading) => {
      searchKeywordValue.value = value
      deviceOptions.length = 0
      if (value.length > 0) {
        loading(true)
        store.dispatch('adminDevice/getDeviceOptionListByKeyword', value).then((res) => {
          res.data.rows.forEach(element => {
            if (deviceOptions.findIndex(device => device.deviceId === element.deviceId) === -1) {
              deviceOptions.push(element)
            }
            if (element.deviceSerialNumber === parseInt(value)) {
              selectDeviceItem.value = element
            }
          })
          loading(false)
        }).catch((err) => {
          showErrModal(err)
          loading(false)
        })
      }
    }

    const getFleetOptions = (value, loading) => {
      fleetOptions.value.length = 0
      if (value.length > 0) {
        loading(true)
        store.dispatch('adminFleet/getFleetOptionListByKeyword', value).then((res) => {
          res.data.rows.forEach(element => {
            if (fleetOptions.value.findIndex(fleet => fleet.id === element.id) === -1) {
              fleetOptions.value.push(element)
            }
          })
          loading(false)
        }).catch((err) => {
          console.error(err)
          loading(false)
        })
      }
    }

    function onDeviceUpdateCallback (data) {
      const originData = store.getters['adminCustomer/getWatchDeviceDataByNumber'](data.deviceSerialNumber)
      const updateData = tools.objectDiff(originData, data)
      if (tools.isEmpty(updateData)) {
        return
      }
      updateData.id = data.deviceId
      store.dispatch('adminDevice/updateAdminDeviceData', updateData).then((res) => {
        reloadDeviceData(data.deviceSerialNumber)
      }).catch((err) => {
        showErrModal(err)
      })
    }

    function reloadDeviceData (deviceSerialNumber) {
      store.dispatch('adminDevice/getAdminSearchDeviceByNumber', deviceSerialNumber).then((res) => {
        store.commit('adminCustomer/updateItemDevice', res.data.rows[0])
      }).catch((err) => {
        showErrModal(err)
      })
    }

    function onAddItemClickEvent () {
      if (tools.isEmpty(selectDeviceItem.value)) {
        if (deviceOptions.length > 0) {
          selectDeviceItem.value = deviceOptions.find(device => device.deviceSerialNumber.toString() === searchKeywordValue.value)
          if (tools.isEmpty(selectDeviceItem.value)) {
            return
          }
        } else {
          return
        }
      }
      document.activeElement.blur()
      store.commit('adminCustomer/addItemDevice', selectDeviceItem.value)
      selectDeviceItem.value = null
      deviceOptions.length = 0
    }

    function clearSearchFleet () {
      searchFleetAccount.value = undefined
      searchFleetDeviceList.value.length = 0
    }

    function onSearchItemClickEvent () {
      clearSearchFleet()
      if (!tools.isEmpty(selectFleetItem.value)) {
        searchFleetAccount.value = selectFleetItem.value.account
        store.dispatch('adminDevice/getDeviceByFleetId', selectFleetItem.value.id).then((res) => {
          res.data.rows.forEach(device => {
            searchFleetDeviceList.value.push(device)
          })
        })
      }
    }

    function onAddFleetDeviceEvent (item) {
      if (tools.isEmpty(item)) {
        return
      }
      store.commit('adminCustomer/addItemDevice', item)
    }

    function onUpdateItemSelectEvent (isSelect, item) {
      if (isSelect) {
        store.commit('adminCustomer/removeSelectWatchList', item)
      } else {
        store.commit('adminCustomer/addSelectWatchList', item)
        addMarkerPointList(item)
      }
    }

    function addMarkerPointList (device) {
      store.dispatch('adminCustomer/getMarkerPointList', device.deviceSerialNumber).then((res) => {
        store.commit('adminCustomer/setMarkerPointList', res.data)
      }).catch((err) => {
        showErrModal(err)
      })
    }

    function onRightClickEvent (event, item) {
      menuTop.value = event.pageY - 10
      menuLeft.value = event.pageX
      RightClickDeviceMenuContext.value.showRightClickMenu(item)
    }

    function onMenuCallback (mainIndex, subData, data) {
      switch (mainIndex) {
        case 2:
          ModalDeviceViewDialog.value.showModalWithData(data, true)
          break
      }
    }

    function showErrModal (err) {
      ModalErrorDialg.value.showModalWithData(true, '', err)
    }

    function clearSelectWatchList () {
      store.commit('adminCustomer/clearSelectWatchList')
    }

    function onServiceStopClick () {
      const stopList = []
      let contentDeviceName = ''
      selectWatchDeviceList.value.forEach(device => {
        if (contentDeviceName.length > 0) {
          contentDeviceName += ','
        }
        contentDeviceName += device.deviceSerialNumber
        stopList.push(device)
      })
      ModalConfirmDialog.value.showModalConfirm(t('GENERAL.STOP_SERVICE'), t('MESSAGE.STOP_DEVICE_STATUS', [contentDeviceName]), stopList)
    }

    function onOneStepStopConfirmCallback (data) {
      Object.values(data).forEach(device => {
        const updateData = {
          id: device.deviceId,
          status: 3
        }
        store.dispatch('adminDevice/updateAdminDeviceData', updateData).then((res) => {
          console.log(res)
        }).catch((err) => {
          console.log(err)
        })
      })
    }

    watch(() => searchAccountType.value, () => {
      clearSearchFleet()
    })

    onMounted(() => {
      clearSelectWatchList()
      getSettingData()
    })

    onUnmounted(() => {
      startReflashData.value = false
    })

    return {
      t,
      reflashTime,
      menuTop,
      menuLeft,
      searchAccountType,
      RightClickDeviceMenuContext,
      ModalDeviceViewDialog,
      ModalConfirmDialog,
      ModalErrorDialg,
      deviceOptions,
      fleetOptions,
      getFleetOptions,
      watchDeviceList,
      selectDeviceItem,
      selectFleetItem,
      selectWatchDeviceList,
      searchFleetAccount,
      searchFleetDeviceList,
      clearSearchFleet,
      onAddItemClickEvent,
      onAddFleetDeviceEvent,
      onSearchItemClickEvent,
      onDeleteItemClickEvent,
      onUpdateItemSelectEvent,
      getDeviceOptions,
      onRightClickEvent,
      onDeviceUpdateCallback,
      onMenuCallback,
      onServiceStopClick,
      onOneStepStopConfirmCallback
    }
  },
  methods: {
    onDeviceSearchInputOption (value, loading) {
      this.getDeviceOptions(value, loading)
    },
    onFleetSearchInputOption (value, loading) {
      this.getFleetOptions(value, loading)
    }
  }
}
</script>

<style lang="stylus" scoped>
.leftSide {
  .row {
    --bs-gutter-x : 0
    --bs-bg-opacity: 0.2;
    background-image: var(--bs-gradient)
  }
  .select {
    width: 10rem
  }

  .deviceList {
    --bs-bg-opacity: 0.2;
    background-image: var(--bs-gradient)
    height: calc(100vh - 18.1rem)
    .fleetDeviceList {
      height: 10rem
    }
    .item {
      background-color: transparent
      .carImg {
        width: 20px
        height: 20px
      }
      & svg {
        color: red
      }
    }
  }
  .footer {
    padding-top: 0.2rem
    height: 2.5rem
    --bs-bg-opacity: 0.2;
    background-image: var(--bs-gradient)
  }
}
</style>
