import { request } from '../../../api/axios'

const state = {
  pageIndex: 0,
  pageRows: 100
}

const getters = {

}

const mutations = {

}

const actions = {
  async getAnnouncementList ({ state, rootState }) {
    const params = {
      userToken: rootState.userToken,
      pageIndex: state.pageIndex,
      pageRows: state.pageRows
    }
    return request('/api/getAnnouncements', JSON.stringify(params))
  },
  async getMessageList ({ state, rootState }) {
    const params = {
      userToken: rootState.userToken,
      pageIndex: state.pageIndex,
      pageRows: state.pageRows,
      receiverAccountType: rootState.userData.accountType,
      receiverAccountId: rootState.userData.accountId
    }
    return request('/api/getMessages', JSON.stringify(params))
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
