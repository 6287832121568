<template>
  <div v-if="showModal" class="modal-overlay">
    <div class="modal-dialog">
      <div class="modal-content">
        <h4 class="modal-title">{{ t('ADMIN_ANNOUNCEMENT.MODAL_SHOW_TITLE[' + (isMessage ? 1 : 0) + ']') }}</h4>
        <hr />
        <form class="row g-3 p-3">
          <div v-if="isMessage" class="form-group row mb-2">
            <label class="col-sm-2 col-form-label">{{ t('ADMIN_ANNOUNCEMENT.MODAL_MESSAGE_RECIVER_TABLES[1]') }}</label>
            <label class="col-sm-auto col-form-label">{{ messageData.alias }}</label>
          </div>
          <div class="form-group row mb-2">
            <label class="col-sm-2 col-form-label">{{ t('ADMIN_ANNOUNCEMENT.MODAL_ANNOUNCEMENT_CONTENT[0]') }}</label>
            <label class="col-sm-auto col-form-label">{{ tools.getDateTime(messageData.activeDate, '---') }}</label>
          </div>
          <div class="form-group row mb-2">
            <label class="col-sm-2 col-form-label">{{ t('ADMIN_ANNOUNCEMENT.MODAL_ANNOUNCEMENT_CONTENT[1]') }}</label>
            <textarea class="form-control mx-3 inputTextArea" id="description" rows="5" maxlength="280" disabled
              v-model="messageData.content" />
          </div>
        </form>
        <div class="modal-footer">
          <button type="button" class="btn btn-primary" @click="onSubmitClickEvent">{{ t('GENERAL.CLOSE') }}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from '@vue/reactivity'
import { useI18n } from 'vue-i18n'
import { inject } from '@vue/runtime-core'
export default {
  emits: ['modalCallback'],
  setup (props, { emit }) {
    const { t } = useI18n()
    const tools = inject('tools')

    const showModal = ref(false)
    const isMessage = ref(false)
    const messageData = ref({})

    function showModalWithData (isMsg, data) {
      isMessage.value = isMsg
      messageData.value = Object.assign({}, data)
      showModal.value = true
    }

    function onModalSubmit () {
      showModal.value = false
      emit('modalCallback')
    }

    return {
      t,
      tools,
      showModal,
      isMessage,
      messageData,
      showModalWithData,
      onModalSubmit
    }
  },
  methods: {
    onSubmitClickEvent () {
      this.onModalSubmit()
    }
  }
}
</script>

<style lang="stylus" scoped>
.inputTextArea {
  resize: none
}
</style>
