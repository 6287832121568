export const configData = {
  googleMapAPIKey: 'AIzaSyCN5uILNWd4HdSsZ4TKAfvfPzrZ_xYP8MI',
  accountStatus: {
    1: '正常',
    2: '停權'
  },
  cookieName: 'maycharm_cartracker',
  cookieAccount: 'maycahrm_cartrack_account',
  cookieClientAccount: 'maycharm_cartrack_client',
  permissionCategory: {
    1: 'ACCOUNT',
    2: 'PERMISSION',
    3: 'DEVICE',
    4: 'FLEET',
    5: 'CUSTOMER',
    6: 'PLAN',
    7: 'VIP_PLAN',
    8: 'STATISTICS',
    9: 'REPORT',
    10: 'FINANCE',
    11: 'ANNOUNCEMENT',
    12: 'SYSTEM_LOG',
    13: 'SETTING'
  },
  permissionStatus: ['ADD', 'EDITOR', 'DELETE', 'VIEW'],
  deviceSearchType: ['GENERAL.ALL', 'ADMIN_DEVICE.SEARCH_NUMBER', 'ADMIN_DEVICE.SEARCH_ZONE', 'SERVICE_EXPIRE.TABLES[3]', 'TABLES.OFFLINE_DEVICE[7]'],
  deviceServiceType: ['GENERAL.SERVICE_PLAN_STATUS[0]', 'GENERAL.SERVICE_TYPE[0]', 'GENERAL.SERVICE_TYPE[1]', 'GENERAL.SERVICE_TYPE[2]', 'GENERAL.SERVICE_TYPE[3]'],
  deviceStatusType: ['MONITOR.DEVICE_STATUS_TYPE[0]', 'MONITOR.DEVICE_STATUS_TYPE[1]', 'MONITOR.DEVICE_STATUS_TYPE[2]', 'MONITOR.DEVICE_STATUS_TYPE[3]'],
  planCategory: {
    0: 'ADMIN_DEVICE.PLAN[0]',
    1: 'ADMIN_DEVICE.PLAN[1]',
    2: 'ADMIN_DEVICE.PLAN[2]',
    3: 'ADMIN_DEVICE.PLAN[3]',
    4: 'ADMIN_DEVICE.PLAN[4]',
    5: 'ADMIN_DEVICE.PLAN[5]',
    6: 'ADMIN_DEVICE.PLAN[6]',
    7: 'ADMIN_DEVICE.PLAN[7]',
    8: 'ADMIN_DEVICE.PLAN[8]',
    9: 'ADMIN_DEVICE.PLAN[9]',
    10: 'ADMIN_DEVICE.PLAN[10]'
  },
  deviceStatus: {
    1: 'ADMIN_DEVICE.STATUS[0]',
    2: 'ADMIN_DEVICE.STATUS[1]'
  },
  settingStatus: ['GENERAL.SETTING_STATUS[0]', 'GENERAL.SETTING_STATUS[1]'],
  servicePlanStatus: ['GENERAL.SERVICE_PLAN_STATUS[0]', 'GENERAL.SERVICE_PLAN_STATUS[1]'],
  carPower: ['GENERAL.YES', 'GENERAL.NO'],
  serviceStatus: {
    NULL: 'GENERAL.SERVICE_PLAN_STATUS[0]',
    1: 'GENERAL.SERVICE_TYPE[0]',
    2: 'GENERAL.SERVICE_TYPE[1]',
    3: 'GENERAL.SERVICE_TYPE[2]',
    4: 'GENERAL.SERVICE_TYPE[3]'
  },
  vipPlanShowOn: ['GENERAL.VIP_PLAN_HIDE', 'GENERAL.VIP_PLAN_SHOW'],
  fleetPermission: {
    0: 'ADMIN_FLEET.SUB_PERMISSION[0]',
    1: 'ADMIN_FLEET.SUB_PERMISSION[1]',
    2: 'ADMIN_FLEET.SUB_PERMISSION[2]'
  },
  fleetSubPermission: {
    0: 'ADMIN_FLEET.SUB_PERMISSION[0]',
    2: 'ADMIN_FLEET.SUB_PERMISSION[2]'
  },
  messageModalSearch: ['ADMIN_ANNOUNCEMENT.MODAL_SEARCH_OPTION[0]', 'ADMIN_ANNOUNCEMENT.MODAL_SEARCH_OPTION[1]'],
  reportSearchOptions: ['ADMIN_REPORT.REPORT_SEARCH_OPTIONS[0]', 'ADMIN_REPORT.REPORT_SEARCH_OPTIONS[1]', 'ADMIN_REPORT.REPORT_SEARCH_OPTIONS[2]'],
  reportProcessStatus: ['ADMIN_REPORT.PROCESS_STATUS[0]', 'ADMIN_REPORT.PROCESS_STATUS[1]', 'ADMIN_REPORT.PROCESS_STATUS[2]'],
  financeSearchOptions: ['ADMIN_FINANCE.SEARCH_OPTIONS[0]', 'ADMIN_FINANCE.SEARCH_OPTIONS[1]', 'ADMIN_FINANCE.SEARCH_OPTIONS[2]', 'ADMIN_FINANCE.SEARCH_OPTIONS[3]'],
  financePaymentStatusOptions: ['ADMIN_FINANCE.SEARCH_PAYMENT_OPTIONS[0]', 'ADMIN_FINANCE.SEARCH_PAYMENT_OPTIONS[1]', 'ADMIN_FINANCE.SEARCH_PAYMENT_OPTIONS[2]', 'ADMIN_FINANCE.SEARCH_PAYMENT_OPTIONS[3]', 'GENERAL.ALL'],
  financePaymentTypeOptions: ['ADMIN_FINANCE.PAYMENT_TYPE[0]', 'ADMIN_FINANCE.PAYMENT_TYPE[1]', 'ADMIN_FINANCE.PAYMENT_TYPE[2]', 'ADMIN_FINANCE.PAYMENT_TYPE[3]'],
  financePaymentTypeKeys: ['', 'CREDIT', 'MANUAL', 'CVS'],
  financeInvoiceTypeOptions: ['GENERAL.ALL', 'ADMIN_FINANCE.INVOICE_TYPE[0]', 'ADMIN_FINANCE.INVOICE_TYPE[1]', 'ADMIN_FINANCE.INVOICE_TYPE[2]'],
  planSearchOptions: ['ADMIN_DEVICE.MODAL_VIEW_CONTENT[0]', 'ADMIN_DEVICE.MODAL_VIEW_CONTENT[2]'],
  remoteSelectOptions: ['REMOTE.TABS[0]', 'REMOTE.TABS[1]', 'REMOTE.TABS[2]'],
  remoteControlRadio: ['REMOTE.CONTROL[0]', 'REMOTE.CONTROL[1]', 'REMOTE.CONTROL[2]'],
  remoteGetKeyValue: {
    PSW: '查詢終端密碼',
    PHONE: '查詢終端內置SIM卡號碼',
    USER: '查詢車主手機號碼',
    FREQ: '查詢開啟追蹤后的上報頻率,單位是秒',
    VIB: '設置開啟設防時是否傳送簡訊警報:1為開啟 0為關閉',
    VIBL: '查詢振動靈敏度0~15,0為最高靈敏度,太高可能會誤報,15為最低靈敏度',
    LBVON: '查詢是否開啟低電量警報功能(同手機功能):1為開啟 0為關閉',
    POF: '查詢是否開啟低電量警報功能(強制功能):1為開啟 0為關閉',
    GPS: '查詢GPS接收的衛星編號和強度,例如：2300 1223 3431... 一共12組四位數,2300表示接收到編號23衛星信號強度為00,1223表示接收到編號12的衛星信號強度為23',
    VBAT: '查詢電池電壓，是否過電，是否充電中。例如：VBAT=3713300：5000000：100000 表示電池電壓為3713300uV 5000000代表有過電,0000000代表沒過電 1000000代表充電中,0000000代表沒在充電'
  },
  remoteSendKeyValue: {
    PSW: '查詢終端密碼',
    PHONE: '查詢終端內置SIM卡號碼',
    USER: '查詢車主手機號碼',
    FREQ: '設置開啟追蹤后的上報頻率,單位是秒',
    VIB: '設置開啟設防時是否傳送簡訊警報:1為開啟 0為關閉',
    VIBL: '查詢振動靈敏度0~15,0為最高靈敏度,太高可能會誤報,15為最低靈敏度',
    LBVON: '設置是否開啟低電量警報功能(同手機功能):1為開啟 0為關閉',
    POF: '設置是否開啟低電量警報功能(強制功能):1為開啟 0為關閉',
    SLEEP: '設置是否開啟休眠功能:1為開啟 0為關閉'
  },
  remoteClientGetKeyValue: {
    VIBL: '查詢振動靈敏度0~15,0為最高靈敏度,太高可能會誤報,15為最低靈敏度',
    LBVON: '設置是否開啟低電量警報功能:1為開啟 0為關閉'
    // GPS: '查詢GPS接收的衛星編號和強度,例如：2300 1223 3431... 一共12組四位數,2300表示接收到編號23衛星信號強度為00,1223表示接收到編號12的衛星信號強度為23',
    // VBAT: '查詢電池電壓，是否過電，是否充電中。例如：VBAT=3713300：5000000：100000 表示電池電壓為3713300uV 5000000代表有過電,0000000代表沒過電 1000000代表充電中,0000000代表沒在充電'
  },
  remoteClientSendKeyValue: {
    VIBL: '查詢振動靈敏度0~15,0為最高靈敏度,太高可能會誤報,15為最低靈敏度',
    LBVON: '設置是否開啟低電量警報功能:1為開啟 0為關閉',
    FREQ: '設置定位更新速度'
  },
  remoteFREQContent: {
    30: 'GENERAL.FREQ_CONTENT[0]',
    15: 'GENERAL.FREQ_CONTENT[1]'
  },
  monitorTabs: ['MONITOR.TABS[0]', 'MONITOR.TABS[1]', 'MONITOR.TABS[2]'],
  customerStatisticType: ['ADMIN_STATISTICS.SIDE_MENU[0]', 'ADMIN_STATISTICS.SIDE_MENU[1]', 'ADMIN_STATISTICS.SIDE_MENU[2]', 'ADMIN_STATISTICS.SIDE_MENU[3]', 'ADMIN_STATISTICS.SIDE_MENU[4]'],
  customerStatisticSubType: ['ADMIN_STATISTICS.SIDE_SUB_MENU[0]', 'ADMIN_STATISTICS.SIDE_SUB_MENU[1]', 'ADMIN_STATISTICS.SIDE_SUB_MENU[2]'],
  languageOptions: {
    'zh-TW': 'LANGUAGE.SELECT[0]',
    'en-US': 'LANGUAGE.SELECT[1]'
  },
  systemLogActionStatus: ['ADMIN_SYSTEM_LOG.ACTION_STATUS[0]', 'ADMIN_SYSTEM_LOG.ACTION_STATUS[1]', 'ADMIN_SYSTEM_LOG.ACTION_STATUS[2]', 'ADMIN_SYSTEM_LOG.ACTION_STATUS[3]'],
  targetType: ['MONITOR.ELE_TARGET_TYPE[0]', 'MONITOR.ELE_TARGET_TYPE[1]', 'MONITOR.ELE_TARGET_TYPE[2]'],
  markerPointType: ['MONITOR.MARKER_POINT_TYPE[1]', 'MONITOR.MARKER_POINT_TYPE[0]'],
  drawElectronicFenceType: ['MONITOR.DRAW_TYPE[0]', 'MONITOR.DRAW_TYPE[1]'],
  feebackType: {
    1: 'ADMIN_REPORT.REPORT_TYPE[0]',
    2: 'ADMIN_REPORT.REPORT_TYPE[1]',
    3: 'ADMIN_REPORT.REPORT_TYPE[2]'
  },
  historyTables: ['HISTORY.TABLES[0]', 'HISTORY.TABLES[1]', 'HISTORY.TABLES[2]'],
  donateOptions: {
    25885: '伊甸基金會',
    531: '董氏基金會',
    8585: '家扶基金會',
    8957282: 'HSAPF(流浪動物之家)',
    9527: '罕病基金會'
  },
  serviceCategory: ['TOP_TABS.TELECOM_SERVICE', 'TOP_TABS.VIP_PLAN'],
  manualServiceTypeOptions: ['ADMIN_PLAN.EXPIRED_DAY', 'ADMIN_PLAN.EXTRA_ADDRESS_TIMES'],
  financeSearchTypeOptions: ['TOP_TABS.FINANCE', 'ADMIN_PLAN.MODAL_TITLE'],
  systemLogSearchTypeOptions: ['終端序號', '車隊帳號', '管理者帳號']
}
