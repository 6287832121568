<template>
  <div class="container-fluid p-0 align-items-start tabContnet">
    <div class="tabs">
      <div v-for="(data, index) in menuData" class="tab" :key="index">
        <router-link :to="{name: data.name }" custom v-slot="{ navigate, isActive }">
          <input type="radio" name="css-tabs" :id="data.name" :checked="isActive" @change="navigate" class="tab-switch">
          <label :for="data.name" class="tab-label">{{ data.title }}</label>
        </router-link>
      </div>
    </div>
    <router-view/>
  </div>
</template>

<script>
import { reactive } from '@vue/reactivity'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'
export default {
  setup () {
    const { t } = useI18n()
    const router = useRouter()

    const menuData = reactive([
      {
        title: t('TOP_TABS.MONITOR'),
        name: 'customer.monitor'
      },
      {
        title: t('TOP_TABS.MULTI_MONITOR'),
        name: 'customer.multimonitor'
      },
      {
        title: t('TOP_TABS.HISTORY'),
        name: 'customer.history'
      },
      {
        title: t('TOP_TABS.REMOTE'),
        name: 'customer.remote'
      },
      // {
      //   title: t('TOP_TABS.WARNING'),
      //   name: 'customer.warning'
      // },
      {
        title: t('TOP_TABS.STATISTICS'),
        name: 'customer.statistics'
      }
    ])

    return {
      t,
      router,
      menuData
    }
  }
}
</script>

<style lang="stylus" scoped>
.tabContnet {
  width: 100%
}
.tabs {
  position: relative
  margin: 0
}
.tabs::before,
.tabs::after {
  content: ""
  display: table
}
.tabs::after {
  clear: both
}
.tab {
  float: left
}
.tab-switch {
  display: none
}
.tab-label {
  position: relative
  display: block
  line-height: 2.5em
  height: 2.5em
  margin: 0 0.1rem
  padding: 0 1.5rem
  background: #eee
  border-left: 0.5rem solid #AAA
  cursor: pointer
  top: 0;
  font-size: 12px
  transition: all 0.25s
}
.tab-label:hover {
  background: #4ba6ed
  transition: background 0.25s
}

.tab-switch:checked + .tab-label {
  background: #4ba6ed
  color: #FFF
  border-left: 0.5rem solid #cf3858
  transition: all 0.35s
  z-index: 1
}
</style>
