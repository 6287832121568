<template>
  <div class="partentTemp" @mousedown="onMouseDownClick">
    <top-nav class="topNav"/>
    <div class="container-fluid pt-3">
      <div class="row mt-0">
        <div class="col leftList"  :style="{ 'max-width': (showLeftSideList ? 16 : 0) + 'rem'}">
          <left-list :showLeft="showLeftSideList"/>
          <div v-if="showLeftSideList" class="hideLeft" @click="onShowHideLeftListEvent">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="text-dark bi bi-caret-left-fill" viewBox="0 0 16 16">
              <path d="m3.86 8.753 5.482 4.796c.646.566 1.658.106 1.658-.753V3.204a1 1 0 0 0-1.659-.753l-5.48 4.796a1 1 0 0 0 0 1.506z"/>
            </svg>
          </div>
        </div>
        <div class="col content">
          <client-content/>
        </div>
        <div v-if="!showLeftSideList" class="showLeft" @click="onShowHideLeftListEvent">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="text-dark bi bi-caret-right-fill" viewBox="0 0 16 16">
            <path d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z"/>
          </svg>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TopNav from './TopNav.vue'
import LeftList from '../../components/ClientPages/LeftList.vue'
import ClientContent from '../../components/ClientPages/ContentView.vue'
import { useStore } from 'vuex'
import { ref } from '@vue/reactivity'
export default {
  setup () {
    const store = useStore()

    const showLeftSideList = ref(true)

    function onMouseDownClick (event) {
      store.commit('resetTimer')
    }

    function onShowHideLeftListEvent () {
      showLeftSideList.value = !showLeftSideList.value
    }

    return {
      onShowHideLeftListEvent,
      onMouseDownClick,
      showLeftSideList
    }
  },
  components: {
    TopNav,
    LeftList,
    ClientContent
  }
}
</script>

<style lang="stylus" scoped>
.topNav {
  max-height: 70px
}

.leftList {
  max-width: 16rem
  padding-right: 0
  .hideLeft {
    position: absolute
    top: 50vh
    left: calc(16rem - 5px)
  }
}
.showLeft {
    width: 24px
    height: 24px
    position: absolute
    top: 50vh
    left: 0px
  }
.content {
  min-width: 500px
  padding: 0px 15px
  --bs-gutter-x : 0
}

@media screen and (max-height: 720px) {
  .topNav {
    max-height: 50px
  }
  .leftList {
    max-width: 14rem
    font-size: 0.7rem
    padding-right: 0
  }
}
</style>
