<template>
  <div class="content">
    <div v-if="messageData.receiverAccountId" class="form-group row field m-2">
      <label class="col-sm-2 mx-3">{{ t('GENERAL.MESSAGE_TITLE[0]') }}：</label>
      <label class="col-sm">{{ showDate(messageData) }}</label>
    </div>
    <div class="form-group row field m-2">
      <label class="col-sm-2 mx-3">{{ t('GENERAL.MESSAGE_TITLE[2]') }}：</label>
      <label class="col-sm">{{ messageData.content }}</label>
    </div>
  </div>
</template>

<script>
import { ref } from '@vue/reactivity'
import { useI18n } from 'vue-i18n'
import { inject } from 'vue'
export default {
  props: ['messageData'],
  emits: ['modalCallback'],
  setup (props, { emit }) {
    const { t } = useI18n()
    const tools = inject('tools')

    const showModal = ref(false)
    const modalData = ref()

    function showModalWithData (data) {
      modalData.value = Object.assign({}, data)
      showModal.value = true
    }

    function showDate (data) {
      let date = ''
      if (data) {
        if (data.receiverAccountId) {
          date = tools.getShortDate(data.activeDate, '---')
        }
      }
      return date
    }

    function showCategory (data) {
      return t('GENERAL.MESSAGE_TYPE[' + (data.receiverAccountId ? 1 : 0) + ']')
    }

    function onModalCancel () {
      showModal.value = false
    }

    function onModalSubmit () {
      showModal.value = false
    }

    return {
      t,
      showModal,
      modalData,
      showCategory,
      showDate,
      showModalWithData,
      onModalCancel,
      onModalSubmit
    }
  },
  methods: {
    onCancelClickEvent () {
      this.onModalCancel()
    },
    onSubmitClickEvent () {
      this.onModalSubmit()
    }
  }
}
</script>

<style lang="stylus" scoped>
.table {
  overflow: auto
  max-height: 70%
  & thead {
    position: sticky
    top: 0
  }
}
.tableContent {
    max-width: 100px
    overflow: hidden
    text-overflow: ellipsis
    white-space: nowrap
  }
</style>
