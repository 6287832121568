import { request } from '../../../api/axios'

const state = {
  accountList: [],
  total: 0,
  pageIndex: 0,
  pageRows: 30
}

const getters = {
  totalCount (state) {
    return state.total
  },
  pageIndex (state) {
    return state.pageIndex
  },
  pageRows (state) {
    return state.pageRows
  },
  getAccountList (state) {
    return state.accountList
  },
  getSelectAccountById: (state) => (id) => {
    return state.accountList.find(data => data.id === id)
  }
}

const mutations = {
  setPageIndex (state, index) {
    state.pageIndex = index
  },
  renewAccountList (state, data) {
    state.accountList.length = 0
    state.total = data.total
    data.rows.forEach(element => {
      state.accountList.push(element)
    })
  }
}

const actions = {
  async adminLogin ({ state }, { userAccount, userPassword }) {
    const params = {
      account: userAccount,
      password: userPassword
    }
    return request('/api/loginAccount', JSON.stringify(params))
  },
  async adminForgetPassword ({ state }, { userAccount, userEmail }) {
    const params = {
      account: userAccount,
      email: userEmail
    }
    return request('/api/forgetAccountPassword', JSON.stringify(params))
  },
  async adminResetPassword ({ state }, { resetKeyVal, newPassword }) {
    const params = {
      resetKey: resetKeyVal,
      password: newPassword
    }
    return request('/api/resetAccountPassword', JSON.stringify(params))
  },
  async adminCreateAccount ({ rootState }, userData) {
    let params = {
      userToken: rootState.userToken
    }
    params = { ...params, ...userData }
    return request('/api/createAccount', JSON.stringify(params))
  },
  async adminUpdateAccount ({ rootState }, updateData) {
    let params = {
      userToken: rootState.userToken
    }
    params = { ...params, ...updateData }
    return request('/api/editAccount', JSON.stringify(params))
  },
  async adminDeleteAccount ({ rootState }, { userId }) {
    const params = {
      userToken: rootState.userToken,
      id: userId
    }
    return request('/api/deleteAccount', JSON.stringify(params))
  },
  async adminGetAccountList ({ state, rootState }, { keyword = null }) {
    const params = {
      userToken: rootState.userToken,
      pageIndex: state.pageIndex,
      pageRows: state.pageRows,
      keyword: keyword ?? ''
    }
    return request('/api/getAccounts', JSON.stringify(params))
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
