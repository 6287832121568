import { request } from '../../api/axios'

const state = {
  selectDeviceElectronicFenceList: [],
  selectFenceId: 0,
  selectDeviceMarkerPointList: {},
  selectMarkerId: 0,
  selectFooterIndex: 0
}

const getters = {
  selectDeviceElectronicFenceList (state) {
    return state.selectDeviceElectronicFenceList
  },
  selectFenceId (state) {
    return state.selectFenceId
  },
  selectDeviceMarkerPointList (state) {
    const list = []
    Object.values(state.selectDeviceMarkerPointList).forEach(arrList => {
      arrList.forEach(markPointData => {
        list.push(markPointData)
      })
    })
    return list
  },
  selectMarkerId (state) {
    return state.selectMarkerId
  },
  selectFooterIndex (state) {
    return state.selectFooterIndex
  },
  getSelectDeviceMarkerPointListByDeviceSerialNumber: (state) => (deviceSerialNumber) => {
    if (state.selectDeviceMarkerPointList[deviceSerialNumber]) {
      return state.selectDeviceMarkerPointList[deviceSerialNumber]
    }
    return []
  }
}

const mutations = {
  setSelectFooterIndex (state, index) {
    state.selectFooterIndex = index
  },
  setSelectFenceId (state, fenceId) {
    state.selectFenceId = fenceId
  },
  setSelectMarkerId (state, markerId) {
    state.selectMarkerId = markerId
  },
  addMarkerPointData (state, data) {
    if (state.selectDeviceMarkerPointList.findIndex(marker => data.id === marker.id) === -1) {
      state.selectDeviceMarkerPointList.push(data)
    }
  },
  setMarkerPointList (state, data) {
    data.rows.forEach(element => {
      if (!state.selectDeviceMarkerPointList[element.deviceSerialNumber]) {
        state.selectDeviceMarkerPointList[element.deviceSerialNumber] = []
      }
      if (state.selectDeviceMarkerPointList[element.deviceSerialNumber].findIndex(mark => mark.id === element.id) === -1) {
        state.selectDeviceMarkerPointList[element.deviceSerialNumber].push(element)
      }
    })
  },
  removeMarkerPointListByDeviceSerialNumber (state, deviceSerialNumber) {
    if (state.selectDeviceMarkerPointList[deviceSerialNumber]) {
      delete state.selectDeviceMarkerPointList[deviceSerialNumber]
    }
  },
  addElectronicFenceData (state, data) {
    if (state.selectDeviceElectronicFenceList.findIndex(fence => fence.fenceData.id === data.fenceData.id) === -1) {
      state.selectDeviceElectronicFenceList.push(data)
    }
  },
  setElectronicFenceList (state, data) {
    data.rows.forEach(element => {
      if (state.selectDeviceElectronicFenceList.findIndex(fence => fence.id === element.id) === -1) {
        const content = Object.assign({}, element)
        content.isEnterWarning = element.isEnterWarning === 1
        content.isExitWarning = element.isExitWarning === 1
        state.selectDeviceElectronicFenceList.push(content)
      }
    })
  },
  removeElectronicFenceData (state, fenceId) {
    const index = state.selectDeviceElectronicFenceList.findIndex(fence => fence.id === fenceId)
    if (index > -1) {
      state.selectDeviceElectronicFenceList.splice(index, 1)
    }
  },
  clearDeviceElectronicFenceList (state) {
    state.selectDeviceElectronicFenceList.length = 0
  },
  clearDeviceMarkerPointList (state) {
    state.selectDeviceMarkerPointList = {}
  }
}

const actions = {
  async getElectronicFence ({ rootState }, deviceNumber) {
    const params = {
      userToken: rootState.userToken,
      deviceSerialNumber: deviceNumber
    }
    return request('/api/getFences', JSON.stringify(params))
  },
  async getMarkerPointList ({ rootState }, deviceNumber) {
    const params = {
      userToken: rootState.userToken,
      deviceSerialNumber: deviceNumber
    }
    return request('/api/getMarkers', JSON.stringify(params))
  },
  async addMarkerPoint ({ rootState }, markerData) {
    let params = {
      userToken: rootState.userToken
    }
    params = { ...params, ...markerData }
    return request('/api/addMarker', JSON.stringify(params))
  },
  async removeMarkerPoint ({ rootState }, markerId) {
    const params = {
      userToken: rootState.userToken,
      id: markerId
    }
    return request('/api/deleteMarker', JSON.stringify(params))
  },
  async editorMarkerPoint ({ rootState }, markerData) {
    let params = {
      userToken: rootState.userToken
    }
    params = { ...params, ...markerData }
    return request('/api/editMarker', JSON.stringify(params))
  },
  async addFence ({ rootState }, fenceData) {
    let params = {
      userToken: rootState.userToken
    }
    params = { ...params, ...fenceData }
    return request('/api/addFence', JSON.stringify(params))
  },
  async editorFence ({ rootState }, fenceData) {
    let params = {
      userToken: rootState.userToken
    }
    params = { ...params, ...fenceData }
    return request('/api/editFence', JSON.stringify(params))
  },
  async removeFence ({ rootState }, fenceId) {
    const params = {
      userToken: rootState.userToken,
      id: fenceId
    }
    return request('/api/deleteFence', JSON.stringify(params))
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
