const state = {
  mapCenter: { lat: 23.97565, lng: 120.9738819 },
  mapZoom: 12
}

const getters = {
  mapCenter (state) {
    return state.mapCenter
  },
  mapZoom (state) {
    return state.mapZoom
  }
}

const mutations = {
  resetMapData (state) {
    state.mapCenter = Object.assign({}, { lat: 23.97565, lng: 120.9738819 })
    state.mapZoom = 8
  },
  setMapCenter (state, center) {
    state.mapCenter = Object.assign({}, center)
  },
  setMapZoom (state, zoom) {
    state.mapZoom = zoom
  }
}

const actions = {

}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
