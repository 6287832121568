import { createLogger, createStore } from 'vuex'
import { useCookies } from 'vue3-cookies'

import adminAccount from './modules/admin/adminAccount.js'
import adminPermission from './modules/admin/adminPermission.js'
import adminDevice from './modules/admin/adminDevice.js'
import adminFleet from './modules/admin/adminFleet.js'
import adminCustomer from './modules/admin/adminCustomer.js'
import adminVipPlan from './modules/admin/adminVipPlan.js'
import adminAnnouncement from './modules/admin/adminAnnouncement.js'
import adminReport from './modules/admin/adminReport.js'
import adminFinance from './modules/admin/adminFinance.js'
import adminStatistics from './modules/admin/adminStatistics.js'
import adminSystemLog from './modules/admin/adminSystemLog.js'
import adminPlan from './modules/admin/adminPlan.js'

import clientAccount from './modules/client/clientAccount.js'
import clientAnnouncement from './modules/client/clientAnnouncement.js'
import clientService from './modules/client/clientService.js'
import clientWarning from './modules/client/clientWarning.js'
import clientVipService from './modules/client/clientVIPService.js'
import clientMileage from './modules/client/clientMileage.js'
import clientStatistics from './modules/client/clientStatistics.js'

import recorder from './modules/recorder.js'
import remote from './modules/remote.js'
import monitor from './modules/monitor.js'
import setting from './modules/setting.js'
import mapData from './modules/mapData.js'
import MapTalks from './modules/maptalks.js'
import { request } from '../api/axios.js'

const debug = process.env.NODE_ENV !== 'production'
const cookieName = 'maycharm_cartracker'

export default createStore({
  state: {
    loginTimestamp: 0,
    markerDistance: 150,
    countdownTime: 900,
    nowTime: 0,
    userToken: '',
    userData: {},
    announcementDatas: [],
    messageDatas: [],
    adminUserData: {
      accountName: '',
      accountId: -1,
      permissions: []
    },
    planItems: {}
  },
  getters: {
    countdownTime: (state) => {
      return state.countdownTime - state.nowTime
    },
    getMarkerDistance: (state) => {
      return state.markerDistance
    },
    getApiParams: (state) => {
      return {
        userToken: state.userToken
      }
    },
    getAccountName: (state) => {
      return state.userData.account
    },
    getClientUser (state) {
      return state.userData
    },
    getUserAccountType (state) {
      return state.userData.accountType
    },
    getAdminUser (state) {

    },
    getClientUserPermissions (state) {
      switch (state.userData.accountType) {
        case 1:
          return state.userData.permissions
        case 2:
          return Array.from(String(state.userData.permissions), Number)
        case 3:
          return [1, 1, 1]
      }
    },
    getUserAnnouncementList (state) {
      return state.announcementDatas
    },
    getUserMessageList (state) {
      return state.messageDatas
    },
    getAdminPermissionView: (state) => (id) => {
      return (state.userData.permissions[id])[3] === 1
    },
    getAdminPermissionEdit: (state) => (id) => {
      return (state.userData.permissions[id])[1] === 1
    },
    getAdminPermissionAdd: (state) => (id) => {
      return (state.userData.permissions[id])[0] === 1
    },
    getAdminPermissionDelete: (state) => (id) => {
      return (state.userData.permissions[id])[2] === 1
    },
    getAdminLoginFirstRouterId (state) {
      for (const [key, value] of Object.entries(state.userData.permissions)) {
        if (value[3] === 1) {
          return parseInt(key)
        }
      }
    },
    getPlanItemName: (state) => (planId) => {
      return state.planItems[planId].name ?? ''
    },
    getPlans (state) {
      return state.planItems
    }
  },
  mutations: {
    countingTimer (state) {
      state.nowTime += 1
    },
    resetTimer (state) {
      state.nowTime = 0
    },
    clearAnnouncementList (state) {
      state.announcementDatas.length = 0
    },
    clearMessageList (state) {
      state.messageDatas.length = 0
    },
    updateUserData (state, accountData) {
      state.loginTimestamp = new Date().getTime() * 1000
      state.userToken = accountData.token
      state.userData = accountData
      state.announcementDatas = accountData.announcementDatas
      state.messageDatas = accountData.messageDatas
      useCookies().cookies.set(cookieName, JSON.stringify(state.userData))
    },
    updateAdminUserData (state, data) {
      data.accountType = 1
      state.userToken = data.token
      state.userData = data
      useCookies().cookies.set(cookieName, JSON.stringify(data))
    },
    clearUserData (state) {
      state.userData = {}
    },
    setPlanItem (state, data) {
      data.rows.forEach(item => {
        state.planItems[item.id] = item
      })
    }
  },
  actions: {
    async clientLogin ({ state }, data) {
      console.log(JSON.stringify(data))
      return request('/api/login', JSON.stringify(data))
    },
    async getClientFleetData ({ state }, { account, token }) {
      const params = {
        userToken: token,
        pageIndex: 0,
        pageRows: 0,
        keyword: account
      }
      return request('/api/getFleets', JSON.stringify(params))
    },
    async getClientDeviceData ({ state }, { device, token }) {
      const params = {
        userToken: token,
        pageIndex: 0,
        pageRows: 1,
        deviceSerialNumber: device,
        lastLoadingTimestamp: state.loginTimestamp
      }
      return request('/api/getDevices', JSON.stringify(params))
    },
    async covertLocateToAddress ({ state }, data) {
      let params = {
        userToken: state.userToken
      }
      params = { ...params, ...data }
      return request('/api/convertGpsToAddress', JSON.stringify(params))
    },
    async covertAddressToGps ({ state }, data) {
      let params = {
        userToken: state.userToken
      }
      params = { ...params, ...data }
      return request('api/convertAddressToGps', JSON.stringify(params))
    },
    async resetFleetPassword ({ state }, data) {
      return request('api/resetPassword', JSON.stringify(data))
    },
    async getPlans ({ state }) {
      const params = {
        userToken: state.userToken,
        pageIndex: 0,
        pageRows: 0
      }
      return request('api/getPlans', JSON.stringify(params))
    }
  },
  modules: {
    adminAccount,
    adminPermission,
    adminDevice,
    adminFleet,
    adminCustomer,
    adminVipPlan,
    adminAnnouncement,
    adminReport,
    adminFinance,
    adminStatistics,
    adminSystemLog,
    adminPlan,
    clientAccount,
    clientAnnouncement,
    clientService,
    clientWarning,
    clientVipService,
    clientMileage,
    clientStatistics,
    remote,
    recorder,
    monitor,
    setting,
    mapData,
    MapTalks
  },
  strict: debug,
  plugins: debug ? [createLogger()] : []
})
