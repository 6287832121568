<template>
  <div class="container-fluid mainLayout">
    <div class="topBanner">{{ t('TOP_TABS.VIP_PLAN') }}</div>
    <div class="row mt-2">
      <div class="col-2 border-end sideMenu">
        <left-side-menu/>
      </div>
      <div class="col-10">
        <router-view/>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted } from '@vue/runtime-core'
import { useI18n } from 'vue-i18n'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'
import LeftSideMenu from './VipPlanPages/LeftSideMenu.vue'
export default {

  setup () {
    const { t } = useI18n()
    const store = useStore()
    const route = useRoute()
    const router = useRouter()

    function checkPermission () {
      let monted = true
      if (route.meta) {
        if (route.meta.requiredPermission) {
          monted = store.getters.getAdminPermissionView(route.meta.permissionId)
        }
      }
      if (!monted) {
        router.push({
          name: 'admin.login'
        })
      }
    }

    onMounted(() => {
      checkPermission()
    })

    return {
      t
    }
  },
  components: {
    LeftSideMenu
  }
}
</script>

<style lang="stylus" scoped>

</style>
