<template>
  <div class="col m-1 treeView">
    <li>
      <div class="row d-flex align-items-center">
        <div class="col arrow" v-if="isFolder" @click="onToggleClick">
          <img v-if="isOpen" src="../../assets/icon/caret-down-fill.svg" alt="folder">
          <img v-else src="../../assets/icon/caret-right-fill.svg" alt="device">
        </div>
        <div v-else class="col arrow"></div>
        <check-box :type="checkStatus" @click="onCheckBoxChangeEvent" />
        <div class="col-sm-1 image">
          <img class="folder" v-if="isFolder" src="../../assets/icon/icons8-folder.svg" alt="folder">
          <img class="folder" v-else-if="isDevice" :src="carImg" alt="device">
          <img class="folder" style="filter: drop-shadow(1000px 0 0 #AAA); transform: translate(-1000px)" v-else
            src="../../assets/icon/icons8-folder.svg" alt="fleet">
        </div>
        <label class="col-auto p-0 d-flex name" @click="onToggleClick" @contextmenu="onRightClickMenu">
          {{ itemData.name }}
        </label>
      </div>
      <ul v-show="isOpen" v-if="isFolder">
        <tree-view class="item" v-for="(child, index) in itemData.children" :key="index" :data="child"
          :checked="child.isChecked ?? false" :allExpend="isOpen" @selectCallback="onSelectItemCallback"
          @rightClickCallback="onRightClickCallback" />
      </ul>
    </li>
  </div>
</template>

<script>
import { computed, ref, watch } from '@vue/runtime-core'
import CheckBox from './Tools/CheckBox.vue'
import { useI18n } from 'vue-i18n'
export default {
  components: { CheckBox },
  props: {
    data: Object,
    checked: Boolean,
    allExpend: Boolean
  },
  emits: ['selectCallback', 'rightClickCallback'],
  setup (props, { emit }) {
    const { t } = useI18n()
    const itemData = computed(() => props.data)
    const isOpen = ref(props.allExpend)

    const checkStatus = ref(0)
    const showRightMenu = ref(false)

    const isFolder = computed(() => {
      return (props.data.children && props.data.children.length > 0) ?? false
    })

    const carOff = require('../../assets/images/caroff.gif')
    const carOn = require('../../assets/images/caron.gif')

    // const carImg = computed(() => props.data.data ? ((props.data.data.deviceStatusCode === null || props.data.data.deviceStatusCode === 1 || props.data.data.deviceStatusCode === 4) ? carOff : carOn) : carOff)
    const carImg = computed(() => {
      if (props.data.data) {
        switch (props.data.data.deviceStatusCode) {
          case 2: // Parking
            return carOn
          case 3: // Moving
            return carOn
          default:
            return carOff
        }
      } else {
        return carOff
      }
    })
    const isDevice = computed(() => {
      return props.data.type === 'Device'
    })

    function resetSelfCheckStatus () {
      if (props.data.isChecked) {
        if (props.data.children && props.data.children.length > 0) {
          const allChildSelected = checkAllChildSelected(props.data)
          checkStatus.value = allChildSelected ? 1 : 2
        } else {
          checkStatus.value = 1
        }
      } else {
        checkStatus.value = 0
      }
      if (props.data.type === 'Device') {
        emit('selectCallback', checkStatus.value, props.data)
      }
    }

    function checkAllChildSelected (item) {
      let allCheckStatus = true
      item.children.forEach(child => {
        if (allCheckStatus) {
          if (child.isChecked && child.type === 'Fleet') {
            allCheckStatus = checkAllChildSelected(child)
          } else {
            allCheckStatus = child.isChecked
          }
        }
      })
      return allCheckStatus
    }

    function checkFolderChecked (item) {
      let hasChecked = false
      item.children.forEach(child => {
        if (!hasChecked && child.isChecked) {
          hasChecked = true
        }
      })
      return hasChecked
    }

    function onToggleClick () {
      if (isFolder) {
        isOpen.value = !isOpen.value
      }
    }

    function setChildChecked (itemData, isChecked) {
      if (itemData.children && itemData.children.length > 0) {
        itemData.children.forEach(child => {
          child.isChecked = isChecked
          setChildChecked(child, child.isChecked)
        })
      }
    }

    function onRightClickMenu (event) {
      onRightClickCallback(event, itemData)
      event.preventDefault()
    }

    function onRightClickCallback (event, data) {
      emit('rightClickCallback', event, data)
    }

    watch(() => props.checked, (oldVal, newVal) => {
      resetSelfCheckStatus()
    })

    function onSelectCallback (type, item) {
      emit('selectCallback', type, item)
    }

    return {
      t,
      isOpen,
      isDevice,
      itemData,
      isFolder,
      carImg,
      checkStatus,
      showRightMenu,
      onToggleClick,
      resetSelfCheckStatus,
      setChildChecked,
      checkAllChildSelected,
      onSelectCallback,
      checkFolderChecked,
      onRightClickMenu,
      onRightClickCallback
    }
  },
  methods: {
    onCheckBoxChangeEvent () {
      this.itemData.isChecked = !this.itemData.isChecked
      this.setChildChecked(this.itemData, this.itemData.isChecked)
      this.resetSelfCheckStatus()
    },
    onSelectItemCallback (type, item) {
      if (this.isFolder) {
        this.itemData.isChecked = this.checkFolderChecked(this.itemData)
        this.resetSelfCheckStatus()
      }
      this.onSelectCallback(type, item)
    }
  }
}
</script>

<style lang="stylus" scoped>
.treeView {
  li {
    list-style none
  }
  .item {
    cursor: pointer;
  }
  .bold {
    font-weight: bold;
  }
  ul {
    padding-left: 0.7em;
    line-height: 1.1em;
    list-style-type: dot;
  }
  .folder {
    width: 16px
    height: 16px
  }
  .image {
    margin: 0 1px 0 5px
    padding: 0
  }
  .arrow {
    margin: 0 5px 0 0
    padding: 0
    max-width: 16px
  }
  .name {
    font-size: 0.7rem
  }
}

</style>
