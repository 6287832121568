import { request } from '../../api/axios'

const state = {
  voiceRecorderLogList: []
}

const getters = {
  voiceRecorderList (state) {
    return state.voiceRecorderLogList
  }
}

const mutations = {
  addVoiceRecordLogList (state, data) {
    state.voiceRecorderLogList.length = 0
    let startIndex = 0
    if (data.deviceVoiceLogs.length > 20) {
      startIndex = data.deviceVoiceLogs.length - 20
    }
    for (let i = startIndex; i < data.deviceVoiceLogs.length; i++) {
      state.voiceRecorderLogList.push(data.deviceVoiceLogs[i])
    }
  }
}

const actions = {
  async getVoiceRevorderLogs ({ rootState }, deviceNumber) {
    const params = {
      userToken: rootState.userToken,
      deviceSerialNumber: deviceNumber
    }
    return request('/api/getVoiceRecorderLogs', JSON.stringify(params))
  },
  async uploadVoiceRecorderLogs ({ rootState }, uploadData) {
    let params = {
      userToken: rootState.userToken
    }
    params = { ...params, ...uploadData }
    return request('/api/uploadVoiceRecorderLogs', JSON.stringify(params))
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
