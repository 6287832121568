<template>
  <div class="fixed-top d-flex">
    <div class="p-2">
      <img class="toplogo" style="width:122px height: 50px" src="../../assets/images/topLogo.png" alt="Logo">
    </div>
    <div class="p-2 flex-grow-1 align-self-center">
      <label class="d-flex">昇豪科技GPS查車平台</label>
    </div>
    <div class="align-self-center m-3">
      <status-bar></status-bar>
    </div>
  </div>
</template>

<script>
import StatusBar from './StatusBar.vue'
export default {
  components: { StatusBar },
  setup () {
  }
}
</script>

<style lang="stylus" scoped>
.d-flex {
  top: 0
}

</style>
