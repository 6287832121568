import { request } from '../../../api/axios'

const state = {
  list: [],
  pageIndex: 0,
  pageRows: 50,
  totalCount: 0,
  addDays: 30,
  servicePlanList: [],
  addressPlanList: []
}

const getters = {
  pageIndex (state) {
    return state.pageIndex
  },
  pageRows (state) {
    return state.pageRows
  },
  totalCount (state) {
    return state.totalCount
  },
  getServiceExpiredDeviceList (state) {
    return state.list
  },
  getServicePlanListByPlanId: (state) => (planId) => {
    return state.servicePlanList.filter(item => item.planId === planId)
  },
  getAddressPlanList (state) {
    return state.addressPlanList
  }
}

const mutations = {
  setPageIndex (state, index) {
    state.pageIndex = index
  },
  setServiceExpiredDeviceList (state, data) {
    state.list.length = 0
    state.totalCount = data.total
    data.rows.forEach(element => {
      state.list.push(element)
    })
  },
  setServicePlanList (state, data) {
    state.servicePlanList.length = 0
    data.rows.forEach(element => {
      if (element.activeStatus === 1) {
        state.servicePlanList.push(element)
      }
    })
  },
  setAddressPlanList (state, data) {
    state.addressPlanList.length = 0
    data.rows.forEach(element => {
      state.addressPlanList.push(element)
    })
  }
}

const actions = {
  async searchServiceExpiredDeviceByKeyword ({ state, rootState }, key) {
    const date = new Date()
    date.setDate(date.getDate() + state.addDays + 1)
    date.setHours(0, 0, 0, 0)
    const params = {
      userToken: rootState.userToken,
      pageIndex: state.pageIndex,
      pageRows: state.pageRows,
      keyword: key
    }
    return request('/api/getDevices', JSON.stringify(params))
  },
  async getServiceExpiredDevice ({ state, rootState }) {
    const date = new Date()
    date.setDate(date.getDate() + state.addDays + 1)
    date.setHours(0, 0, 0, 0)
    const params = {
      userToken: rootState.userToken,
      pageIndex: state.pageIndex,
      pageRows: state.pageRows,
      filterServiceExpiredDate: date.getTime() * 1000
    }
    return request('/api/getDevices', JSON.stringify(params))
  },
  async extendServiceDate ({ rootState }, data) {
    let params = {
      userToken: rootState.userToken
    }
    params = { ...params, ...data }
    return request('/api/extendServiceDate', JSON.stringify(params))
  },
  async addManualPlanOrder ({ rootState }, data) {
    let params = {
      userToken: rootState.userToken
    }
    params = { ...params, ...data }
    return request('/api/addManualPlanOrder', JSON.stringify(params))
  },
  async getServicePlan ({ rootState }) {
    const params = {
      userToken: rootState.userToken,
      pageIndex: 0,
      pageRows: 0
    }
    return request('/api/getPlanItems', JSON.stringify(params))
  },
  async getAddressPlanList ({ state, rootState }) {
    const params = {
      userToken: rootState.userToken,
      pageIndex: 0,
      pageRows: 0,
      flags: [2]
    }
    return request('/api/getVipPlanItems', JSON.stringify(params))
  },
  async manualAdjustService ({ state, rootState }, data) {
    let params = {
      userToken: rootState.userToken
    }
    params = { ...params, ...data }
    console.log(JSON.stringify(params))
    return request('/api/manualAdjustService', JSON.stringify(params))
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
