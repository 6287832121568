<template>
  <div class="container-fluid clientLayout">
    <div class="row-3 border-end">
      <client-menu-tab/>
    </div>
    <div class="row-10 mb-5">
      <router-view/>
    </div>
  </div>
</template>

<script>
import ClientMenuTab from './ClientMenuTab.vue'
export default {
  components: {
    ClientMenuTab
  }
}
</script>

<style lang="stylus" scoped>

</style>
