<template>
  <div v-if="showModal" class="modal-overlay">
    <div class="modal-dialog">
      <div class="modal-content">
        <h4 class="modal-title">{{ t('ADMIN_DEVICE.MODAL_VIEW_TITLE') }}</h4>
        <hr />
        <form class="row g-3 p-3">
          <div class="form-group row field align-items-center">
            <label class="col-sm-3 mx-3">{{ t('CONTEXT_MENU.MODAL_DEVICE_CONTENT[0]') }}</label>
            <label class="col-sm">{{ deviceData.deviceSerialNumber }}</label>
          </div>
          <div class="form-group row mt-2 field align-items-center">
            <label class="col-sm-3 mx-3">{{ t('CONTEXT_MENU.MODAL_DEVICE_CONTENT[1]') }}</label>
            <div class="col-sm">
              <input name="userName" type="text" class="form-control" :disabled="accountPermissions[1] === 0"
                v-model="deviceData.carName">
            </div>
          </div>
          <div class="form-group row mt-2 field align-items-center">
            <label class="col-sm-3 mx-3">{{ t('CONTEXT_MENU.MODAL_DEVICE_CONTENT[2]') }}</label>
            <div class="col-sm">
              <input name="carName" type="text" class="form-control" :disabled="accountPermissions[1] === 0"
                v-model="deviceData.deviceOwnerName">
            </div>
          </div>
          <div class="form-group row mt-2 field align-items-center">
            <label class="col-sm-3 mx-3">{{ t('CONTEXT_MENU.MODAL_DEVICE_CONTENT[3]') }}</label>
            <div class="col-sm">
              <input name="phoneNo" type="text" class="form-control" :disabled="accountPermissions[1] === 0"
                v-model="deviceData.phoneNumber">
            </div>
          </div>
          <div class="form-group row mt-2 field align-items-center">
            <label class="col-sm-3 mx-3">{{ t('CONTEXT_MENU.MODAL_DEVICE_CONTENT[8]') }}</label>
            <div class="col-sm">
              <input name="email" type="text" class="form-control" :disabled="accountPermissions[1] === 0"
                v-model="deviceData.email">
            </div>
          </div>
          <div class="form-group row mt-2 field align-items-center">
            <label class="col-sm-3 mx-3">{{ t('CONTEXT_MENU.MODAL_DEVICE_CONTENT[4]') }}</label>
            <div class="col-sm">
              <input name="password" type="password" class="form-control" :disabled="accountPermissions[1] === 0"
                v-model="password">
            </div>
          </div>
          <div class="form-group row mt-2 field">
            <label class="col-sm-3 mx-3">{{ t('ADMIN_DEVICE.MODAL_VIEW_CONTENT[11]') }}</label>
            <label class="col-sm">{{ deviceData.convertGpsToAddressTimes }}</label>
          </div>
          <div class="form-group row mt-2 field align-items-center">
            <label class="col-sm-3 mx-3">{{ t('CONTEXT_MENU.MODAL_DEVICE_CONTENT[5]') }}</label>
            <label class="col-sm">{{ deviceData.simNumber?? '---' }}</label>
          </div>
          <div class="form-group row my-2 field align-items-center">
            <label class="col-sm-3 mx-3">{{ t('CONTEXT_MENU.MODAL_DEVICE_CONTENT[6]') }}</label>
            <label class="col-sm mx-3">{{ tools.getDateTime(deviceData.serviceExpiredDate, '---') }}</label>
          </div>
          <div class="form-group row my-2 field align-items-center">
            <label class="col-sm-3 mx-3">{{ t('CONTEXT_MENU.MODAL_DEVICE_CONTENT[7]') }}</label>
            <div class="col">
              <div class="row row-cols-3">
                <div class="col form-check">
                  <input class="form-check-input" type="radio" name="carIcon" id="car1" :value="1"
                    :disabled="accountPermissions[1] === 0" v-model.number="deviceData.deviceIcon" />
                  <label class="form-check-label" for="car1">{{ t('CONTEXT_MENU.CAR_ICON[0]') }}</label>
                </div>
                <div class="col form-check">
                  <input class="form-check-input" type="radio" name="carIcon" id="car2" :value="2"
                    :disabled="accountPermissions[1] === 0" v-model.number="deviceData.deviceIcon" />
                  <label class="form-check-label" for="car2">{{ t('CONTEXT_MENU.CAR_ICON[1]') }}</label>
                </div>
                <div class="col form-check">
                  <input class="form-check-input" type="radio" name="carIcon" id="car3" :value="3"
                    :disabled="accountPermissions[1] === 0" v-model.number="deviceData.deviceIcon" />
                  <label class="form-check-label" for="car3">{{ t('CONTEXT_MENU.CAR_ICON[2]') }}</label>
                </div>
                <div class="col form-check">
                  <input class="form-check-input" type="radio" name="carIcon" id="car4" :value="4"
                    :disabled="accountPermissions[1] === 0" v-model.number="deviceData.deviceIcon" />
                  <label class="form-check-label" for="car4">{{ t('CONTEXT_MENU.CAR_ICON[3]') }}</label>
                </div>
                <div class="col form-check">
                  <input class="form-check-input" type="radio" name="carIcon" id="car5" :value="5"
                    :disabled="accountPermissions[1] === 0" v-model.number="deviceData.deviceIcon" />
                  <label class="form-check-label" for="car5">{{ t('CONTEXT_MENU.CAR_ICON[4]') }}</label>
                </div>
                <div class="col form-check">
                  <input class="form-check-input" type="radio" name="carIcon" id="car6" :value="6"
                    :disabled="accountPermissions[1] === 0" v-model.number="deviceData.deviceIcon" />
                  <label class="form-check-label" for="car6">{{ t('CONTEXT_MENU.CAR_ICON[5]') }}</label>
                </div>
                <div class="col form-check">
                  <input class="form-check-input" type="radio" name="carIcon" id="car7" :value="7"
                    :disabled="accountPermissions[1] === 0" v-model.number="deviceData.deviceIcon" />
                  <label class="form-check-label" for="car7">{{ t('CONTEXT_MENU.CAR_ICON[6]') }}</label>
                </div>
              </div>
            </div>
          </div>
        </form>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" @click="onCancelClickEvent">{{ t('GENERAL.CLOSE') }}</button>
          <button type="button" class="btn btn-primary" @click="onSubmitClickEvent"
            :disabled="accountPermissions[1] === 0">{{ t('GENERAL.SAVE') }}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, inject, ref } from '@vue/runtime-core'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'
// import md5 from 'js-md5'
export default {
  name: 'ModalDeviceViewer',
  emits: ['updateCallback'],
  setup (props, { emit }) {
    const { t } = useI18n()
    const tools = inject('tools')
    const store = useStore()
    const configData = inject('configData')

    const accountPermissions = computed(() => store.getters.getClientUserPermissions)

    const password = ref('')
    const showModal = ref(false)
    const deviceData = ref({})

    function showModalWithData (data) {
      password.value = ''
      deviceData.value = Object.assign({}, data)
      if (tools.isEmpty(deviceData.value.serviceStatusCode)) {
        deviceData.value.serviceStatusCode = 0
      }
      if (!deviceData.value.carName) {
        deviceData.value.carName = deviceData.value.alias
      }
      showModal.value = true
    }

    function onModalCancel () {
      showModal.value = false
    }

    function onModalSubmit () {
      if (password.value !== '') {
        deviceData.value.password = password.value.toString()
      }
      emit('updateCallback', deviceData.value)
      showModal.value = false
    }

    return {
      t,
      accountPermissions,
      tools,
      configData,
      showModal,
      deviceData,
      password,
      showModalWithData,
      onModalCancel,
      onModalSubmit
    }
  },
  methods: {
    onCancelClickEvent () {
      this.onModalCancel()
    },
    onSubmitClickEvent () {
      this.onModalSubmit()
    }
  }
}
</script>

<style lang="stylus" scoped>

</style>
