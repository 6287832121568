<template>
  <div v-if="showModal" class="modal-overlay">
    <div class="modal-dialog">
      <div class="modal-content">
        <h4 class="modal-title">{{ modalTitle }}{{ t('ADMIN_FLEET.MODAL_SUB_FLEET_TITLE') }}</h4>
        <hr />
        <form class="row g-3 p-3">
          <div class="form-group row mt-3 field">
            <label class="form-label col-auto">{{ t('ADMIN_FLEET.MODAL_MAIN_FLEET') }}:</label>
            <label class="col">{{ parentFleetName }}</label>
          </div>
          <div class="col-md-6 field">
            <label class="form-label">{{ t('ADMIN_FLEET.MODAL_SUB_FLEET_CONTENT[0]')}}</label>
            <input name="account" type="text" class="form-control" v-model="subFleetData.account" :disabled="isEditor">
          </div>
          <div class="col-md-6 field">
            <label class="form-label">{{ t('ADMIN_FLEET.MODAL_SUB_FLEET_CONTENT[1]') }}</label>
            <input type="password" class="form-control" :placeholder="t('GENERAL.PASSWORD_HOLDER')"
              :disabled="isDisablePermission" v-model="subFleetData.password">
          </div>
          <div class="form-group row mt-3 field">
            <label class="form-label">{{ t('ADMIN_FLEET.MODAL_SUB_FLEET_CONTENT[2]') }}</label>
            <div class="col">
              <input :disabled="isDisablePermission" name="alias" type="text" class="form-control"
                v-model="subFleetData.alias">
            </div>
          </div>
          <div class="form-group row mt-3 field">
            <label class="col-sm-2">{{ t('ADMIN_FLEET.MODAL_SUB_FLEET_CONTENT[3]') }}</label>
            <div class="border col py-3">
              <div class="row">
                <div class="col-4" v-for="(value, id) of configData.fleetSubPermission" :key="id">
                  <input :id="value" type="checkbox" class="form-check-input" :true-value="1" :false-value="0"
                    :disabled="isDisablePermission" v-model="permission[id]">
                  <label :for="value" class="form-check-label mx-2">{{ t(value) }}</label>
                </div>
              </div>
            </div>
          </div>
        </form>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" @click="onCancelClickEvent">{{ t('GENERAL.CLOSE') }}</button>
          <button type="button" class="btn btn-danger" @click="onSubmitClickEvent" :disabled="isDisablePermission">{{
          btnSubmit }}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, inject, reactive, ref } from '@vue/runtime-core'
import { useI18n } from 'vue-i18n'
import md5 from 'js-md5'
import { useStore } from 'vuex'
export default {
  emits: ['modalCallback'],
  setup (props, { emit }) {
    const { t } = useI18n()
    const store = useStore()
    const defaultData = inject('defaultData')
    const configData = inject('configData')
    const tools = inject('tools')

    const isEditor = ref(false)
    const showModal = ref(false)

    const modalTitle = computed(() => (isEditor.value ? t('GENERAL.EDITOR') : t('GENERAL.ADD')))
    const btnSubmit = computed(() => (isEditor.value ? t('GENERAL.SAVE') : t('GENERAL.ADD')))
    const accountPermissions = computed(() => store.getters.getClientUserPermissions)
    const parentFleetName = computed(() => parentFleetData.value.account ?? '')
    const subFleetData = reactive({})
    const parentFleetData = ref({})

    const permission = reactive([])

    const isDisablePermission = computed(() => {
      if (Array.isArray(accountPermissions.value[4])) {
        return isEditor.value ? (accountPermissions.value[4])[1] === 0 : (accountPermissions.value[4])[0] === 0
      } else {
        return accountPermissions.value[1] === 0
      }
    })

    function showModalDialog (editor, data, parentData = null) {
      isEditor.value = editor
      parentFleetData.value = Object.assign({}, parentData)
      Object.assign(subFleetData, isEditor.value ? data : defaultData.fleetSubData)
      permission.length = 0
      permission.push(...Array.from(String(subFleetData.permissions), Number))
      if (parentData === null && isEditor.value) {
        getParentFleetData(data.parentFleetId)
      }
      showModal.value = true
    }

    function getParentFleetData (parentFleetId) {
      store.dispatch('adminFleet/getAdminParentFleetList', parentFleetId).then((res) => {
        if (res.data?.rows.length > 0) {
          parentFleetData.value = Object.assign({}, res.data.rows[0])
        }
      })
    }

    function onCancelClickEvent () {
      showModal.value = false
    }

    function onSubmitClickEvent () {
      subFleetData.permissions = ''
      subFleetData.permissions = permission.join('')
      subFleetData.password = tools.isEmpty(subFleetData.password) ? '' : md5(subFleetData.password)
      emit('modalCallback', isEditor.value, subFleetData)
      showModal.value = false
    }

    return {
      t,
      accountPermissions,
      parentFleetData,
      parentFleetName,
      isEditor,
      showModal,
      configData,
      modalTitle,
      btnSubmit,
      subFleetData,
      permission,
      isDisablePermission,
      onCancelClickEvent,
      onSubmitClickEvent,
      showModalDialog
    }
  }

}
</script>

<style lang="stylus" scoped>

</style>
