<template>
  <div class="d-flex">
    <div class="align-self-center mx-5 autoLogout">
      <label>{{ t('GENERAL.AUTO_LOGOUT') }}:</label>
      <label>{{ showTimer }}</label>
    </div>
    <div class="align-self-center mx-2 language">
      <label>{{ t('LANGUAGE.TITLE') }}:</label>
      <select class="mx-2" @change="onLocalLangChanged">
        <option v-for="(value, name) in configData.languageOptions" :key="name" :value="name">{{ t(value) }}</option>
      </select>
    </div>
    <div class="align-self-center me-3">{{ userData.account }}</div>
    <div class="dropdown text-end">
      <a href="#" class="d-block link-dark text-decoration-none dropdown-toggle" id="dropdownUser1"
        data-bs-toggle="dropdown" aria-expanded="false">
        <img src="../../assets/images/avatar_2x.png" class="rounded-circle">
      </a>
      <div class="dropdown-menu text-small" aria-labelledby="dropdownUser1" style="">
        <button class="dropdown-item" @click="onFebackClickEvent">{{ t('GENERAL.FEEBACK') }}</button>
        <button class="dropdown-item" @click="onMessageClickEvent">{{ t('GENERAL.MESSAGE_LIST') }}</button>
        <a class="dropdown-item" href="https://www.toptrack.com.tw/article-NEWS07.html" target="_blank">{{
        t('GENERAL.HELP') }}</a>
        <div class="dropdown-divider"></div>
        <button class="dropdown-item" @click="onLogoutClickEvent">{{ t('GENERAL.LOGOUT') }}</button>
      </div>
    </div>
  </div>
  <modal-feeback ref="ModalFeebackDialog" @modalCallback="onFeebackModalCallback" />
  <modal-history-message ref="ModalHistoryMessageDialog"/>
  <modal-error-msg ref="ModalErrorDialg" />
</template>

<script>
import { computed, inject, onMounted, onUnmounted, ref } from '@vue/runtime-core'
import { useI18n } from 'vue-i18n'
import { useCookies } from 'vue3-cookies'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import ModalFeeback from '../Modal/client/ModalFeeback.vue'
import ModalErrorMsg from '../Modal/ModalErrorMsg.vue'
import ModalHistoryMessage from '../Modal/client/ModalHistoryMessage.vue'
export default {
  components: { ModalFeeback, ModalErrorMsg, ModalHistoryMessage },
  setup () {
    const { t, locale } = useI18n()
    const { cookies } = useCookies()
    const store = useStore()
    const router = useRouter()
    const configData = inject('configData')

    const ModalFeebackDialog = ref()
    const ModalHistoryMessageDialog = ref()
    const ModalErrorDialg = ref()

    const startCountdown = ref(false)
    const startCountdownInterval = ref()

    const showTimer = computed(() => {
      const date = new Date(lastCountdownTime.value * 1000)
      return date.getMinutes() + ':' + date.getSeconds()
    })

    const lastCountdownTime = computed(() => store.getters.countdownTime)

    function startToCountdown () {
      startCountdown.value = true
      startCountdownInterval.value = setInterval(() => {
        if (startCountdown.value) {
          if (lastCountdownTime.value > 0) {
            store.commit('countingTimer')
          } else {
            startCountdown.value = false
            onLogoutClickEvent()
          }
        } else {
          clearInterval(startCountdownInterval.value)
        }
      }, 1000)
    }

    const userData = computed(() => store.getters.getClientUser)

    const onLogoutClickEvent = () => {
      cookies.remove(configData.cookieName)
      store.commit('clearUserData')
      router.push({
        name: 'login'
      })
    }

    function onFebackClickEvent () {
      ModalFeebackDialog.value.openModal()
    }

    function onFeebackModalCallback (data) {
      store.dispatch('addReportData', data).then((res) => {

      }).catch((err) => {
        showErrModal(err)
      })
    }

    function onMessageClickEvent () {
      ModalHistoryMessageDialog.value?.showModalWithData(userData.value)
    }

    function showErrModal (err) {
      ModalErrorDialg.value.showModalWithData(false, '', err)
    }

    function onLocalLangChanged (evt) {
      locale.value = evt.target.value
    }

    onUnmounted(() => {
      startCountdown.value = false
    })

    onMounted(() => {
      const cookieData = cookies.get(configData.cookieName)
      if (cookieData) {
        if (process.env.NODE_ENV === 'production') {
          startToCountdown()
        }
      } else {
        router.push({
          name: 'login'
        })
      }
    })

    return {
      t,
      configData,
      userData,
      showTimer,
      ModalFeebackDialog,
      ModalHistoryMessageDialog,
      ModalErrorDialg,
      onLogoutClickEvent,
      onFebackClickEvent,
      onMessageClickEvent,
      onFeebackModalCallback,
      onLocalLangChanged
    }
  }
}
</script>

<style lang="stylus" scoped>
.rounded-circle {
  width: 32px
  height: 32px
}
</style>
