<template>
  <div v-if="showModal" class="modal-overlay">
    <div class="modal-dialog">
      <div class="modal-content">
        <h4 class="modal-title">{{ t('ADMIN_DEVICE.MODAL_VIEW_TITLE') }}</h4>
        <hr />
        <form class="row g-3 p-3">
          <div class="form-group row field">
            <label class="col-sm-4 mx-3">{{ t('ADMIN_DEVICE.MODAL_VIEW_CONTENT[0]') }}</label>
            <label class="col-sm">{{ deviceData.deviceSerialNumber }}</label>
          </div>
          <div class="form-group row mt-2 field">
            <label class="col-sm-4 mx-3">{{ t('ADMIN_DEVICE.MODAL_VIEW_CONTENT[1]') }}</label>
            <div class="col-sm">
              <input name="userName" type="text" class="form-control" v-model="deviceData.deviceOwnerName">
            </div>
          </div>
          <div class="form-group row mt-2 field">
            <label class="col-sm-4 mx-3">{{ t('ADMIN_DEVICE.MODAL_VIEW_CONTENT[2]') }}</label>
            <div class="col-sm">
              <input name="carName" type="text" class="form-control" v-model="deviceData.carName">
            </div>
          </div>
          <div class="form-group row mt-2 field">
            <label class="col-sm-4 mx-3">{{ t('ADMIN_DEVICE.MODAL_VIEW_CONTENT[12]') }}</label>
            <div class="col-sm">
              <input name="email" type="text" class="form-control" v-model="deviceData.email">
            </div>
          </div>
          <div class="form-group row mt-2 field">
            <label class="col-sm-4 mx-3">{{ t('ADMIN_DEVICE.MODAL_VIEW_CONTENT[3]') }}</label>
            <div class="col-sm">
              <input name="simId" type="text" class="form-control" :disabled="customerEditor"
                v-model="deviceData.simNumber">
            </div>
          </div>
          <div class="form-group row mt-2 field">
            <label class="col-sm-4 mx-3">{{ t('ADMIN_DEVICE.MODAL_VIEW_CONTENT[4]') }}</label>
            <div class="col-sm">
              <input name="phoneNo" type="text" class="form-control" :disabled="customerEditor"
                v-model="deviceData.phoneNumber">
            </div>
          </div>
          <div class="form-group row mt-2 field">
            <label class="col-sm-4 mx-3">{{ t('ADMIN_DEVICE.MODAL_VIEW_CONTENT[5]') }}</label>
            <div class="input-group col-sm">
              <div v-if="!customerEditor" class="input-group-text">
                <input class="form-check-input mt-0" type="checkbox" v-model="enableEditorPW"/>
              </div>
              <input name="password" :type="showPassword ? 'text' : 'password'" class="form-control" :disabled="customerEditor || !enableEditorPW" v-model="password">
              <button v-if="!customerEditor" type="button" class="btn btn-outline-secondary" :disabled="!enableEditorPW" @click="onShowPassword()">
                <svg v-if="showPassword" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-eye"
                viewBox="0 0 16 16">
                  <path
                    d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z" />
                  <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z" />
                </svg>
                <svg v-else xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-eye-slash"
                  viewBox="0 0 16 16">
                  <path
                    d="M13.359 11.238C15.06 9.72 16 8 16 8s-3-5.5-8-5.5a7.028 7.028 0 0 0-2.79.588l.77.771A5.944 5.944 0 0 1 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.134 13.134 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755-.165.165-.337.328-.517.486l.708.709z" />
                  <path
                    d="M11.297 9.176a3.5 3.5 0 0 0-4.474-4.474l.823.823a2.5 2.5 0 0 1 2.829 2.829l.822.822zm-2.943 1.299.822.822a3.5 3.5 0 0 1-4.474-4.474l.823.823a2.5 2.5 0 0 0 2.829 2.829z" />
                  <path
                    d="M3.35 5.47c-.18.16-.353.322-.518.487A13.134 13.134 0 0 0 1.172 8l.195.288c.335.48.83 1.12 1.465 1.755C4.121 11.332 5.881 12.5 8 12.5c.716 0 1.39-.133 2.02-.36l.77.772A7.029 7.029 0 0 1 8 13.5C3 13.5 0 8 0 8s.939-1.721 2.641-3.238l.708.709zm10.296 8.884-12-12 .708-.708 12 12-.708.708z" />
                </svg>
              </button>
            </div>
          </div>
          <div class="form-group row mt-2 field">
            <label class="col-sm-4 mx-3">{{ t('ADMIN_DEVICE.MODAL_VIEW_CONTENT[11]') }}</label>
            <label class="col-sm">{{ deviceData.convertGpsToAddressTimes }}</label>
          </div>
          <div class="form-group row mt-2 field">
            <label class="col-sm-4 mx-3">{{ t('ADMIN_DEVICE.MODAL_VIEW_CONTENT[6]') }}</label>
            <div class="col-sm">
              <select class="form-select" v-model="deviceData.carElectricity">
                <option v-for="(value, index) in configData.carPower" :value="index" :key="index">{{ t(value) }}
                </option>
              </select>
            </div>
          </div>
          <div class="form-group row mt-2 field">
            <label class="col-sm-4 mx-3">{{ t('ADMIN_DEVICE.MODAL_VIEW_CONTENT[7]') }}</label>
            <div class="col-sm">
              <input name="version" type="text" class="form-control" :disabled="customerEditor"
                v-model="deviceData.firmwareVersion">
            </div>
          </div>
          <div class="form-group row mt-2 field">
            <label class="col-sm-4 mx-3">{{ t('ADMIN_DEVICE.MODAL_VIEW_CONTENT[8]') }}</label>
            <div class="col-sm">
              <input name="esimId" type="text" class="form-control" v-model="deviceData.esimId">
            </div>
          </div>
          <div class="form-group row mt-2 field">
            <label class="col-sm-4 mx-3">{{ t('ADMIN_DEVICE.MODAL_VIEW_CONTENT[9]') }}</label>
            <div class="col-sm">
              <select class="form-select" v-model="deviceData.isValueAddedVisible">
                <option v-for="(value, index) in configData.vipPlanShowOn" :value="index" :key="index">{{ t(value) }}
                </option>
              </select>
            </div>
          </div>
          <div class="form-group row mt-2 field">
            <label class="col-sm-4 mx-3">{{ t('ADMIN_DEVICE.MODAL_VIEW_CONTENT[10]') }}</label>
            <div class="col-sm">
              <select class="form-select" v-model="deviceData.serviceStatusCode" :disabled="customerEditor">
                <option v-for="(value, id) in configData.serviceStatus" :value="id" :key="id">{{ t(value) }}
                </option>
              </select>
            </div>
          </div>
        </form>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" @click="onCancelClickEvent">{{ t('GENERAL.CLOSE') }}</button>
          <button type="button" class="btn btn-primary" @click="onSubmitClickEvent">{{ t('GENERAL.SAVE') }}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { inject, reactive, ref } from '@vue/runtime-core'
import { useI18n } from 'vue-i18n'
import md5 from 'js-md5'
export default {
  name: 'ModalDeviceViewer',
  emits: ['updateCallback'],
  setup (props, { emit }) {
    const { t } = useI18n()
    const configData = inject('configData')

    const customerEditor = ref(false)

    const showModal = ref(false)
    const deviceData = reactive({})
    const showPassword = ref(false)
    const password = ref('')
    const enableEditorPW = ref(false)

    function showModalWithData (data, isCustomer = false) {
      customerEditor.value = isCustomer
      Object.assign(deviceData, data)
      if (deviceData.serviceStatusCode === null) {
        deviceData.serviceStatusCode = 'NULL'
      }
      enableEditorPW.value = false
      password.value = ''
      showModal.value = true
    }

    function onModalCancel () {
      showModal.value = false
    }

    function onModalSubmit () {
      if (password.value !== '' && enableEditorPW.value) {
        deviceData.password = md5(password.value)
      }
      if (deviceData.deviceOwnerName) {
        deviceData.ownerName = deviceData.deviceOwnerName
      }
      if (deviceData.simNumber) {
        deviceData.simCardNumber = deviceData.simNumber
      }
      if (deviceData.phoneNumber) {
        deviceData.ownerPhone = deviceData.phoneNumber
      }
      if (deviceData.carElectricity) {
        deviceData.isCarElectricity = deviceData.carElectricity
      }
      deviceData.serviceStatusCode = deviceData.serviceStatusCode === 'NULL' ? deviceData.serviceStatusCode : parseInt(deviceData.serviceStatusCode)
      emit('updateCallback', deviceData)
      showModal.value = false
    }

    function onShowPassword () {
      showPassword.value = !showPassword.value
    }

    return {
      t,
      configData,
      showPassword,
      customerEditor,
      showModal,
      deviceData,
      password,
      enableEditorPW,
      showModalWithData,
      onModalCancel,
      onModalSubmit,
      onShowPassword
    }
  },
  methods: {
    onCancelClickEvent () {
      this.onModalCancel()
    },
    onSubmitClickEvent () {
      this.onModalSubmit()
    }
  }
}
</script>

<style lang="stylus" scoped>

</style>
