import { request } from '../../../api/axios'

const state = {
  vipServiceList: []
}

const getters = {
  totalCount (state) {
    return state.vipServiceList.length
  },
  vipServiceList (state) {
    return state.vipServiceList
  }
}

const mutations = {
  setVipPlanList (state, data) {
    state.vipServiceList.length = 0
    data.rows.forEach(element => {
      if (element.statusCode === 1) {
        state.vipServiceList.push(element)
      }
    })
  },
  clearVipPlanList (state) {
    state.vipServiceList.length = 0
  }
}

const actions = {
  async getVipPlanList ({ state, rootState }) {
    const params = {
      userToken: rootState.userToken,
      pageIndex: 0,
      pageRows: 0,
      flags: [1]
    }
    return request('/api/getVipPlanItems', JSON.stringify(params))
  },
  async getAddressPlanList ({ state, rootState }) {
    const params = {
      userToken: rootState.userToken,
      pageIndex: 0,
      pageRows: 0,
      flags: [2]
    }
    return request('/api/getVipPlanItems', JSON.stringify(params))
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
