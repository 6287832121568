<template>
  <div class="checkbox d-flex align-items-center">
    <img v-if="checkState === 1" src="./Icon/checkbox-line-2.svg" alt="checked">
    <img v-else-if="checkState === 2" src="./Icon/checkbox-indeterminate-fill-2.svg" alt="leaf">
    <img v-else src="./Icon/checkbox-blank-line-1.svg" alt="unchecked">
  </div>
</template>

<script>
import { computed } from '@vue/runtime-core'
export default {
  props: ['type'],
  setup (props) {
    const checkState = computed(() => props.type)
    return {
      checkState
    }
  }
}
</script>

<style lang="stylus" scoped>
.checkbox {
  width: 16px
  height: 16px
  margin: 0 2px 0 2px
  padding: 0
}
</style>
