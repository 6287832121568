import { request } from '../../../api/axios'

const state = {
  systemLogList: [],
  pageIndex: 0,
  pageRows: 5000,
  totalCount: 0
}

const getters = {
  pageIndex (state) {
    return state.pageIndex
  },
  pageRows (state) {
    return state.pageRows
  },
  totalCount (state) {
    return state.totalCount
  },
  getSystemLogList (state) {
    return state.systemLogList
  }
}

const mutations = {
  setPageIndex (state, index) {
    state.pageIndex = index
  },
  setSystemLogList (state, data) {
    state.systemLogList.length = 0
    state.totalCount = data.total
    data.rows.forEach(element => {
      state.systemLogList.push(element)
    })
  },
  clearSystemLogList (state) {
    state.systemLogList.length = 0
  }
}

const actions = {
  async searchSystemLogList ({ commit, state, rootState }, data) {
    commit('clearSystemLogList')
    let params = {
      userToken: rootState.userToken,
      pageIndex: state.pageIndex,
      pageRows: state.pageRows,
      fullQuery: true
    }
    params = { ...params, ...data }
    console.log(JSON.stringify(params))
    return request('/api/getSystemLogs', JSON.stringify(params))
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
