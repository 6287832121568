import { createApp } from 'vue'
import { createI18n } from 'vue-i18n'
import App from './App.vue'
import router from './router'
import store from './store'

import axios from 'axios'
import VueAxios from 'vue-axios'

import BootstrapVue3 from 'bootstrap-vue-3'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue-3/dist/bootstrap-vue-3.css'
import './assets/css/default.styl'

import { VueWindowSizePlugin } from 'vue-window-size/option-api'
import PerfectScrollbar from 'vue3-perfect-scrollbar'
import 'vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css'
import jstools from './plugins/JSTools.js'
import { configData } from './plugins/configData.js'
import { defaultData } from './plugins/defaultData.js'

import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'

import Datepicker from '@vuepic/vue-datepicker'
import '@vuepic/vue-datepicker/dist/main.css'

/**
 * i18n Language package
 */
import { locale as zh } from './Lang/tw.js'
import { locale as en } from './Lang/en.js'
const i18n = createI18n({
  legacy: false,
  locale: 'zh-TW',
  fallbackLocale: 'zh-TW',
  messages: {
    'zh-TW': zh,
    'en-US': en
  }
})

const app = createApp(App).use(store).use(router)
app.provide('configData', configData)
app.provide('defaultData', defaultData)
app.use(VueAxios, axios)
app.use(BootstrapVue3)
app.use(VueWindowSizePlugin)
app.use(PerfectScrollbar)
app.use(jstools)
app.use(i18n)
app.component('Datepicker', Datepicker)
app.component('v-select', vSelect)
app.mount('#app')
