<template>
  <div class="col align-items-center d-flex item">
    <img :src="carIcon" alt="offline" class="carImg mx-1">
    <label class="col flex-grow-1 from-check-label mx-2 text-start">{{
    itemData.deviceSerialNumber }} ({{ t('MONITOR.DEVICE_STATUS_TYPE[' + (itemData.deviceStatusCode ?
    itemData.deviceStatusCode - 1 : 0) + ']')
    }})</label>
      <button class="btn btn-sm btn-danger p-0 px-1" :disabled="hasOnWatchDevice" @click="onAddItemClickEvent">{{ t('GENERAL.ADD') }}</button>
  </div>
</template>

<script>
import { computed } from '@vue/runtime-core'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'
export default {
  props: ['item'],
  emits: ['onSelectItemCallback', 'onAddDeviceClickEvent', 'onMenuCallback'],
  setup (props, { emit }) {
    const { t } = useI18n()
    const store = useStore()
    const itemData = computed(() => props.item)

    const icons = {
      carOff: require('../../assets/images/caroff.gif'),
      carOn: require('../../assets/images/caron.gif'),
      delete: require('../../assets/icon/x-square-fill.svg')
    }

    const hasOnWatchDevice = computed(() => {
      return store.getters['adminCustomer/getWatchDeviceDataByNumber'](props.item.deviceSerialNumber)
    })

    const carIcon = computed(() => {
      switch (props.item.deviceStatusCode) {
        case 2: // Parking
          return icons.carOn
        case 3: // Moving
          return icons.carOn
        default:
          return icons.carOff
      }
    })

    function onAddItemClickEvent () {
      emit('onAddDeviceClickEvent', itemData.value)
    }

    return {
      t,
      icons,
      carIcon,
      itemData,
      hasOnWatchDevice,
      onAddItemClickEvent
    }
  }
}
</script>

<style lang="stylus" scoped>
.carImg {
  width: 20px
  height: 20px
}
& button {
  height: 1.5rem
}
</style>
