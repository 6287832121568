import { request } from '../../../api/axios'

const state = {
  announcementIndex: 0,
  announcementPageRows: 20,
  announcementTotal: 0,
  announcementList: [],
  messageIndex: 0,
  messagePageRows: 20,
  messageTotal: 0,
  messageList: []
}

const getters = {
  getAnnouncementList (state) {
    return state.announcementList
  },
  getAnnouncementIndex (state) {
    return state.announcementIndex
  },
  getAnnouncementRows (state) {
    return state.announcementPageRows
  },
  getAnnouncementTotal (state) {
    return state.announcementTotal
  },
  getMessageList (state) {
    return state.messageList
  },
  getMessageIndex (state) {
    return state.messageIndex
  },
  getMessageRows (state) {
    return state.messagePageRows
  },
  getMessageTotal (state) {
    return state.messageTotal
  }
}

const mutations = {
  setAnnouncementList (state, data) {
    state.announcementList.length = 0
    state.announcementTotal = data.total
    data.rows.forEach(element => {
      state.announcementList.push(element)
    })
  },
  setAnnouncementIndex (state, index) {
    state.announcementIndex = index
  },
  setMessageList (state, data) {
    state.messageList.length = 0
    state.messageTotal = data.total
    data.rows.forEach(element => {
      state.messageList.push(element)
    })
  },
  setMessageIndex (state, index) {
    state.messageIndex = index
  }
}

const actions = {
  async resetRevicerList ({ state }) {
    state.selectRevicerList.length = 0
  },
  async getAnnouncementList ({ state, rootState }) {
    const params = {
      userToken: rootState.userToken,
      pageIndex: state.announcementIndex,
      pageRows: state.announcementPageRows
    }
    return request('/api/getAnnouncements', JSON.stringify(params))
  },
  async publishAnnouncement ({ rootState }, data) {
    let params = {
      userToken: rootState.userToken
    }
    params = { ...params, ...{ publishDate: data.activeDate, messageContent: data.content } }
    return request('/api/publishAnnouncement', JSON.stringify(params))
  },
  async deleteAnnouncement ({ rootState }, announcementId) {
    const params = {
      userToken: rootState.userToken,
      id: announcementId
    }
    return request('/api/deleteAnnouncements', JSON.stringify(params))
  },
  async getMessageList ({ state, rootState }) {
    const params = {
      userToken: rootState.userToken,
      pageIndex: state.messageIndex,
      pageRows: state.messagePageRows,
      senderAccountId: -1
    }
    return request('/api/getMessages', JSON.stringify(params))
  },
  async publishMessage ({ rootState }, data) {
    let params = {
      userToken: rootState.userToken
    }
    params = { ...params, ...data }
    return request('/api/publishMessage', JSON.stringify(params))
  },
  async deleteMessage ({ rootState }, messageId) {
    const params = {
      userToken: rootState.userToken,
      id: messageId
    }
    return request('/api/deleteMessage', JSON.stringify(params))
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
