<template>
  <div class="parentTemp">
    <top-nav></top-nav>
    <div class="container-fluid pt-3">
      <div class="row-3 border-end">
        <menu-tabs></menu-tabs>
      </div>
      <div class="row-10 mb-5 overflow-auto">
        <router-view/>
      </div>
    </div>
  </div>
</template>

<script>
import MenuTabs from './MenuTabs.vue'
import TopNav from './TopNav.vue'
export default {
  setup () {

  },
  components: {
    TopNav,
    MenuTabs
  }
}
</script>
<style lang="stylus" scoped>
.time {
  font-size: 12px
}
</style>
