import { request } from '../../../api/axios.js'

const state = {
  permissionList: [],
  pageIndex: 0,
  pageRows: 30,
  total: 0
}

const getters = {
  totalCount (state) {
    return state.total
  },
  pageIndex (state) {
    return state.pageIndex
  },
  pageRows (state) {
    return state.pageRows
  },
  getPermissionList (state) {
    return state.permissionList
  },
  getPermissionNameById: (state) => (permissionId) => {
    const data = state.permissionList.find(item => item.id === permissionId)
    return data ? data.name : ''
  },
  getPermissionDataById: (state) => (id) => {
    return state.permissionList.find(item => item.id === id)
  }
}

const mutations = {
  setPageIndex (state, index) {
    state.pageIndex = index
  },
  setPermissionList (state, data) {
    state.permissionList.length = 0
    state.total = data.total
    data.rows.forEach(element => {
      state.permissionList.push(element)
    })
  }
}

const actions = {
  async getPermissionList ({ state, rootState }, { keyword }) {
    const params = {
      userToken: rootState.userToken,
      pageIndex: state.pageIndex,
      pageRows: state.pageRows,
      keyword: keyword ?? ''
    }
    return request('/api/getPermissions', JSON.stringify(params))
  },
  async createPermission ({ rootState }, data) {
    let params = {
      userToken: rootState.userToken
    }
    params = { ...params, ...data }
    return request('/api/createPermission', JSON.stringify(params))
  },
  async updatePermission ({ rootState }, data) {
    let params = {
      userToken: rootState.userToken
    }
    params = { ...params, ...data }
    return request('/api/editPermission', JSON.stringify(params))
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
