<template>
  <div v-if="showModal" class="modal-overlay">
    <div class="modal-dialog">
      <div class="modal-content">
        <h4 class="modal-title">{{ modalTitle }}</h4>
        <hr/>
        <form class="row g-3 p-3">
          <div class="form-group row">
            <label class="col-sm-2 col-form-label">{{ t('MONITOR.ELE_FENCE[1]') }}</label>
            <div class="col-sm-10" v-if="isEditor">
              <label class="col-sm-2 col-form-label d-flex">{{ modalData.deviceSerialNumber }}</label>
            </div>
            <div class="col-sm-10" v-else>
              <select class="form-select" v-model="selectDeviceId">
                <option v-for="(device, index) in targetOptions" :key="index" :value="device.deviceId">{{ device.deviceSerialNumber }}{{ deviceServiceExpiredHint(device) }}</option>
              </select>
            </div>
          </div>
          <div class="form-group row mt-2">
            <label class="col-sm-2 col-form-label">{{ t('MONITOR.ELE_FENCE[2]') }}</label>
            <div class="col-sm-4">
              <select class="form-select" v-model="targetType">
                <option v-for="(value, index) in configData.targetType" :key="index" :value="index">{{ t(value) }}</option>
              </select>
            </div>
            <div class="col-sm-5" v-if="targetType === 2">
              <input type="text" class="col-sm-5 form-control" v-model="targetName">
            </div>
          </div>
          <div class="form-group row mt-2">
            <label class="col-sm-2 col-form-label">{{ t('MONITOR.ALARM_TYPE') }}</label>
            <div class="col-sm-10">
              <div class="row align-items-center" style="height: 100%">
                <div class="col-sm-5 d-flex">
                  <input type="checkbox" class="form-check-input" id="enter" v-model="enterAlarm">
                  <label class="form-check-label mx-2" for="enter">{{ t('MONITOR.ELE_FENCE[3]') }}</label>
                </div>
                <div class="col-sm-5 d-flex">
                  <input type="checkbox" class="form-check-input" id="exit" v-model="exitAlarm">
                  <label class="form-check-label mx-2" for="exit">{{ t('MONITOR.ELE_FENCE[4]') }}</label>
                </div>
              </div>
            </div>
          </div>
          <div class="form-group row mt-2">
            <label class="col-sm-2 col-form-label">{{ t('MONITOR.ELE_FENCE[5]') }}</label>
            <div class="col">
              <input type="text" class="col-sm-5 form-control" :placeholder="t('MONITOR.DRAW_MAP_HINT')" disabled v-model="inputCoords">
            </div>
            <button type="button" class="col-sm-2 btn btn-primary" :disabled="isExpired" @click="onDrawMapClickEvent">{{ t('GENERAL.DRAW_MAP') }}</button>
          </div>
          <div class="form-group row mt-2">
            <label class="col-sm-2 col-form-label">{{ t('MONITOR.ELE_FENCE[6]') }}</label>
            <div class="col">
              <textarea class="form-control inputTextArea" id="description" rows="5" maxlength="280" v-model="noteContent"/>
            </div>
          </div>
        </form>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" @click="onCancelClickEvent">{{ t('GENERAL.CLOSE') }}</button>
          <button type="button" class="btn btn-primary" :disabled="isExpired" @click="onSubmitClickEvent">{{ submitButtonName }}</button>
        </div>
      </div>
    </div>
  </div>
  <modal-eletronic-fence-map ref="ModalDrawEletronicFenceMap" @modalCallback="onDrawModalCallback"/>
</template>

<script>
import { ref } from '@vue/reactivity'
import { useI18n } from 'vue-i18n'
import { computed, inject, watch } from '@vue/runtime-core'
import { useStore } from 'vuex'
import ModalEletronicFenceMap from '../ModalEletronicFenceMap.vue'
export default {
  components: { ModalEletronicFenceMap },
  emits: ['modalCallback'],
  setup (props, { emit }) {
    const { t } = useI18n()
    const store = useStore()
    const tools = inject('tools')
    const configData = inject('configData')

    const accountType = computed(() => store.getters.getUserAccountType)

    const ModalDrawEletronicFenceMap = ref()

    const showModal = ref(false)
    const isEditor = ref(false)
    const isExpired = ref(false)

    const inputCoords = ref('')
    const fenceCoordsData = ref({})

    const modalTitle = computed(() => {
      return (isEditor.value ? t('GENERAL.EDITOR') : t('GENERAL.ADD')) + t('MONITOR.MODAL_ELEFENCE')
    })

    const submitButtonName = computed(() => {
      return isEditor.value ? t('GENERAL.SAVE') : t('GENERAL.ADD')
    })

    const modalData = ref({})

    const targetOptions = computed(() => store.getters['clientAccount/accountDeviceList'])
    const selectDeviceId = ref(0)
    const targetType = ref(0)
    const targetName = ref('')
    const enterAlarm = ref(false)
    const exitAlarm = ref(false)
    const noteContent = ref('')

    function checkSelectDeviceExpired () {
      const device = targetOptions.value.find(item => item.deviceId === selectDeviceId.value)
      isExpired.value = CheckDeviceExpired(device)
    }

    function CheckDeviceExpired (deviceData) {
      let checkIsExpired = true
      if (deviceData) {
        const nowTimeStamp = new Date().getTime() * 1000
        checkIsExpired = nowTimeStamp > deviceData.serviceExpiredDate
      }
      return checkIsExpired
    }

    function deviceServiceExpiredHint (device) {
      return CheckDeviceExpired(device) ? t('MODAL.SERVICE_EXPIRED') : ''
    }

    function showModalWithData (editor, data) {
      isEditor.value = editor
      if (isEditor.value) {
        setModalData(data)
      } else {
        clearModalData()
      }
      showModal.value = true
    }

    function showModalWithDeviceId (deviceId) {
      isEditor.value = false
      clearModalData()
      selectDeviceId.value = deviceId
      showModal.value = true
    }

    function clearModalData () {
      inputCoords.value = ''
      selectDeviceId.value = targetOptions.value[0].deviceId
      targetType.value = 0
      targetName.value = ''
      enterAlarm.value = false
      exitAlarm.value = false
      noteContent.value = ''
      fenceCoordsData.value = {}
    }

    function setModalData (data) {
      modalData.value = Object.assign({}, data)
      enterAlarm.value = modalData.value.isEnterWarning
      exitAlarm.value = modalData.value.isExitWarning
      targetType.value = 2
      targetName.value = modalData.value.alias
      noteContent.value = modalData.value.remark
      const coordList = []
      modalData.value.coordinates.forEach(coord => {
        const str = coord.split(',')
        const latLng = {
          lat: parseFloat(str[0]),
          lng: parseFloat(str[1])
        }
        coordList.push(latLng)
      })
      const fanceData = {
        fenceType: modalData.value.fenceType,
        data: {
          coordinates: coordList,
          radius: modalData.value.radius
        }
      }
      onDrawModalCallback(fanceData)
    }

    function onModalCancel () {
      showModal.value = false
    }

    function onModalSubmit () {
      if (tools.isEmpty(fenceCoordsData.value)) {
        return
      }
      let data = {
        alias: targetType.value === 2 ? targetName.value : t(configData.targetType[targetType.value]),
        isEnterWarning: enterAlarm.value,
        isExitWarning: exitAlarm.value,
        remark: noteContent.value
      }
      const mapData = {
        fenceType: fenceCoordsData.value.fenceType,
        coordinates: getCoordinates()
      }
      if (fenceCoordsData.value.fenceType === 1) {
        Object.assign(mapData, {
          radius: fenceCoordsData.value.data.radius
        })
      }
      data = { ...data, ...mapData }
      if (isEditor.value) {
        modalData.value = { ...modalData.value, ...data }
      }
      emit('modalCallback', isEditor.value, isEditor.value ? modalData.value.id : selectDeviceId.value, isEditor.value ? modalData.value : data)
      showModal.value = false
    }

    function getCoordinates () {
      const arr = []
      fenceCoordsData.value.data.coordinates.forEach(element => {
        const data = element.lat + ',' + element.lng
        arr.push(data)
      })
      return arr
    }

    function onDrawMapClickEvent () {
      ModalDrawEletronicFenceMap.value.showModalWithData(isEditor.value, isEditor.value ? fenceCoordsData : null)
    }

    function onDrawModalCallback (fenceData) {
      fenceCoordsData.value = Object.assign({}, fenceData)
      inputCoords.value = JSON.stringify(fenceData.data)
    }

    watch(() => selectDeviceId.value, () => {
      // console.log('selectDeviceIdChanged:', selectDeviceId.value)
      checkSelectDeviceExpired()
    })

    return {
      t,
      ModalDrawEletronicFenceMap,
      deviceServiceExpiredHint,
      // disableDrawMapWithExpired,
      accountType,
      modalData,
      inputCoords,
      fenceCoordsData,
      isExpired,
      isEditor,
      noteContent,
      enterAlarm,
      exitAlarm,
      configData,
      targetType,
      targetName,
      selectDeviceId,
      targetOptions,
      showModal,
      modalTitle,
      submitButtonName,
      showModalWithData,
      onModalCancel,
      onModalSubmit,
      onDrawMapClickEvent,
      onDrawModalCallback,
      showModalWithDeviceId
    }
  },
  methods: {
    onCancelClickEvent () {
      this.onModalCancel()
    },
    onSubmitClickEvent () {
      this.onModalSubmit()
    }
  }
}
</script>
