<template>
  <div class="sideMenu">
    <div class="col">
      <div class="col ">
        <div class="title bg-info bg-opacity-30 align-items-center d-flex" @click="onClickMainIndex(0)">
          <label class="fw-bold mx-2">{{ t('ADMIN_STATISTICS.SIDE_MENU[0]') }}</label>
        </div>
        <ul v-if="getShowSubContent(0)" class="list-group list-group-flush">
          <router-link :to="{name: 'client.allPerDay'}" custom v-slot="{navigate, isExactActive}">
            <li class="list-group-item" @click="navigate" :class="isExactActive && activeClass">
              {{ t('ADMIN_STATISTICS.SIDE_SUB_MENU[0]') }}{{ t('ADMIN_STATISTICS.SIDE_MENU[0]') }}
            </li>
          </router-link>
          <router-link :to="{name: 'client.allPerMonth'}" custom v-slot="{navigate, isExactActive}">
            <li class="list-group-item" @click="navigate" :class="isExactActive && activeClass">
              {{ t('ADMIN_STATISTICS.SIDE_SUB_MENU[1]') }}{{ t('ADMIN_STATISTICS.SIDE_MENU[0]') }}
            </li>
          </router-link>
          <router-link :to="{name: 'client.allPerTimeZone'}" custom v-slot="{navigate, isExactActive}">
            <li class="list-group-item" @click="navigate" :class="isExactActive && activeClass">
              {{ t('ADMIN_STATISTICS.SIDE_SUB_MENU[2]') }}{{ t('ADMIN_STATISTICS.SIDE_MENU[0]') }}
            </li>
          </router-link>
        </ul>
      </div>
      <div class="col mt-1">
        <div class="title bg-info bg-opacity-30 align-items-center d-flex" @click="onClickMainIndex(1)">
          <label class="fw-bold mx-2">{{ t('ADMIN_STATISTICS.SIDE_MENU[1]') }}</label>
        </div>
        <ul v-if="getShowSubContent(1)" class="list-group list-group-flush">
          <router-link :to="{name: 'client.mileagePerDay'}" custom v-slot="{navigate, isExactActive}">
            <li class="list-group-item" @click="navigate" :class="isExactActive && activeClass">
              {{ t('ADMIN_STATISTICS.SIDE_SUB_MENU[0]') }}{{ t('ADMIN_STATISTICS.SIDE_MENU[1]') }}
            </li>
          </router-link>
          <router-link :to="{name: 'client.mileagePerMonth'}" custom v-slot="{navigate, isExactActive}">
            <li class="list-group-item" @click="navigate" :class="isExactActive && activeClass">
              {{ t('ADMIN_STATISTICS.SIDE_SUB_MENU[1]') }}{{ t('ADMIN_STATISTICS.SIDE_MENU[1]') }}
            </li>
          </router-link>
          <router-link :to="{name: 'client.mileagePerTimeZone'}" custom v-slot="{navigate, isExactActive}">
            <li class="list-group-item" @click="navigate" :class="isExactActive && activeClass">
              {{ t('ADMIN_STATISTICS.SIDE_SUB_MENU[2]') }}{{ t('ADMIN_STATISTICS.SIDE_MENU[1]') }}
            </li>
          </router-link>
        </ul>
      </div>
      <div class="col mt-1">
        <div class="title bg-info bg-opacity-30 align-items-center d-flex" @click="onClickMainIndex(2)">
          <label class="fw-bold mx-2">{{ t('ADMIN_STATISTICS.SIDE_MENU[2]') }}</label>
        </div>
        <ul v-if="getShowSubContent(2)" class="list-group list-group-flush">
          <router-link :to="{name: 'client.warningPerDay'}" custom v-slot="{navigate, isExactActive}">
            <li class="list-group-item" @click="navigate" :class="isExactActive && activeClass">
              {{ t('ADMIN_STATISTICS.SIDE_SUB_MENU[0]') }}{{ t('ADMIN_STATISTICS.SIDE_MENU[2]') }}
            </li>
          </router-link>
          <router-link :to="{name: 'client.warningPerMonth'}" custom v-slot="{navigate, isExactActive}">
            <li class="list-group-item" @click="navigate" :class="isExactActive && activeClass">
              {{ t('ADMIN_STATISTICS.SIDE_SUB_MENU[1]') }}{{ t('ADMIN_STATISTICS.SIDE_MENU[2]') }}
            </li>
          </router-link>
          <router-link :to="{name: 'client.warningPerTimeZone'}" custom v-slot="{navigate, isExactActive}">
            <li class="list-group-item" @click="navigate" :class="isExactActive && activeClass">
              {{ t('ADMIN_STATISTICS.SIDE_SUB_MENU[2]') }}{{ t('ADMIN_STATISTICS.SIDE_MENU[2]') }}
            </li>
          </router-link>
        </ul>
      </div>
      <div class="col mt-1" v-if="enableOverSpeed">
        <div class="title bg-info bg-opacity-30 align-items-center d-flex" @click="onClickMainIndex(3)">
          <label class="fw-bold mx-2">{{ t('ADMIN_STATISTICS.SIDE_MENU[3]') }}</label>
        </div>
        <ul v-if="getShowSubContent(3)" class="list-group list-group-flush">
          <router-link :to="{name: 'client.overspeedPerDay'}" custom v-slot="{navigate, isExactActive}">
            <li class="list-group-item" @click="navigate" :class="isExactActive && activeClass">
              {{ t('ADMIN_STATISTICS.SIDE_SUB_MENU[0]') }}{{ t('ADMIN_STATISTICS.SIDE_MENU[3]') }}
            </li>
          </router-link>
          <router-link :to="{name: 'client.overspeedPerMonth'}" custom v-slot="{navigate, isExactActive}">
            <li class="list-group-item" @click="navigate" :class="isExactActive && activeClass">
              {{ t('ADMIN_STATISTICS.SIDE_SUB_MENU[1]') }}{{ t('ADMIN_STATISTICS.SIDE_MENU[3]') }}
            </li>
          </router-link>
          <router-link :to="{name: 'client.overspeedPerTimeZone'}" custom v-slot="{navigate, isExactActive}">
            <li class="list-group-item" @click="navigate" :class="isExactActive && activeClass">
              {{ t('ADMIN_STATISTICS.SIDE_SUB_MENU[2]') }}{{ t('ADMIN_STATISTICS.SIDE_MENU[3]') }}
            </li>
          </router-link>
        </ul>
      </div>
      <div class="col mt-1" v-if="enableOffline">
        <div class="title bg-info bg-opacity-30 align-items-center d-flex" @click="onClickMainIndex(4)">
          <label class="fw-bold mx-2">{{ t('ADMIN_STATISTICS.SIDE_MENU[4]') }}</label>
        </div>
        <ul v-if="getShowSubContent(4)" class="list-group list-group-flush">
          <router-link :to="{name: 'client.offlinePerDay'}" custom v-slot="{navigate, isExactActive}">
            <li class="list-group-item" @click="navigate" :class="isExactActive && activeClass">
              {{ t('ADMIN_STATISTICS.SIDE_SUB_MENU[0]') }}{{ t('ADMIN_STATISTICS.SIDE_MENU[4]') }}
            </li>
          </router-link>
          <router-link :to="{name: 'client.offlinePerMonth'}" custom v-slot="{navigate, isExactActive}">
            <li class="list-group-item" @click="navigate" :class="isExactActive && activeClass">
              {{ t('ADMIN_STATISTICS.SIDE_SUB_MENU[1]') }}{{ t('ADMIN_STATISTICS.SIDE_MENU[4]') }}
            </li>
          </router-link>
          <router-link :to="{name: 'client.offlinePerTimeZone'}" custom v-slot="{navigate, isExactActive}">
            <li class="list-group-item" @click="navigate" :class="isExactActive && activeClass">
              {{ t('ADMIN_STATISTICS.SIDE_SUB_MENU[2]') }}{{ t('ADMIN_STATISTICS.SIDE_MENU[4]') }}
            </li>
          </router-link>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'
import { computed } from '@vue/runtime-core'
import { useRoute } from 'vue-router'
export default {
  setup () {
    const { t } = useI18n()

    const store = useStore()
    const route = useRoute()

    const enableOverSpeed = computed(() => store.getters['setting/getClientShowOverSpeed'])
    const enableOffline = computed(() => store.getters['setting/getClientShowOffline'])

    const isActive = computed((name) => { return route.name === name })

    const selectMainIndex = computed(() => store.getters['clientStatistics/getSelectMainIndex'])

    const onClickMainIndex = (index) => {
      store.commit('clientStatistics/setSelectMainIndex', index)
    }
    const getShowSubContent = (index) => {
      return selectMainIndex.value === index
    }

    return {
      t,
      isActive,
      selectMainIndex,
      enableOverSpeed,
      enableOffline,
      onClickMainIndex,
      getShowSubContent,
      activeClass: 'text-danger'
    }
  }
}
</script>

<style lang="stylus" scoped>
.title {
  cursor: pointer
  height: 2rem
  & label {
    cursor: pointer
  }
}
.list-group-item {
  cursor: pointer
  font-size: 1rem
}
</style>
