<template>
  <div v-if="showModal" class="modal-overlay">
    <div class="modal-dialog">
      <div class="modal-content">
        <h4 class="modal-title">{{ t('GENERAL.FEEBACK') }}</h4>
        <hr/>
        <form class="row g-3 p-3">
          <div class="form-group row mt-2 align-items-center field">
            <label class="col-sm-2 mx-3">{{ t('ADMIN_REPORT.REPORT_TABLES[4]') }}</label>
            <div class="col-sm-9">
              <select class="form-select" v-model.number="reportType">
                <option v-for="(item, key) in configData.feebackType" :key="key" :value="key">{{ t(item) }}</option>
              </select>
            </div>
          </div>
          <div class="form-group row mt-2 align-items-center field">
            <label class="col-sm-2 mx-3">{{ t('ADMIN_REPORT.REPORT_TABLES[2]') }}</label>
            <div class="col-sm-9">
              <input name="count" type="text" class="form-control" v-model="contactName">
            </div>
          </div>
          <div class="form-group row mt-2 align-items-center field">
            <label class="col-sm-2 mx-3">{{ t('ADMIN_REPORT.REPORT_TABLES[3]') }}</label>
            <div class="col-sm-9">
              <input name="count" :placeholder="t('ADMIN_REPORT.REPORT_METHOD_PLACEHOLDER')" type="text" class="form-control" v-model="contactMethod">
            </div>
          </div>
          <div class="form-group row mt-2 align-items-center field">
            <label class="col-sm-2 mx-3">{{ t('ADMIN_REPORT.MODAL_TITLE') }}</label>
            <div class="col-sm-9">
              <textarea class="form-control inputTextArea" id="descript" rows="5" maxlength="280" v-model="description"/>
            </div>
          </div>
        </form>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" @click="onCancelClickEvent">{{ t('GENERAL.CLOSE') }}</button>
          <button type="button" class="btn btn-primary" @click="onSubmitClickEvent">{{ t('GENERAL.SAVE') }}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from '@vue/reactivity'
import { useI18n } from 'vue-i18n'
import { inject } from '@vue/runtime-core'
export default {
  emits: ['modalCallback'],
  setup (props, { emit }) {
    const { t } = useI18n()
    const configData = inject('configData')

    const showModal = ref(false)

    const reportType = ref(1)
    const contactName = ref('')
    const contactMethod = ref('')
    const description = ref('')

    function openModal () {
      reportType.value = 1
      contactName.value = ''
      contactMethod.value = ''
      description.value = ''
      showModal.value = true
    }

    function onModalCancel () {
      showModal.value = false
    }

    function onModalSubmit () {
      const data = {
        reportTypeId: reportType.value,
        contact: contactName.value,
        contactMethod: contactMethod.value,
        description: description.value
      }
      emit('modalCallback', data)
      showModal.value = false
    }

    return {
      t,
      configData,
      showModal,
      reportType,
      contactName,
      contactMethod,
      description,
      openModal,
      onModalCancel,
      onModalSubmit
    }
  },
  methods: {
    onCancelClickEvent () {
      this.onModalCancel()
    },
    onSubmitClickEvent () {
      this.onModalSubmit()
    }
  }
}
</script>
