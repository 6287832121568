<template>
  <div class="sideMenu">
    <ul class="list-group list-group-flush">
      <router-link :to="{name: 'service.plan'}" custom v-slot="{navigate, isExactActive}">
        <li class="list-group-item fw-bold" @click="navigate" :class="isExactActive && activeClass">
          {{ t('SIDE_MENU.VIP_PLAN[0]') }}
        </li>
      </router-link>
      <router-link :to="{name: 'service.vipplan'}" custom v-slot="{navigate, isExactActive}">
        <li class="list-group-item fw-bold" @click="navigate" :class="isExactActive && activeClass">
          {{ t('SIDE_MENU.VIP_PLAN[1]') }}
        </li>
      </router-link>
      <router-link :to="{name: 'service.coupon'}" custom v-slot="{navigate, isExactActive}">
        <li class="list-group-item fw-bold" @click="navigate" :class="isExactActive && activeClass">
          {{ t('SIDE_MENU.VIP_PLAN[2]') }}
        </li>
      </router-link>
    </ul>
  </div>
</template>

<script>
import { useI18n } from 'vue-i18n'
export default {
  setup () {
    const { t } = useI18n()

    return {
      t,
      activeClass: 'text-danger'
    }
  }
}
</script>

<style lang="stylus" scoped>
.list-group-item {
  cursor: pointer
}
.list-group-item:hover{

}
</style>
