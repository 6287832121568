import { request } from '../../../api/axios'

const state = {
  addDays: 30,
  pageIndex: 0,
  pageRows: 20,
  totalCount: 0,
  serviceExpiredDeviceList: [],
  servicePlanList: []
}

const getters = {
  pageIndex (state) {
    return state.pageIndex
  },
  pageRows (state) {
    return state.pageRows
  },
  totalCount (state) {
    return state.totalCount
  },
  serviceExpiredDeviceList (state) {
    return state.serviceExpiredDeviceList
  },
  servicePlanList (state) {
    return state.servicePlanList
  }

}

const mutations = {
  setPageIndex (state, index) {
    state.pageIndex = index
  },
  setExpiredDeviceData (state, data) {
    state.serviceExpiredDeviceList.length = 0
    state.totalCount = data.total
    data.rows.forEach(element => {
      state.serviceExpiredDeviceList.push(element)
    })
  },
  setServicePlanData (state, data) {
    state.servicePlanList.length = 0
    data.rows.forEach(element => {
      state.servicePlanList.push(element)
    })
  },
  clearPlanList (state) {
    state.servicePlanList.length = 0
  }
}

const actions = {
  async getServiceExpiredDevice ({ state, rootState }) {
    const date = new Date()
    date.setDate(date.getDate() + state.addDays + 1)
    date.setHours(0, 0, 0, 0)
    let params = {
      userToken: rootState.userToken,
      pageIndex: state.pageIndex,
      pageRows: state.pageRows,
      filterServiceExpiredDate: date.getTime() * 1000
    }
    if (rootState.userData.accountType) {
      switch (rootState.userData.accountType) {
        case 2:
          params = { ...params, ...{ fleetId: rootState.userData.accountId } }
          break
        case 3:
          params = { ...params, ...{ deviceSerialNumber: rootState.userData.account } }
          break
        default:
          return
      }
    }
    return request('/api/getDevices', JSON.stringify(params))
  },
  async getServicePlan ({ state, rootState }, planId) {
    const params = {
      userToken: rootState.userToken,
      pageIndex: 0,
      pageRows: 0,
      planId
    }
    return request('/api/getPlanItems', JSON.stringify(params))
  },
  async checkCouponNumber ({ rootState }, couponNumber) {
    const params = {
      userToken: rootState.userToken,
      pageIndex: 0,
      pageRows: 1,
      couponNumber
    }
    return request('/api/getCoupons', JSON.stringify(params))
  },
  async addPlanOrder ({ rootState }, orderData) {
    let params = {
      userToken: rootState.userToken
    }
    params = { ...params, ...orderData }
    return request('/api/addPlanOrder', JSON.stringify(params))
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
