import { request } from '../../api/axios'

const state = {
  historyList: [],
  nextReloadTime: 0,
  nowTime: 0
}

const getters = {
  getHistoryList (state) {
    return state.historyList
  },
  getNextReloadTime (state) {
    const time = state.nextReloadTime - state.nowTime
    return time < 0 ? 0 : time
  }
}

const mutations = {
  setNextReloadTime (state) {
    state.nowTime = new Date().getTime()
    state.nextReloadTime = state.nowTime + 60 * 1000
  },
  countingTimer (state) {
    state.nowTime = new Date().getTime()
  },
  addCommandLogData (state, response) {
    response.logs.forEach(log => {
      console.log(log)
      const data = {
        id: log.id,
        requestDataCode: log.requestDataCode,
        remoteType: 3,
        deviceSerialNumber: log.deviceSerialNumber,
        timestamp: log.modifyDate,
        responseData: log.responseJson !== '' ? JSON.parse(log.responseJson) : {},
        requestData: log.requestJson !== '' ? JSON.parse(log.requestJson) : {}
      }
      const index = state.historyList.findIndex(history => history.id === data.id)
      if (index === -1) {
        state.historyList.splice(0, 0, data)
      }
    })
  },
  addHistoryCommand (state, response) {
    const data = Object.assign({}, response)
    data.id = -1
    data.timestamp = new Date().getTime() * 1000
    state.historyList.splice(0, 0, data)
  },
  clearHistoryCommand (state) {
    state.historyList.length = 0
  }
}

const actions = {
  async sendRemoteStatus ({ rootState }, queryData) {
    let params = {
      userToken: rootState.userToken
    }
    params = { ...params, ...queryData }
    return request('/api/remoteCommand', JSON.stringify(params))
  },
  async getRemoteHistory ({ rootState }, data) {
    let params = {
      userToken: rootState.userToken,
      startTime: new Date(new Date().setHours(0, 0, 0, 0)).getTime() * 1000,
      endTime: new Date().getTime() * 1000,
      pageIndex: 0,
      pageRow: 100
    }
    params = { ...params, ...data }
    return request('/api/getDeviceSendCommandLog', JSON.stringify(params))
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
