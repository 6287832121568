<template>
  <div class="rightClickMenu" v-if="showMenu" @click="onHideRightMenuClick" @contextmenu="onHideRightClickEvent">
    <ul class="dropdown-menu dropdown-menu-sm fleetMenuContent" :style="{ top: posTop + 'px', left: posLeft + 'px' }" id="context-menu">
      <!-- <li><a class="dropdown-item" @click="onMainMenuClickEvent(0, 0)" @mouseover="onMouseOverEvent(-1)">{{ t('CONTEXT_MENU.DEVICE[0]') }}</a></li> -->
      <!-- <li><a class="dropdown-item" @click="onMainMenuClickEvent(1, 0)" @mouseover="onMouseOverEvent(-1)" href="#">{{ t('CONTEXT_MENU.DEVICE[1]') }}</a></li> -->
      <li><a class="dropdown-item" @click="onMainMenuClickEvent(2, 0)" @mouseover="onMouseOverEvent(-1)" href="#">{{ t('CONTEXT_MENU.DEVICE[2]') }}</a></li>
      <!-- <li><a class="dropdown-item" @click="onMainMenuClickEvent(3, 0)" @mouseover="onMouseOverEvent(-1)">{{ t('CONTEXT_MENU.DEVICE[3]') }}</a></li> -->
    </ul>
  </div>
</template>

<script>
import { computed, onMounted, ref } from '@vue/runtime-core'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'
export default {
  props: ['posX', 'posY'],
  emits: ['rightMenuCallback'],
  setup (props, { emit }) {
    const { t } = useI18n()
    const store = useStore()
    const posTop = computed(() => props.posY)
    const posLeft = computed(() => props.posX)

    const enableOverSpeed = computed(() => store.getters['setting/getClientShowOverSpeed'])
    const enableOffline = computed(() => store.getters['setting/getClientShowOffline'])

    const showSubMenu = ref([
      false, false, false, false
    ])

    const showRemoteSubMenu = ref(false)

    const selectDevice = ref({})

    const accountFleetList = computed(() => store.getters['clientAccount/accountMenuFleetList'])

    const showMenu = ref(false)

    function showRightClickMenu (itemData) {
      selectDevice.value = Object.assign({}, itemData)
      onMouseOverEvent(-1)
      onMouseOverSubRemoteEvent(false)
      showMenu.value = true
    }

    function onHideRightMenuClick () {
      showMenu.value = false
    }

    function onHideRightClickEvent (event) {
      showMenu.value = false
      event.preventDefault()
    }

    function onMouseOverEvent (index) {
      for (let i = 0; i < showSubMenu.value.length; i++) {
        showSubMenu.value[i] = i === index
      }
    }

    function onMouseLeaveEvent (index) {
      showSubMenu.value[index] = false
    }

    function onMouseOverSubRemoteEvent (isShow) {
      showRemoteSubMenu.value = isShow
    }

    function onMouseLeaveSubRemoteEvent (isShow) {
      showRemoteSubMenu.value = isShow
    }

    function onMainMenuClickEvent (mainIndex, subIndex) {
      emit('rightMenuCallback', mainIndex, subIndex, selectDevice.value)
    }

    onMounted(() => {

    })

    return {
      t,
      enableOverSpeed,
      enableOffline,
      showMenu,
      showSubMenu,
      showRemoteSubMenu,
      posTop,
      posLeft,
      selectDevice,
      accountFleetList,
      showRightClickMenu,
      onHideRightMenuClick,
      onHideRightClickEvent,
      onMouseOverEvent,
      onMouseLeaveEvent,
      onMouseOverSubRemoteEvent,
      onMouseLeaveSubRemoteEvent,
      onMainMenuClickEvent
    }
  }
}

</script>

<style lang="stylus" scoped>
.rightClickMenu {
  .fleetMenuContent {
    display: block
    font-size: 0.7rem
  }
}
.dropdown-submenu {
  position: relative;
}

.dropdown-submenu a::after {
  transform: rotate(-90deg);
  position: absolute;
  right: 6px;
  top: .8em;
}

.dropdown-submenu .dropdown-menu {
  top: 0;
  left: 100%;
  margin-left: .1rem;
  margin-right: .1rem;
}
.dropdown-item:active {
  background #F00
}

</style>
