import md5 from 'js-md5'
export default {
  install: (app, options) => {
    function isEmpty (obj) {
      if (obj == null) return true
      // Assume if it has a length property with a non-zero value
      // that that property is correct.
      if (obj.length > 0) return false
      if (obj.length === 0) return true

      // If it isn't an object at this point
      // it is empty, but it can't be anything *but* empty
      // Is it empty?  Depends on your application.
      if (typeof obj !== 'object') return true

      // Otherwise, does it have any properties of its own?
      // Note that this doesn't handle
      // toString and valueOf enumeration bugs in IE < 9
      for (const key in obj) {
        if (hasOwnProperty.call(obj, key)) return false
      }
      return true
    }

    function objectDiff (obj1, obj2) {
      // Make sure an object to compare is provided
      if (!obj2 || Object.prototype.toString.call(obj2) !== '[object Object]') {
        return obj1
      }

      // Variables
      const diffs = {}
      let key

      // Methods
      /**
       * Check if two arrays are equal
       * @param  {Array}   arr1 The first array
       * @param  {Array}   arr2 The second array
       * @return {Boolean}      If true, both arrays are equal
       */
      const arraysMatch = function (arr1, arr2) {
        // Check if the arrays are the same length
        if (arr1.length !== arr2.length) return false

        // Check if all items exist and are in the same order
        for (let i = 0; i < arr1.length; i++) {
          if (arr1[i] !== arr2[i]) return false
        }

        // Otherwise, return true
        return true
      }

      /**
       * Compare two items and push non-matches to object
       * @param  {*}      item1 The first item
       * @param  {*}      item2 The second item
       * @param  {String} key   The key in our object
       */
      const compare = function (item1, item2, key) {
        // Get the object type
        const type1 = Object.prototype.toString.call(item1)
        const type2 = Object.prototype.toString.call(item2)

        // If type2 is undefined it has been removed
        if (type2 === '[object Undefined]') {
          diffs[key] = null
          return
        }

        // If items are different types
        if (type1 !== type2) {
          diffs[key] = item2
          return
        }

        // If an object, compare recursively
        if (type1 === '[object Object]') {
          if (item1 !== item2) {
            diffs[key] = item2
          }
          return
        }

        // If an array, compare
        if (type1 === '[object Array]') {
          if (!arraysMatch(item1, item2)) {
            diffs[key] = item2
          }
          return
        }

        // Else if it's a function, convert to a string and compare
        // Otherwise, just compare
        if (type1 === '[object Function]') {
          if (item1.toString() !== item2.toString()) {
            diffs[key] = item2
          }
        } else {
          if (item1 !== item2) {
            diffs[key] = item2
          }
        }
      }
      // Loop through the first object
      for (key in obj1) {
        if (Object.prototype.hasOwnProperty.call(obj1, key)) {
          compare(obj1[key], obj2[key], key)
        }
      }

      // Loop through the second object and find missing items
      for (key in obj2) {
        if (Object.prototype.hasOwnProperty.call(obj2, key)) {
          if (!obj1[key] && obj1[key] !== obj2[key]) {
            diffs[key] = obj2[key]
          }
        }
      }

      // Return the object of differences
      return diffs
    }

    function getDeviceGPSDate (unixTime, defaultStr) {
      if (unixTime === null) {
        return defaultStr
      }
      const date = new Date(unixTime)
      return date.toLocaleString()
    }

    function checkExpiredDate (expiredDay) {
      const crossValue = 16 - expiredDay.toString().length
      expiredDay = parseInt(expiredDay)
      for (let i = 0; i < crossValue; i++) {
        expiredDay = expiredDay * 10
      }
      return (expiredDay / 1000) > (new Date()).getTime()
    }

    function checkOvertimeDays (expiredDay, overtimeDays) {
      const nowTimes = new Date()
      if ((expiredDay / 1000) < nowTimes.getTime() && overtimeDays > 0) {
        const betweenTime = nowTimes.getTime() - (expiredDay / 1000)
        return (betweenTime / (1000 * 3600 * 24)) > overtimeDays
      }
      return false
    }

    function getDateTime (unixTime, defaultStr, isShortData = false) {
      if (unixTime === null || unixTime === undefined) {
        return defaultStr
      }
      const crossValue = 16 - unixTime.toString().length
      let covertUnixTime = parseInt(unixTime)
      for (let i = 0; i < crossValue; i++) {
        covertUnixTime = unixTime * 10
      }
      if (covertUnixTime > new Date().getTime() * 1000) {
        covertUnixTime = unixTime
      }
      covertUnixTime = covertUnixTime / 1000
      const date = new Date(covertUnixTime)
      const options = {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hourCycle: 'h23',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit'
      }
      return isShortData ? (date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate()) : date.toLocaleString([], options)
    }

    function getDate (unixTime, defaultStr, isGPSDevice = false) {
      if (unixTime === null || unixTime === undefined) {
        return defaultStr
      }
      const crossValue = 16 - unixTime.toString().length
      for (let i = 0; i < crossValue; i++) {
        unixTime = unixTime * 10
      }
      unixTime = unixTime / 1000
      const date = new Date(unixTime)
      return date.toLocaleString()
    }

    function getShortDate (unixTime, defaultStr, isGPSDevice = false) {
      if (unixTime === null || unixTime === undefined) {
        return defaultStr
      }
      unixTime = parseInt(unixTime)

      if (isGPSDevice) {
        unixTime = unixTime * 1000
      } else {
        unixTime = parseInt(unixTime / 1000)
      }
      const date = new Date(unixTime)
      return date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate()
    }

    function getObjectFromToRefs (toRefs) {
      const data = {}
      for (const [key, value] of Object.entries(toRefs)) {
        data[key] = value.value
      }
      return data
    }

    function stringFormat (formatted, ...args) {
      for (let i = 0; i < args.length; i++) {
        const regexp = new RegExp('\\{' + i + '\\}', 'gi')
        formatted = formatted.replace(regexp, args[i])
      }
      return formatted
    }

    function CovertUpdateDeviceData (deviceData) {
      const updateData = {}
      if (deviceData.carName) {
        updateData.carName = deviceData.carName
      }
      if (deviceData.deviceOwnerName) {
        updateData.ownerName = deviceData.deviceOwnerName
      }
      if (deviceData.phoneNumber) {
        updateData.ownerPhone = deviceData.phoneNumber
      }
      if (deviceData.password) {
        updateData.password = md5(deviceData.password)
      }
      if (deviceData.deviceIcon) {
        updateData.deviceIcon = deviceData.deviceIcon
      }
      if (deviceData.email || deviceData.email === '') {
        updateData.email = deviceData.email
      }
      return updateData
    }

    function CheckDeviceExpired (deviceData) {
      if (deviceData) {
        const nowTimeStamp = new Date().getTime() * 1000
        return nowTimeStamp > deviceData.serviceExpiredDate
      } else {
        return true
      }
    }

    function HyperLinkGoogleMapAddress (latitude, longitude) {
      const url = 'https://www.google.com/maps/search/?api=1&query=' + latitude + '%2C' + longitude
      window.open(url, '_blank')
    }

    function HyperLinkGoogleMapNavigate (latitude, longitude) {
      const url = 'https://www.google.com/maps/dir/?api=1&destination=' + latitude + '%2C' + longitude + '&travelmode=driving&dir_action=navigate'
      window.open(url, '_blank')
    }

    app.provide('tools', {
      isEmpty,
      objectDiff,
      getDate,
      getDateTime,
      getShortDate,
      getObjectFromToRefs,
      getDeviceGPSDate,
      stringFormat,
      CovertUpdateDeviceData,
      checkExpiredDate,
      checkOvertimeDays,
      CheckDeviceExpired,
      HyperLinkGoogleMapAddress,
      HyperLinkGoogleMapNavigate
    })
  }
}
