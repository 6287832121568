export const defaultData = {
  userData: {
    id: -1,
    alias: '',
    account: '',
    permissionId: 0,
    password: '',
    statusCode: 1
  },
  adminTabMenu: [
    {
      id: 1,
      title: 'TOP_TABS.ACCOUNT',
      name: 'account'
    },
    {
      id: 2,
      title: 'TOP_TABS.PERMISSION',
      name: 'permission'
    },
    {
      id: 3,
      title: 'TOP_TABS.DEVICE',
      name: 'device'
    },
    {
      id: 4,
      title: 'TOP_TABS.FLEET',
      name: 'fleet'
    },
    {
      id: 5,
      title: 'TOP_TABS.CUSTOMER',
      name: 'customer'
    },
    {
      id: 6,
      title: 'TOP_TABS.PLAN',
      name: 'plan'
    },
    {
      id: 8,
      title: 'TOP_TABS.STATISTICS',
      name: 'admin.statistics'
    },
    {
      id: 9,
      title: 'TOP_TABS.REPORT',
      name: 'report'
    },
    {
      id: 10,
      title: 'TOP_TABS.FINANCE',
      name: 'finance'
    },
    {
      id: 11,
      title: 'TOP_TABS.ANNOUNCEMENT',
      name: 'announcement'
    },
    {
      id: 7,
      title: 'TOP_TABS.PLAN_SETTING',
      name: 'admin.vipPlan'
    },
    // {
    //   id: 12,
    //   title: 'TOP_TABS.SYSTEM_LOG',
    //   name: 'systemLog'
    // },
    {
      id: 13,
      title: 'TOP_TABS.SETTING',
      name: 'setting'
    }
  ],
  permissionData: {
    id: -1,
    name: '',
    sort: 0,
    items: {
      1: '1111',
      2: '1111',
      3: '1111',
      4: '1111',
      5: '0101',
      6: '0101',
      7: '1111',
      8: '0001',
      9: '0101',
      10: '0001',
      11: '1101',
      12: '0001',
      13: '0101'
    }
  },
  PermissionItems: {
    1: '1111',
    2: '1111',
    3: '1111',
    4: '1111',
    5: '0101',
    6: '0101',
    7: '1111',
    8: '0001',
    9: '0101',
    10: '0001',
    11: '1101',
    12: '0001',
    13: '0101'
  },
  permissionStatus: {
    1: [1, 1, 1, 1],
    2: [1, 1, 1, 1],
    3: [1, 1, 1, 1],
    4: [1, 1, 1, 1],
    5: [-1, 1, -1, 1],
    6: [-1, 1, -1, 1],
    7: [1, 1, 1, 1],
    8: [-1, -1, -1, 1],
    9: [-1, 1, -1, 1],
    10: [-1, -1, -1, 1],
    11: [1, 1, -1, 1],
    12: [-1, -1, -1, 1],
    13: [-1, 1, -1, 1]
  },
  deviceData: {
    deviceId: 0,
    deviceSerialNumber: '',
    deviceOwnerName: '',
    carName: '',
    simNumber: '',
    phoneNumber: '',
    carElectricity: 0,
    firmwareVersion: '',
    esimId: '',
    isValueAddedVisible: 0,
    serviceStatusCode: 1,
    fleetAlias: '',
    planName: '',
    serviceExpiredDate: 0,
    valueExpiredDate: 0,
    createDate: 0,
    deviceStatusCode: 0
  },
  fleetData: {
    account: '',
    password: '',
    alias: '',
    contact: '',
    phoneNumber: '',
    address: '',
    email: '',
    permissions: '111'
  },
  fleetPermission: [1, 1, 1],
  fleetSubData: {
    id: 0,
    account: '',
    password: '',
    alias: '',
    permissions: '101'
  },
  servicePlanData: {
    id: 0,
    planId: 1,
    name: '',
    price: 0,
    extendDays: 0,
    activeStatus: 1,
    overtimeDays: 0,
    overtimePrice: 0,
    description: ''
  },
  vipPlanData: {
    name: '',
    price: 0,
    extendDays: 0,
    statusCode: 1,
    flag: 2,
    description: '',
    invoiceName: ''
  },
  couponData: {
    numberOfCoupons: 0,
    discountFee: 0
  },
  announcementData: {
    activeDate: 0,
    content: ''
  },
  settingData: {
    id: 0,
    value: 0
  },
  electricFenceData: {

  },
  markerPointData: {

  },
  parkingOverTime: 1800,
  timePicker: ['00:00', '01:00', '02:00', '03:00', '04:00', '05:00', '06:00', '07:00', '08:00', '09:00', '10:00', '11:00', '12:00', '13:00', '14:00', '15:00', '16:00', '17:00', '18:00', '19:00', '20:00', '21:00', '22:00', '23:00']
}
