<template>
  <div class="col align-items-center d-flex item">
    <input type="checkbox" class="form-check-input" v-model="isSelected"
      @change="onUpdateItemSelectEvent(isSelected)">
    <img :src="carIcon" alt="offline" class="carImg mx-1">
    <label class="col flex-grow-1 from-check-label mx-2 text-start" @contextmenu="onRightClickEvent">{{
    itemData.deviceSerialNumber }} ({{ t('MONITOR.DEVICE_STATUS_TYPE[' + (itemData.deviceStatusCode ?
    itemData.deviceStatusCode - 1 : 0) + ']')
    }})</label>
    <div class="delete" @click="onDeleteItemClickEvent">
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-square-fill"
        viewBox="0 0 16 16">
        <path
          d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2zm3.354 4.646L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 1 1 .708-.708z" />
      </svg>
    </div>
  </div>
</template>

<script>
import { computed } from '@vue/runtime-core'
import { useI18n } from 'vue-i18n'
export default {
  props: ['item'],
  emits: ['onSelectItemCallback', 'onDeleteItemCallback', 'onMenuCallback'],
  setup (props, { emit }) {
    const { t } = useI18n()
    const itemData = computed(() => props.item)

    const isSelected = computed(() => props.item.isSelect)

    const icons = {
      carOff: require('../../assets/images/caroff.gif'),
      carOn: require('../../assets/images/caron.gif'),
      delete: require('../../assets/icon/x-square-fill.svg')
    }

    const carIcon = computed(() => {
      switch (props.item.deviceStatusCode) {
        case 2: // Parking
          return icons.carOn
        case 3: // Moving
          return icons.carOn
        default:
          return icons.carOff
      }
    })

    function onDeleteItemClickEvent () {
      emit('onDeleteItemCallback', itemData.value)
    }

    function onUpdateItemSelectEvent (isSelect) {
      emit('onSelectItemCallback', isSelect, itemData.value)
    }

    function onRightClickEvent (event) {
      emit('onMenuCallback', event, itemData.value)
      event.preventDefault()
    }

    return {
      t,
      icons,
      carIcon,
      itemData,
      isSelected,
      onDeleteItemClickEvent,
      onUpdateItemSelectEvent,
      onRightClickEvent
    }
  }
}
</script>

<style lang="stylus" scoped>
.carImg {
  width: 20px
  height: 20px
}
& svg {
  color: red
}
</style>
