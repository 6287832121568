import { request } from '../../../api/axios'

const state = {
  servicePageIndex: 0,
  servicePageRows: 50,
  serviceTotals: 0,
  servicePlanList: [],
  vipPlanPageIndex: 0,
  vipPlanPageRows: 50,
  vipPlanTotals: 0,
  vipPlanList: [],
  couponPageIndex: 0,
  couponPageRows: 50,
  couponTotals: 0,
  couponList: []
}

const getters = {
  servicePageIndex (state) {
    return state.servicePageIndex
  },
  vipPlanPageIndex (state) {
    return state.vipPlanPageIndex
  },
  couponPageIndex (state) {
    return state.couponPageIndex
  },
  serviceTotalCount (state) {
    return state.serviceTotals
  },
  vipPlanTotalCount (state) {
    return state.vipPlanTotals
  },
  couponTotalCount (state) {
    return state.couponTotals
  },
  servicePageRows (state) {
    return state.servicePageRows
  },
  vipPlanPageRows (state) {
    return state.vipPlanPageRows
  },
  couponPageRows (state) {
    return state.couponPageRows
  },
  getServicePlanList (state) {
    return state.servicePlanList
  },
  getServicePlanDataById: (state) => (id) => {
    return state.servicePlanList.find(item => item.id === id)
  },
  getVipPlanList (state) {
    return state.vipPlanList
  },
  getVipPlanDataById: (state) => (id) => {
    return state.vipPlanList.find(item => item.id === id)
  },
  getCouponItemList (state) {
    return state.couponList
  },
  getCouponDataById: (state) => (id) => {
    return state.couponList.find(item => item.id === id)
  }
}

const mutations = {
  setServicePageIndex (state, index) {
    state.servicePageIndex = index
  },
  setVipPlanPageIndex (state, index) {
    state.vipPlanPageIndex = index
  },
  setCouponPageIndex (state, index) {
    state.couponPageIndex = index
  },
  setServicePlanList (state, data) {
    state.servicePlanList.length = 0
    state.serviceTotals = data.total
    data.rows.forEach(element => {
      state.servicePlanList.push(element)
    })
  },
  setVipPlanList (state, data) {
    state.vipPlanList.length = 0
    state.vipPlanTotals = data.total
    data.rows.forEach(element => {
      state.vipPlanList.push(element)
    })
  },
  setCouponList (state, data) {
    state.couponList.length = 0
    state.couponTotals = data.total
    data.rows.forEach(element => {
      state.couponList.push(element)
    })
  }
}

const actions = {
  async getServicePlanList ({ state, rootState }) {
    const params = {
      userToken: rootState.userToken,
      pageIndex: state.servicePageIndex,
      pageRows: state.servicePageRows
    }
    return request('/api/getPlanItems', JSON.stringify(params))
  },
  async createServicePlan ({ rootState }, data) {
    let params = {
      userToken: rootState.userToken
    }
    params = { ...params, ...data }
    return request('/api/addPlanItem', JSON.stringify(params))
  },
  async updateServicePlan ({ rootState }, data) {
    let params = {
      userToken: rootState.userToken
    }
    params = { ...params, ...data }
    return request('/api/editPlanItem', JSON.stringify(params))
  },
  async deleteServicePlan ({ rootState }, deleteId) {
    const params = {
      userToken: rootState.userToken,
      id: deleteId
    }
    return request('/api/deletePlanItem', JSON.stringify(params))
  },
  async getVipPlanItemList ({ state, rootState }) {
    const params = {
      userToken: rootState.userToken,
      pageIndex: state.vipPlanPageIndex,
      pageRows: state.vipPlanPageRows
    }
    return request('/api/getVipPlanItems', JSON.stringify(params))
  },
  async createVipPlanItem ({ rootState }, data) {
    let params = {
      userToken: rootState.userToken
    }
    params = { ...params, ...data }
    return request('/api/addVipPlanItem', JSON.stringify(params))
  },
  async updateVipPlanItem ({ rootState }, data) {
    let params = {
      userToken: rootState.userToken
    }
    params = { ...params, ...data }
    return request('/api/editVipPlanItem', JSON.stringify(params))
  },
  async deleteVipPlanItem ({ rootState }, deleteId) {
    const params = {
      userToken: rootState.userToken,
      id: deleteId
    }
    return request('/api/deleteVipPlanItem', JSON.stringify(params))
  },
  async getCouponItemList ({ state, rootState }, searchData) {
    let params = {
      userToken: rootState.userToken,
      pageIndex: state.couponPageIndex,
      pageRows: state.couponPageRows
    }
    params = { ...params, ...searchData }
    return request('/api/getCoupons', JSON.stringify(params))
  },
  async createCouponItem ({ rootState }, data) {
    let params = {
      userToken: rootState.userToken
    }
    params = { ...params, ...data }
    return request('/api/addCoupon', JSON.stringify(params))
  },
  async updateCouponItem ({ rootState }, data) {
    let params = {
      userToken: rootState.userToken
    }
    params = { ...params, ...data }
    return request('/api/editCoupon', JSON.stringify(params))
  },
  async deleteCouponItem ({ rootState }, deleteId) {
    const params = {
      userToken: rootState.userToken,
      id: deleteId
    }
    return request('/api/deleteCoupon', JSON.stringify(params))
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
