import { createRouter, createWebHistory } from 'vue-router'
import AdminParentTemp from '../components/AdminPages/AdminParentTemp.vue'
import serviceParentTemp from '../views/AdminPages/VipPlan.vue'
import announcementParentTemp from '../views/AdminPages/Announcement.vue'
import statisticsParentTemp from '../views/AdminPages/Statistics.vue'
import customerParentTemp from '../views/AdminPages/Customer.vue'

import ClientParentTemp from '../components/ClientPages/ClientParentTemp.vue'
import clientStatisticTemp from '../views/ClientPages/Statistics.vue'

const routes = [
  {
    path: '/compareHistory',
    name: 'compareHistory',
    component: () => import('../views/CompareHistory.vue')
  },
  {
    path: '/',
    name: 'login',
    meta: {
      isBackend: false,
      requiredPermission: true,
      permissionId: 0
    },
    component: () => import('../views/ClientPages/Login.vue')
  },
  {
    path: '/streetView',
    name: 'streetView',
    props: true,
    component: () => import('../components/Common/StreetView.vue')
  },
  {
    path: '/forgetPassword',
    name: 'forgetPassword',
    meta: {
      isBackend: false,
      requiredPermission: false
    },
    component: () => import('../views/ClientPages/ForgetPassword.vue')
  },
  {
    path: '/PrivatePolicy',
    name: 'PrivatePolicy',
    meta: {
      isBackend: false,
      requiredPermission: false
    },
    component: () => import('../views/ClientPages/PrivatePolicy.vue')
  },
  {
    path: '/redirectIOS',
    name: 'RedirectIOS',
    beforeEnter: () => {
      window.location.href = 'https://apps.apple.com/tw/app/gps-king/id1636638871'
    }
  },
  {
    path: '/redirectAndroid',
    name: 'RedirectAndroid',
    beforeEnter: () => {
      // window.location.href = 'https://play.google.com/store/apps/details?id=com.toptrack.toptrackapp'
      window.location.href = 'https://play.google.com/store/apps/details?id=com.toptrack.toptrackapp'
    }
  },
  {
    path: '/main',
    name: 'main',
    component: ClientParentTemp,
    redirect: to => {
      return { name: 'monitor' }
    },
    children: [
      {
        name: 'monitor',
        path: '/monitor',
        component: () => import('../views/ClientPages/Monitor.vue')
      },
      {
        name: 'multiMonitor',
        path: '/multiMonitor',
        component: () => import('../views/ClientPages/MultiMonitor.vue')
      },
      {
        name: 'history',
        path: '/history',
        component: () => import('../views/ClientPages/History.vue')
      },
      {
        name: 'remote',
        path: '/remote',
        component: () => import('../views/ClientPages/Remote.vue')
      },
      {
        name: 'serviceExpire',
        path: '/serviceExpire',
        component: () => import('../views/ClientPages/ServiceExpired.vue')
      },
      {
        name: 'warning',
        path: '/warning',
        component: () => import('../views/ClientPages/Warning.vue')
      },
      {
        name: 'statistics',
        path: '/statistics',
        component: clientStatisticTemp,
        redirect: to => {
          return { name: 'client.allPerDay' }
        },
        children: [
          {
            name: 'client.allPerDay',
            path: 'allPerDay',
            props: { statisticType: 0 },
            component: () => import('../views/ClientPages/Statistic/StatisticPerDay.vue')
          },
          {
            name: 'client.allPerMonth',
            path: 'allPerMonth',
            props: { statisticType: 0 },
            component: () => import('../views/ClientPages/Statistic/StatisticPerMonth.vue')
          },
          {
            name: 'client.allPerTimeZone',
            path: 'allPerTimeZone',
            props: { statisticType: 0 },
            component: () => import('../views/ClientPages/Statistic/StatisticPerTimeZone.vue')
          },
          {
            name: 'client.mileagePerDay',
            path: 'mileagePerDay',
            props: { statisticType: 1 },
            component: () => import('../views/ClientPages/Statistic/StatisticPerDay.vue')
          },
          {
            name: 'client.mileagePerMonth',
            path: 'mileagePerMonth',
            props: { statisticType: 1 },
            component: () => import('../views/ClientPages/Statistic/StatisticPerMonth.vue')
          },
          {
            name: 'client.mileagePerTimeZone',
            path: 'mileagePerTimeZone',
            props: { statisticType: 1 },
            component: () => import('../views/ClientPages/Statistic/StatisticPerTimeZone.vue')
          },
          {
            name: 'client.warningPerDay',
            path: 'warningPerDay',
            props: { statisticType: 2 },
            component: () => import('../views/ClientPages/Statistic/StatisticPerDay.vue')
          },
          {
            name: 'client.warningPerMonth',
            path: 'warningPerMonth',
            props: { statisticType: 2 },
            component: () => import('../views/ClientPages/Statistic/StatisticPerMonth.vue')
          },
          {
            name: 'client.warningPerTimeZone',
            path: 'warningPerTimeZone',
            props: { statisticType: 2 },
            component: () => import('../views/ClientPages/Statistic/StatisticPerTimeZone.vue')
          },
          {
            name: 'client.overspeedPerDay',
            path: 'overspeedPerDay',
            props: { statisticType: 3 },
            component: () => import('../views/ClientPages/Statistic/StatisticPerDay.vue')
          },
          {
            name: 'client.overspeedPerMonth',
            path: 'overspeedPerMonth',
            props: { statisticType: 3 },
            component: () => import('../views/ClientPages/Statistic/StatisticPerMonth.vue')
          },
          {
            name: 'client.overspeedPerTimeZone',
            path: 'overspeedPerTimeZone',
            props: { statisticType: 3 },
            component: () => import('../views/ClientPages/Statistic/StatisticPerTimeZone.vue')
          },
          {
            name: 'client.offlinePerDay',
            path: 'offlinePerDay',
            props: { statisticType: 4 },
            component: () => import('../views/ClientPages/Statistic/StatisticPerDay.vue')
          },
          {
            name: 'client.offlinePerMonth',
            path: 'offlinePerMonth',
            props: { statisticType: 4 },
            component: () => import('../views/ClientPages/Statistic/StatisticPerMonth.vue')
          },
          {
            name: 'client.offlinePerTimeZone',
            path: 'offlinePerTimeZone',
            props: { statisticType: 4 },
            component: () => import('../views/ClientPages/Statistic/StatisticPerTimeZone.vue')
          }
        ]
      },
      {
        name: 'mileage',
        path: '/mileage',
        component: () => import('../views/ClientPages/Mileage.vue')
      },
      {
        name: 'vipPlan',
        path: '/vipPlan',
        component: () => import('../views/ClientPages/VipPlan.vue')
      },
      {
        name: 'addressPlan',
        path: '/addressPlan',
        component: () => import('../views/ClientPages/AddressPlan.vue')
      },
      {
        name: 'record',
        path: '/record',
        component: () => import('../views/ClientPages/Record.vue')
      }
    ]
  },
  {
    path: '/admin/login',
    name: 'admin.login',
    component: () => import('../views/AdminPages/Login.vue')
  },
  {
    path: '/admin/main',
    component: AdminParentTemp,
    children: [
      {
        name: 'account',
        path: '/admin/account',
        meta: {
          isBackend: true,
          requiredPermission: true,
          permissionId: 1
        },
        component: () => import('../views/AdminPages/Account.vue')
      }
    ]
  },
  {
    path: '/admin/main',
    component: AdminParentTemp,
    children: [
      {
        name: 'permission',
        path: '/admin/permission',
        meta: {
          isBackend: true,
          requiredPermission: true,
          permissionId: 2
        },
        component: () => import('../views/AdminPages/Permission.vue')
      }
    ]
  },
  {
    path: '/admin/device',
    component: AdminParentTemp,
    meta: {
      isBackend: true,
      requiredPermission: true,
      permissionId: 3
    },
    children: [
      {
        name: 'device',
        path: '',
        component: () => import('../views/AdminPages/Device.vue')
      }
    ]
  },
  {
    path: '/admin/fleet',
    component: AdminParentTemp,
    children: [
      {
        name: 'fleet',
        path: '',
        meta: {
          isBackend: true,
          requiredPermission: true,
          permissionId: 4
        },
        component: () => import('../views/AdminPages/Fleet.vue')
      },
      {
        name: 'fleet.subFleet',
        path: 'subFleet/:id',
        meta: {
          isBackend: true,
          requiredPermission: true,
          permissionId: 4
        },
        component: () => import('../views/AdminPages/Fleet/SubFleet.vue')
      },
      {
        name: 'fleet.devices',
        path: 'fleetDevices/:id',
        meta: {
          isBackend: true,
          requiredPermission: true,
          permissionId: 4
        },
        component: () => import('../views/AdminPages/Fleet/FleetDevice.vue')
      }
    ]
  },
  {
    path: '/admin/customer',
    component: AdminParentTemp,
    children: [
      {
        name: 'customer',
        path: '',
        component: customerParentTemp,
        meta: {
          isBackend: true,
          requiredPermission: true,
          permissionId: 5
        },
        redirect: to => {
          return { name: 'customer.monitor' }
        },
        children: [
          {
            name: 'customer.monitor',
            path: 'monitor',
            component: () => import('../views/AdminPages/Customer/Monitor.vue')
          },
          {
            name: 'customer.multimonitor',
            path: 'multimonitor',
            component: () => import('../views/AdminPages/Customer/MultiMonitor.vue')
          },
          {
            name: 'customer.history',
            path: 'history',
            component: () => import('../views/AdminPages/Customer/History.vue')
          },
          {
            name: 'customer.remote',
            path: 'remote',
            component: () => import('../views/AdminPages/Customer/Remote.vue')
          },
          {
            name: 'customer.warning',
            path: 'warning',
            component: () => import('../views/AdminPages/Customer/Warning.vue')
          },
          {
            name: 'customer.statistics',
            path: 'statistics',
            component: () => import('../views/AdminPages/Customer/Statistics.vue')
          }
        ]
      }
    ]
  },
  {
    path: '/admin/plan',
    component: AdminParentTemp,
    children: [
      {
        name: 'plan',
        path: '',
        meta: {
          isBackend: true,
          requiredPermission: true,
          permissionId: 6
        },
        component: () => import('../views/AdminPages/Plan.vue')
      }
    ]
  },
  {
    path: '/admin/vipPlan',
    component: AdminParentTemp,
    children: [
      {
        name: 'admin.vipPlan',
        path: '',
        meta: {
          isBackend: true,
          requiredPermission: true,
          permissionId: 7
        },
        component: serviceParentTemp,
        redirect: to => {
          return { name: 'service.plan' }
        },
        children: [
          {
            name: 'service.plan',
            path: 'servicePlan',
            component: () => import('../views/AdminPages/VipPlanPages/ServicePlan.vue')
          },
          {
            name: 'service.vipplan',
            path: 'vipPlan',
            component: () => import('../views/AdminPages/VipPlanPages/ServiceVipPlan.vue')
          },
          {
            name: 'service.coupon',
            path: 'coupon',
            component: () => import('../views/AdminPages/VipPlanPages/ServiceCoupon.vue')
          }
        ]
      }
    ]
  },
  {
    path: '/admin/statistics',
    component: AdminParentTemp,
    children: [
      {
        name: 'admin.statistics',
        path: '',
        component: statisticsParentTemp,
        meta: {
          isBackend: true,
          requiredPermission: true,
          permissionId: 8
        },
        redirect: to => {
          return { name: 'statistics.allPerDay' }
        },
        children: [
          {
            name: 'statistics.allPerDay',
            path: 'allPerDay',
            props: { statisticType: 0 },
            component: () => import('../views/AdminPages/Statistics/StatisticPerDay.vue')
          },
          {
            name: 'statistics.allPerMonth',
            path: 'allPerMonth',
            props: { statisticType: 0 },
            component: () => import('../views/AdminPages/Statistics/StatisticPerMonth.vue')
          },
          {
            name: 'statistics.allPerTimeZone',
            path: 'allPerTimeZone',
            props: { statisticType: 0 },
            component: () => import('../views/AdminPages/Statistics/StatisticPerTimeZone.vue')
          },
          {
            name: 'statistics.mileagePerDay',
            path: 'mileagePerDay',
            props: { statisticType: 1 },
            component: () => import('../views/AdminPages/Statistics/StatisticPerDay.vue')
          },
          {
            name: 'statistics.mileagePerMonth',
            path: 'mileagePerMonth',
            props: { statisticType: 1 },
            component: () => import('../views/AdminPages/Statistics/StatisticPerMonth.vue')
          },
          {
            name: 'statistics.mileagePerTimeZone',
            path: 'mileagePerTimeZone',
            props: { statisticType: 1 },
            component: () => import('../views/AdminPages/Statistics/StatisticPerTimeZone.vue')
          },
          {
            name: 'statistics.warningPerDay',
            path: 'warningPerDay',
            props: { statisticType: 2 },
            component: () => import('../views/AdminPages/Statistics/StatisticPerDay.vue')
          },
          {
            name: 'statistics.warningPerMonth',
            path: 'warningPerMonth',
            props: { statisticType: 2 },
            component: () => import('../views/AdminPages/Statistics/StatisticPerMonth.vue')
          },
          {
            name: 'statistics.warningPerTimeZone',
            path: 'warningPerTimeZone',
            props: { statisticType: 2 },
            component: () => import('../views/AdminPages/Statistics/StatisticPerTimeZone.vue')
          },
          {
            name: 'statistics.overspeedPerDay',
            path: 'overspeedPerDay',
            props: { statisticType: 3 },
            component: () => import('../views/AdminPages/Statistics/StatisticPerDay.vue')
          },
          {
            name: 'statistics.overspeedPerMonth',
            path: 'overspeedPerMonth',
            props: { statisticType: 3 },
            component: () => import('../views/AdminPages/Statistics/StatisticPerMonth.vue')
          },
          {
            name: 'statistics.overspeedPerTimeZone',
            path: 'overspeedPerTimeZone',
            props: { statisticType: 3 },
            component: () => import('../views/AdminPages/Statistics/StatisticPerTimeZone.vue')
          },
          {
            name: 'statistics.offlinePerDay',
            path: 'offlinePerDay',
            props: { statisticType: 4 },
            component: () => import('../views/AdminPages/Statistics/StatisticPerDay.vue')
          },
          {
            name: 'statistics.offlinePerMonth',
            path: 'offlinePerMonth',
            props: { statisticType: 4 },
            component: () => import('../views/AdminPages/Statistics/StatisticPerMonth.vue')
          },
          {
            name: 'statistics.offlinePerTimeZone',
            path: 'offlinePerTimeZone',
            props: { statisticType: 4 },
            component: () => import('../views/AdminPages/Statistics/StatisticPerTimeZone.vue')
          },
          {
            name: 'statistics.offlineDevice',
            path: 'offlineDevice',
            component: () => import('../views/AdminPages/Statistics/StatisticOfflineDevice.vue')
          },
          {
            name: 'statistics.onlineStatus',
            path: 'onlineStatus',
            component: () => import('../views/AdminPages/Statistics/StatisticOnlineStatus.vue')
          }
        ]
      }
    ]
  },
  {
    path: '/admin/report',
    component: AdminParentTemp,
    children: [
      {
        name: 'report',
        path: '',
        meta: {
          isBackend: true,
          requiredPermission: true,
          permissionId: 9
        },
        component: () => import('../views/AdminPages/Report.vue')
      }
    ]
  },
  {
    path: '/admin/finance',
    component: AdminParentTemp,
    children: [
      {
        name: 'finance',
        path: '',
        meta: {
          isBackend: true,
          requiredPermission: true,
          permissionId: 10
        },
        component: () => import('../views/AdminPages/Finance.vue')
      }
    ]
  },
  {
    path: '/admin/manualModifyRecord',
    component: AdminParentTemp,
    children: [
      {
        name: 'manualModifyRecord',
        path: '',
        meta: {
          isBackend: true,
          requiredPermission: true,
          permissionId: 10
        },
        component: () => import('../views/AdminPages/ManualModifyRecord.vue')
      }
    ]
  },
  {
    path: '/admin/announcement',
    component: AdminParentTemp,
    children: [
      {
        name: 'announcement',
        path: '',
        component: announcementParentTemp,
        meta: {
          isBackend: true,
          requiredPermission: true,
          permissionId: 11
        },
        redirect: to => {
          return { name: 'announcement.announcement' }
        },
        children: [
          {
            name: 'announcement.announcement',
            path: 'announcement',
            component: () => import('../views/AdminPages/Announcement/Announcement.vue')
          },
          {
            name: 'announcement.message',
            path: 'message',
            component: () => import('../views/AdminPages/Announcement/Message.vue')
          }
        ]
      }
    ]
  },
  {
    path: '/admin/systemLog',
    component: AdminParentTemp,
    children: [
      {
        name: 'systemLog',
        path: '',
        meta: {
          isBackend: true,
          requiredPermission: true,
          permissionId: 12
        },
        component: () => import('../views/AdminPages/SystemLog.vue')
      }
    ]
  },
  {
    path: '/admin/setting',
    component: AdminParentTemp,
    children: [
      {
        name: 'setting',
        path: '',
        meta: {
          isBackend: true,
          requiredPermission: true,
          permissionId: 13
        },
        component: () => import('../views/AdminPages/Setting.vue')
      }
    ]
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
