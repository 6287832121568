import { request } from '../../../api/axios'

const state = {
  statisticsList: [],
  allTableList: [],
  mileageTableList: [],
  warningTableList: [],
  overSpeedTableList: [],
  offlineTableList: [],
  selectMainIndex: 0
}

const getters = {
  getAllTableList (state) {
    return state.allTableList
  },
  getMileageTableList (state) {
    return state.mileageTableList
  },
  getWarningTableList (state) {
    return state.warningTableList
  },
  getOverSpeedTableList (state) {
    return state.overSpeedTableList
  },
  getOfflineTableList (state) {
    return state.offlineTableList
  },
  getSelectMainIndex (state) {
    return state.selectMainIndex
  }
}

const mutations = {
  setSelectMainIndex (state, index) {
    state.selectMainIndex = index
  },
  setAllTableList (state, data) {
    state.allTableList.length = 0
    data.rows.forEach(element => {
      element.statistics.forEach(statisticData => {
        const item = { ...statisticData, ...{ deviceId: element.deviceId, deviceSerialNumber: element.deviceSerialNumber, deviceAlias: element.deviceAlias } }
        state.allTableList.push(item)
      })
    })
  },
  setMileageTableList (state, data) {
    state.mileageTableList.length = 0
    data.rows.forEach(element => {
      element.statistics.forEach(statisticData => {
        const item = { ...statisticData, ...{ deviceId: element.deviceId, deviceSerialNumber: element.deviceSerialNumber, deviceAlias: element.deviceAlias } }
        state.mileageTableList.push(item)
      })
    })
  },
  setWarningTableList (state, data) {
    state.warningTableList.length = 0
    data.rows.forEach(element => {
      element.statistics.forEach(statisticData => {
        const item = { ...statisticData, ...{ deviceId: element.deviceId, deviceSerialNumber: element.deviceSerialNumber, deviceAlias: element.deviceAlias } }
        state.warningTableList.push(item)
      })
    })
  },
  setOverSpeedTableList (state, data) {
    state.overSpeedTableList.length = 0
    data.rows.forEach(element => {
      element.statistics.forEach(statisticData => {
        const item = { ...statisticData, ...{ deviceId: element.deviceId, deviceSerialNumber: element.deviceSerialNumber, deviceAlias: element.deviceAlias } }
        state.overSpeedTableList.push(item)
      })
    })
  },
  setOfflineTableList (state, data) {
    state.offlineTableList.length = 0
    data.rows.forEach(element => {
      element.statistics.forEach(statisticData => {
        const item = { ...statisticData, ...{ deviceId: element.deviceId, deviceSerialNumber: element.deviceSerialNumber, deviceAlias: element.deviceAlias } }
        state.offlineTableList.push(item)
      })
    })
  },
  clearAllStatisticList (state) {
    state.allTableList.length = 0
    state.mileageTableList.length = 0
    state.warningTableList.length = 0
    state.overSpeedTableList.length = 0
    state.offlineTableList.length = 0
  }
}

const actions = {
  async getOfflinePerDay ({ state, rootState }, data) {
    let params = {
      userToken: rootState.userToken
    }
    params = { ...params, ...data }
    return request('/api/offlineDayStatistics', JSON.stringify(params))
  },
  async getOfflineMultiDay ({ state, rootState }, data) {
    let params = {
      userToken: rootState.userToken
    }
    params = { ...params, ...data }
    return request('/api/offlineMultiDaysStatistics', JSON.stringify(params))
  },
  async getWarningPerDay ({ state, rootState }, data) {
    let params = {
      userToken: rootState.userToken
    }
    params = { ...params, ...data }
    return request('/api/warningDayStatistics', JSON.stringify(params))
  },
  async getWarningMultiDay ({ state, rootState }, data) {
    let params = {
      userToken: rootState.userToken
    }
    params = { ...params, ...data }
    return request('/api/warningMultiDaysStatistics', JSON.stringify(params))
  },
  async getOverSpeedPerDay ({ state, rootState }, data) {
    let params = {
      userToken: rootState.userToken
    }
    params = { ...params, ...data }
    return request('/api/overspeedDayStatistics', JSON.stringify(params))
  },
  async getOverSpeedMultiDay ({ state, rootState }, data) {
    let params = {
      userToken: rootState.userToken
    }
    params = { ...params, ...data }
    return request('/api/overspeedMultiDaysStatistics', JSON.stringify(params))
  },
  async getMileagePerDay ({ state, rootState }, data) {
    let params = {
      userToken: rootState.userToken
    }
    params = { ...params, ...data }
    return request('/api/mileageDayStatistics', JSON.stringify(params))
  },
  async getMileageMultiDay ({ state, rootState }, data) {
    let params = {
      userToken: rootState.userToken
    }
    params = { ...params, ...data }
    return request('/api/mileageMultiDaysStatistics', JSON.stringify(params))
  },
  async getAllPerDay ({ state, rootState }, data) {
    let params = {
      userToken: rootState.userToken
    }
    params = { ...params, ...data }
    return request('/api/allDayStatistics', JSON.stringify(params))
  },
  async getAllMultiDay ({ state, rootState }, data) {
    let params = {
      userToken: rootState.userToken
    }
    params = { ...params, ...data }
    return request('/api/allMultiDaysStatistics', JSON.stringify(params))
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
