import axios from 'axios'
import { errorMessage } from './errorStatus'

axios.defaults.baseURL = process.env.VUE_APP_BASE_API
axios.defaults.timeout = 60000

axios.interceptors.request.use(
  config => {
    config.headers = {
      'Content-Type': 'text/plain;charset=UTF-8',
      'Access-Control-Allow-Origin': '*'
    }
    return config
  }, error => {
    return Promise.reject(error)
  })

axios.interceptors.response.use(
  response => {
    if (response) {
      if (response.request.responseType === 'blob') {
        return response.data
      } else if (response.data.code === 0) {
        return response.data
      } else {
        response.data.errMsg = response.data.message
        return Promise.reject(response.data)
      }
    }
    return response.data
  }, error => {
    const { response } = error
    if (response) {
      if (typeof response.data === 'object') {
        response.data.errMsg = errorMessage(response.status)
        return Promise.reject(response.data)
      } else {
        response.errMsg = errorMessage(response.status)
        return Promise.reject(response)
      }
    } else {
      // TODO ERROR Message
    }
  }
)

export function request (url = '', params = {}, type = 'POST') {
  return new Promise((resolve, reject) => {
    let promise
    if (type.toUpperCase() === 'GET') {
      promise = axios({
        url,
        params
      })
    } else if (type.toUpperCase() === 'POST') {
      promise = axios({
        method: 'POST',
        url,
        data: params
      })
    } else if (type.toUpperCase() === 'DOWNLOAD') {
      promise = axios({
        url,
        responseType: 'blob',
        params
      })
    }
    promise.then(res => {
      resolve(res)
    }).catch(err => {
      reject(err)
    })
  })
}
