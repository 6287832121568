import { request } from '../../../api/axios'

const state = {
  pageIndex: 0,
  pageRows: 20,
  totalCount: 0,
  mileageMaintenceList: []
}

const getters = {
  pageIndex (state) {
    return state.pageIndex
  },
  pageRows (state) {
    return state.pageRows
  },
  totalCount (state) {
    return state.totalCount
  },
  getMileageList (state) {
    return state.mileageMaintenceList
  }
}

const mutations = {
  setPageIndex (state, index) {
    state.pageIndex = index
  },
  setMileageList (state, data) {
    state.mileageMaintenceList.length = 0
    state.totalCount = data.total
    data.rows.forEach(element => {
      state.mileageMaintenceList.push(element)
    })
  }
}

const actions = {
  getMileageMaintanceList ({ rootState, state }) {
    const params = {
      userToken: rootState.userToken,
      pageIndex: state.pageIndex,
      pageRows: state.pageRows
    }
    // let data = {}
    // if (rootState.userData.accountType === 3) {
    //   data = { carNumber: rootState.userData.account }
    // }
    // params = { ...params, ...data }
    return request('/api/getMileageMaintances', JSON.stringify(params))
  },
  addMileageMaintanceData ({ rootState }, data) {
    let params = {
      userToken: rootState.userToken
    }
    params = { ...params, ...data }
    return request('/api/addMileageMaintance', JSON.stringify(params))
  },
  updateMileageMaintanceData ({ rootState }, updateData) {
    let params = {
      userToken: rootState.userToken
    }
    params = { ...params, ...updateData }
    return request('/api/editMileageMaintance', JSON.stringify(params))
  },
  deleteMileageMaintanceData ({ rootState }, maintanceId) {
    const params = {
      userToken: rootState.userToken,
      id: maintanceId
    }
    return request('/api/deleteMileageMaintance', JSON.stringify(params))
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
