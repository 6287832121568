<template>
  <div class="d-flex">
    <div class="align-self-center me-3">{{ AccountName }}</div>
    <div class="dropdown text-end">
      <a href="#" class="d-block link-dark text-decoration-none dropdown-toggle" id="dropdownUser1" data-bs-toggle="dropdown" aria-expanded="false">
        <img src="../../assets/images/avatar_2x.png" class="rounded-circle">
      </a>
      <ul class="dropdown-menu text-small" aria-labelledby="dropdownUser1" style="">
        <li><button class="dropdown-item" @click="onLogoutClickEvent">{{ t('GENERAL.LOGOUT') }}</button></li>
      </ul>
    </div>
  </div>
</template>

<script>
import { inject, onMounted } from '@vue/runtime-core'
import { useI18n } from 'vue-i18n'
import { useCookies } from 'vue3-cookies'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
export default {
  setup () {
    const { t } = useI18n()
    const { cookies } = useCookies()
    const store = useStore()
    const router = useRouter()
    const configData = inject('configData')
    const AccountName = store.getters.getAccountName
    const onLogoutClickEvent = () => {
      cookies.remove(configData.cookieName)
      router.push({
        name: 'admin.login'
      })
    }

    function getPlanItem () {
      store.dispatch('getPlans').then((res) => {
        store.commit('setPlanItem', res.data)
      })
    }

    onMounted(() => {
      getPlanItem()
    })
    return {
      t,
      AccountName,
      onLogoutClickEvent
    }
  }
}
</script>

<style lang="stylus" scoped>
.rounded-circle {
  width: 32px
  height: 32px
}
</style>
