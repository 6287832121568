<template>
  <div v-if="showModal" class="modal-overlay">
    <div class="modal-dialog">
      <div class="modal-content">
        <h4 class="modal-title">{{ t('MONITOR.DRAW_MARKER_POINT') }}</h4>
        <hr/>
        <form class="row g-3 p-3">
          <div class="row mt-2">
            <div ref="MapTalksMap" class="base-map justify-content-center mx-3 mapContent" id="modal-map"></div>
          </div>
        </form>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" @click="onCancelClickEvent">{{ t('GENERAL.CLOSE') }}</button>
          <button type="button" class="btn btn-primary" @click="onSubmitClickEvent">{{ t('GENERAL.SAVE') }}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, ref } from '@vue/reactivity'
import { useI18n } from 'vue-i18n'
import { computed, inject } from '@vue/runtime-core'
import { Loader } from '@googlemaps/js-api-loader'
import { useStore } from 'vuex'
export default {
  emits: ['modalCallback'],
  setup (props, { emit }) {
    const { t } = useI18n()
    const store = useStore()
    const configData = inject('configData')

    const showModal = ref(false)
    const isEditor = ref(false)
    const selectDrawType = ref(0)

    const mapCenter = computed(() => store.getters['mapData/mapCenter'])
    const mapZoom = computed(() => store.getters['mapData/mapZoom'])

    const marker = ref()
    const markerCoord = ref({})

    const mapStates = reactive({
      google: null,
      map: null,
      markers: null
    })

    const initMap = async () => {
      const loader = new Loader({
        apiKey: configData.googleMapAPIKey,
        version: 'weekly',
        libraries: ['places'],
        language: 'zh-TW'
      })
      mapStates.google = await loader.load()
      mapStates.map = new mapStates.google.maps.Map(document.getElementById('modal-map'), {
        clickableIcons: false,
        center: mapCenter.value,
        zoom: mapZoom.value,
        disableDefaultUI: true
      })
      mapStates.map.addListener('click', (mapsMouseEvent) => {
        markerCoord.value = Object.assign({}, mapsMouseEvent.latLng.toJSON())
        marker.value.setMap(mapStates.map)
        marker.value.setPosition(mapsMouseEvent.latLng)
      })
      marker.value = new mapStates.google.maps.Marker({
        clickable: false
      })
      if (isEditor.value) {
        marker.value.setMap(mapStates.map)
        marker.value.setPosition(new mapStates.google.maps.LatLng(markerCoord.value))
        mapStates.map.setZoom(15)
        mapStates.map.setCenter(new mapStates.google.maps.LatLng(markerCoord.value))
      }
    }

    function showModalWithData (editor, data) {
      isEditor.value = editor
      if (isEditor.value) {
        markerCoord.value = Object.assign({}, { lat: parseFloat(data.latitude), lng: parseFloat(data.longitude) })
      }
      showModal.value = true
      initMap()
    }

    function onModalCancel () {
      showModal.value = false
    }

    function onModalSubmit () {
      const data = {
        latitude: markerCoord.value.lat,
        longitude: markerCoord.value.lng
      }
      emit('modalCallback', data)
      showModal.value = false
    }

    return {
      t,
      markerCoord,
      configData,
      showModal,
      selectDrawType,
      marker,
      showModalWithData,
      onModalCancel,
      onModalSubmit
    }
  },
  methods: {
    onCancelClickEvent () {
      this.onModalCancel()
    },
    onSubmitClickEvent () {
      this.onModalSubmit()
    }
  }
}
</script>

<style lang="stylus" scoped>
.modal-overlay {
  z-index: 5005
  .modal-dialog {
    min-width: 90%
    .map {
      min-width:80%
    }
    .mapContent {
      width: 100%
      height: calc(100vh - 25rem)
    }
  }
}
</style>
