<template>
  <div v-if="showModal" class="modal-overlay">
    <div class="modal-dialog">
      <div class="modal-content">
        <h4 class="modal-title">{{ t('GENERAL.MESSAGE_LIST') }}</h4>
        <hr />
        <form class="row px-1">
          <div v-if="isList" class="content">
            <table class="table table-sm table-striped align-middle mt-0">
              <thead class="table-primary">
                <tr>
                  <th scope="col" class="col-2 text-nowrap">{{ t('GENERAL.MESSAGE_TITLE[0]') }}</th>
                  <th scope="col" class="col-1 text-nowrap">{{ t('GENERAL.MESSAGE_TITLE[1]') }}</th>
                  <th scope="col" class="col-auto text-nowrap">{{ t('GENERAL.MESSAGE_TITLE[2]') }}</th>
                  <th scope="col" class="col-1 text-nowrap">{{ t('GENERAL.MESSAGE_TITLE[3]') }}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(data, index) in totalList" :key="index">
                  <th scope="row" v-text="showDate(data)"></th>
                  <td v-text="showCategory(data)"></td>
                  <td class="tableContent" v-text="data.content"></td>
                  <td>
                    <button class="btn btn-sm btn-warning" @click="onViewMessageClickEvent(data)">{{ t('GENERAL.VIEW') }}</button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <modal-message-detail v-else class="detailContent" :messageData="selectData"/>
        </form>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" @click="onCancelClickEvent">{{ t(isList ? 'GENERAL.CLOSE' : 'GENERAL.RETURN') }}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from '@vue/reactivity'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'
import { inject } from 'vue'
import ModalMessageDetail from './ModalMessageDetail.vue'
export default {
  components: { ModalMessageDetail },
  emits: ['modalCallback'],
  setup (props, { emit }) {
    const { t } = useI18n()
    const store = useStore()
    const tools = inject('tools')

    const showModal = ref(false)
    const modalData = ref()

    const isList = ref(true)
    const totalList = ref([])
    const messageTotalCount = ref(0)
    const announcementTotalCount = ref(0)
    const selectData = ref({})

    function showModalWithData (data) {
      modalData.value = Object.assign({}, data)
      totalList.value.length = 0
      getAnnounementList()
      getMessageList()
      isList.value = true
      showModal.value = true
    }

    function showDate (data) {
      if (data.receiverAccountId) {
        return tools.getShortDate(data.activeDate, '---')
      } else {
        return ''
      }
    }

    function showCategory (data) {
      return t('GENERAL.MESSAGE_TYPE[' + (data.receiverAccountId ? 1 : 0) + ']')
    }

    function getAnnounementList () {
      store.dispatch('clientAnnouncement/getAnnouncementList').then((res) => {
        announcementTotalCount.value = res.data.total
        res.data.rows.forEach(element => {
          totalList.value.push(element)
        })
      })
    }

    function getMessageList () {
      store.dispatch('clientAnnouncement/getMessageList').then((res) => {
        messageTotalCount.value = res.data.total
        res.data.rows.forEach(element => {
          totalList.value.push(element)
        })
      })
    }

    function onViewMessageClickEvent (data) {
      selectData.value = Object.assign({}, data)
      isList.value = false
    }

    function onModalCancel () {
      if (isList.value) {
        showModal.value = false
      } else {
        isList.value = true
      }
    }

    function onModalSubmit () {
      showModal.value = false
    }

    return {
      t,
      showModal,
      totalList,
      modalData,
      selectData,
      isList,
      showCategory,
      showDate,
      showModalWithData,
      onViewMessageClickEvent,
      onModalCancel,
      onModalSubmit
    }
  },
  methods: {
    onCancelClickEvent () {
      this.onModalCancel()
    },
    onSubmitClickEvent () {
      this.onModalSubmit()
    }
  }
}
</script>

<style lang="stylus" scoped>
.content {
  height: calc(50vh)
}
.detailContent {
  height: calc(50vh)
}
.table {
  overflow: auto
  & thead {
    position: sticky
    top: 0
  }
}
.tableContent {
    max-width: 100px
    overflow: hidden
    text-overflow: ellipsis
    white-space: nowrap
  }
</style>
