<template>
  <div class="fixed-top d-flex">
    <div class="p-2">
      <img class="toplogo" src="../../assets/images/topLogo.png" alt="logo">
    </div>
    <div class="p-2 flex-grow-1 align-self-center">
      <label class="d-flex">昇豪科技GPS查車平台</label>
    </div>
    <div class="align-self-center m-3">
      <status-bar></status-bar>
    </div>
  </div>
</template>

<script>
import StatusBar from '../ClientPages/StatusBar.vue'
export default {
  components: {
    StatusBar
  }
}
</script>

<style lang="stylus" scoped>
.d-flex {
  top: 0
  font-size: 1rem
}
.toplogo {
  width: 122px
  height: 50px
}

@media screen and (max-height: 720px) {
  .d-flex {
    top: 0
    font-size: 0.7rem
  }
  .toplogo {
    width: 100px
    height: 40px
  }
}
</style>
