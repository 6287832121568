<template>
  <div class="container-fluid mainLayout overflow-hidden">
    <div class="topBanner">{{ t('TOP_TABS.CUSTOMER') }}</div>
    <div class="row mt-0">
      <div class="col sideMenu">
        <left-side/>
      </div>
      <div class="col content m-1">
        <content-side/>
      </div>
    </div>
  </div>
</template>

<script>
import { useI18n } from 'vue-i18n'
import LeftSide from './Customer/LeftSide.vue'
import ContentSide from './Customer/ContentSide.vue'
import { useRoute, useRouter } from 'vue-router'
import { onMounted } from '@vue/runtime-core'
import { useStore } from 'vuex'
export default {
  setup () {
    const { t } = useI18n()
    const store = useStore()
    const route = useRoute()
    const router = useRouter()

    function checkPermission () {
      let monted = true
      if (route.meta) {
        if (route.meta.requiredPermission) {
          monted = store.getters.getAdminPermissionView(route.meta.permissionId)
        }
      }
      if (!monted) {
        router.push({
          name: 'admin.login'
        })
      }
    }

    onMounted(() => {
      checkPermission()
    })

    return {
      t
    }
  },
  components: {
    LeftSide,
    ContentSide
  }
}
</script>

<style lang="stylus" scoped>
.sideMenu {
  max-width: 16rem
  padding-right: 0
}
.content {
  min-width: 500px
  --bs-gutter-x : 0
}
</style>
