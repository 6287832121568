import { request } from '../../../api/axios'

const state = {
  fleetList: [],
  totalCount: 0,
  pageIndex: 0,
  pageRows: 100,
  subFleetList: [],
  subTotalCount: 0,
  subPageIndex: 0,
  deviceList: [],
  deviceTotalCount: 0,
  devicePageIndex: 0,
  modalSubFleetList: []
}

const getters = {
  totalCount (state) {
    return state.totalCount
  },
  pageIndex (state) {
    return state.pageIndex
  },
  pageRows (state) {
    return state.pageRows
  },
  totalSubCount (state) {
    return state.subTotalCount
  },
  pageSubIndex (state) {
    return state.subPageIndex
  },
  totalDeviceCount (state) {
    return state.subTotalCount
  },
  pageDeviceIndex (state) {
    return state.subPageIndex
  },
  getFleetList (state) {
    return state.fleetList
  },
  getFleetDataById: (state) => (id) => {
    return state.fleetList.find(item => item.id === id)
  },
  getSubFleetList (state) {
    return state.subFleetList
  },
  getModalSubFleetList (state) {
    return state.modalSubFleetList
  },
  getSubFleetDataById: (state) => (id) => {
    return state.subFleetList.find(element => element.id === id)
  },
  getDevicetList (state) {
    return state.deviceList
  },
  getDeviceListById: (state) => (id) => {
    return state.deviceList.filter(device => device.parentFleetId === id)
  }
}

const mutations = {
  setPageIndex (state, index) {
    state.pageIndex = index
  },
  setSubPageIndex (state, index) {
    state.subPageIndex = index
  },
  setFleetList (state, data) {
    state.fleetList.length = 0
    state.totalCount = data.total
    data.rows.forEach(element => {
      if (!element.email) {
        element.email = ''
      }
      state.fleetList.push(element)
    })
  },
  setFleetDeviceList (state, data) {
    state.deviceList.length = 0
    data.rows.forEach(element => {
      state.deviceList.push(element)
    })
  },
  setSubFleetList (state, data) {
    state.subFleetList.length = 0
    state.subTotalCount = data.total
    data.rows.forEach(element => {
      if (!element.password) {
        element.password = ''
      }
      state.subFleetList.push(element)
    })
  },
  setModalSubFleetList (state, data) {
    state.modalSubFleetList.length = 0
    state.subTotalCount = data.total
    data.rows.forEach(element => {
      state.modalSubFleetList.push(element)
    })
  },
  clearSubFleetAndDeviceList (state) {
    state.modalSubFleetList.length = 0
    state.deviceList.length = 0
  }
}

const actions = {
  async getAdminFleetList ({ state, rootState }, searchKeyword = '') {
    let params = {
      userToken: rootState.userToken,
      pageIndex: state.pageIndex,
      pageRows: state.pageRows,
      parentFleetId: searchKeyword === '' ? 'null' : null
    }
    if (searchKeyword !== '') {
      params = { ...params, ...{ keyword: searchKeyword } }
    }
    return request('/api/getFleets', JSON.stringify(params))
  },
  async getAdminParentFleetList ({ state, rootState }, parentFleetId) {
    const params = {
      userToken: rootState.userToken,
      pageIndex: 0,
      pageRows: 1,
      id: parentFleetId
    }
    return request('/api/getFleets', JSON.stringify(params))
  },
  async getFleetDataByAccount ({ rootState }, fleetAccount) {
    const params = {
      userToken: rootState.userToken,
      pageIndex: 0,
      pageRows: 1,
      account: fleetAccount
    }
    return request('/api/getFleets', JSON.stringify(params))
  },
  async createFleetAccount ({ rootState }, fleetData) {
    let params = {
      userToken: rootState.userToken
    }
    params = { ...params, ...fleetData }
    return request('/api/createFleet', JSON.stringify(params))
  },
  async updateFleetAccount ({ rootState }, updateData) {
    let params = {
      userToken: rootState.userToken
    }
    params = { ...params, ...updateData }
    return request('/api/editFleet', JSON.stringify(params))
  },
  async deleteFleetAccount ({ rootState }, deleteId) {
    const params = {
      userToken: rootState.userToken,
      id: deleteId
    }
    return request('/api/deleteFleet', JSON.stringify(params))
  },
  async getSubFleetList ({ state, rootState }, { fleetId }) {
    const params = {
      userToken: rootState.userToken,
      pageIndex: state.pageSubIndex,
      pageRows: state.pageRows,
      parentFleetId: fleetId
    }
    return request('/api/getSubFleets', JSON.stringify(params))
  },
  async createSubFleetAccount ({ rootState }, subFleetData) {
    let params = {
      userToken: rootState.userToken
    }
    params = { ...params, ...subFleetData }
    return request('/api/createSubFleet', JSON.stringify(params))
  },
  async updateSubFleetAccount ({ rootState }, updateData) {
    let params = {
      userToken: rootState.userToken
    }
    params = { ...params, ...updateData }
    return request('/api/editSubFleet', JSON.stringify(params))
  },
  async deleteSubFleetAccount ({ rootState }, deleteId) {
    const params = {
      userToken: rootState.userToken,
      id: deleteId
    }
    return request('/api/deleteSubFleet', JSON.stringify(params))
  },
  async getFleetOptionListByKeyword ({ rootState }, searchKeyword) {
    const params = {
      userToken: rootState.userToken,
      pageIndex: 0,
      pageRows: 0,
      keyword: searchKeyword
    }
    return request('/api/getFleets', JSON.stringify(params))
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
