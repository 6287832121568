<template>
  <div class="container-fluid">
    <div class="tab" role="tabpanel">
      <ul class="nav nav-tabs" role="tablist">
        <div v-for="data in menuData" :key="data.id">
          <div v-if="getPermissionView(data.id)">
            <router-link  :to="{ name: data.name }" custom v-slot=" { navigate, isActive}">
              <li :class="{ 'active': isActive }">
                <div class="item" @click="navigate">{{ t(data.title) }}</div>
              </li>
            </router-link>
          </div>
        </div>
      </ul>
    </div>
  </div>
</template>

<script>
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { computed, inject } from '@vue/runtime-core'
export default {
  setup () {
    const { t } = useI18n()
    const router = useRouter()
    const store = useStore()
    const defaultData = inject('defaultData')

    const menuData = computed(() => defaultData.adminTabMenu)

    const onMenuClickEvent = (routerName) => {
      router.push({ name: routerName })
    }

    function getPermissionView (id) {
      return store.getters.getAdminPermissionView(id)
    }

    return {
      t,
      menuData,
      getPermissionView,
      onMenuClickEvent
    }
  },
  components: {
  }
}
</script>

<style lang="stylus">
.item:hover,
.item:focus{
    text-decoration: none;
    outline: none;
}
.tab .nav-tabs{
    padding: 0;
    margin: 0;
    border: none;
    border-bottom: 3px solid #4ba6ed;
}
.tab .nav-tabs li .item {
    color: #222;
    background: #fff;
    font-size: 1rem;
    font-weight: 600;
    text-align: center;
    letter-spacing: 1px;
    text-transform: capitalize;
    padding: 3px 10px 3px;
    margin: 0 2px 1px 0;
    border: none;
    border-radius: 0;
    box-shadow: 0 0 10px rgba(0,0,0,0.1);
    position: relative;
    z-index: 1;
    transition: all 0.3s ease 0s;
}
.tab .nav-tabs li.active .item,
.tab .nav-tabs li .item:hover,
.tab .nav-tabs li.active .item:hover {
    color: #fff;
    background: transparent;
    border: none;
    box-shadow: none;
}
.tab .nav-tabs li .item:before {
    content: "";
    background: #4ba6ed;
    height: 100%;
    width: 100%;
    opacity: 0;
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: -1;
    clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
    transition: all 0.3s ease 0s;
}
.tab .nav-tabs li.active .item:before,
.tab .nav-tabs li .item:hover:before {
    opacity: 1;
    clip-path: polygon(10% 0%, 90% 0%, 100% 100%, 0% 100%);
}
@media only screen and (max-width: 479px) {
    .tab .nav-tabs {
        padding: 0;
        margin: 0 0 10px;
        border-bottom: none;
        border-left: 3px solid #4ba6ed;
    }
    .tab .nav-tabs li {
        width: 100%;
        text-align: center;
    }
    .tab .nav-tabs li .item { margin: 0 0 5px; }
}
</style>
