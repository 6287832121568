<template>
  <div class="rightClickMenu" v-if="showMenu" @click="onHideRightMenuClick" @contextmenu="onHideRightClickEvent">
    <ul class="dropdown-menu dropdown-menu-sm fleetMenuContent" :style="{ top: posTop + 'px', left: posLeft + 'px' }"
      id="context-menu">
      <li><a class="dropdown-item" @click="onMainMenuClickEvent(0, 0)" @mouseover="onMouseOverEvent(-1)">{{
          t('CONTEXT_MENU.DEVICE[0]') }}</a></li>
      <li><a class="dropdown-item" @click="onMainMenuClickEvent(1, 0)" @mouseover="onMouseOverEvent(-1)" href="#">{{
          t('CONTEXT_MENU.DEVICE[1]') }}</a></li>
      <li><a class="dropdown-item" @click="onMainMenuClickEvent(2, 0)" @mouseover="onMouseOverEvent(-1)" href="#">{{
          t('CONTEXT_MENU.DEVICE[2]') }}</a></li>
      <li><a class="dropdown-item" @click="onMainMenuClickEvent(3, 0)" @mouseover="onMouseOverEvent(-1)">{{
          t('CONTEXT_MENU.DEVICE[3]') }}</a></li>
      <li v-if="permissions[2] === 1" class="dropdown-submenu">
        <a class="dropdown-item dropdown-toggle" @mouseover="onMouseOverEvent(0)" href="#">{{
          t('CONTEXT_MENU.DEVICE[4]') }}</a>
        <div v-if="showSubMenu[0]" @mouseleave="onMouseLeaveEvent(0)">
          <ul class="dropdown-menu dropdown-menu-sm fleetMenuContent">
            <li><a class="dropdown-item" @mouseover="onMouseOverSubRemoteEvent(false)"
                @click="onMainMenuClickEvent(4, 'refreshDevice')">{{ t('CONTEXT_MENU.REMOTE_MAIN[0]') }}</a></li>
            <li class="dropdown-submenu">
              <a class="dropdown-item dropdown-toggle" @mouseover="onMouseOverSubRemoteEvent(true)">{{
                t('CONTEXT_MENU.REMOTE_MAIN[1]') }}</a>
              <div v-if="showRemoteSubMenu" @mouseleave="onMouseLeaveEvent(1)">
                <ul class="dropdown-menu dropdown-menu-sm fleetMenuContent">
                  <li><a class="dropdown-item" @click="onMainMenuClickEvent(4, 13)">{{
                      t('REMOTE.CONTROL[1]') }}</a></li>
                  <li><a class="dropdown-item" @click="onMainMenuClickEvent(4, 12)">{{
                      t('REMOTE.CONTROL[0]') }}</a></li>
                </ul>
              </div>
            </li>
            <li><a class="dropdown-item" @mouseover="onMouseOverSubRemoteEvent(false)"
                @click="onMainMenuClickEvent(4, 'remote')">{{ t('CONTEXT_MENU.REMOTE_MAIN[2]') }}</a></li>
          </ul>
        </div>
      </li>
      <li class="dropdown-submenu">
        <a class="dropdown-item dropdown-toggle" @mouseover="onMouseOverEvent(1)" href="#">{{
          t('CONTEXT_MENU.DEVICE[5]') }}</a>
        <div v-if="showSubMenu[1]" @mouseleave="onMouseLeaveEvent(1)">
          <ul class="dropdown-menu dropdown-menu-sm fleetMenuContent">
            <li><a class="dropdown-item" @click="onMainMenuClickEvent(5, 'client.allPerDay')">{{
                t('ADMIN_STATISTICS.SIDE_MENU[0]') }}</a></li>
            <li><a class="dropdown-item" @click="onMainMenuClickEvent(5, 'client.mileagePerDay')">{{
                t('ADMIN_STATISTICS.SIDE_MENU[1]') }}</a></li>
            <li><a class="dropdown-item" @click="onMainMenuClickEvent(5, 'client.warningPerDay')">{{
                t('ADMIN_STATISTICS.SIDE_MENU[2]') }}</a></li>
            <li v-if="enableOverSpeed"><a class="dropdown-item"
                @click="onMainMenuClickEvent(5, 'client.overspeedPerDay')">{{ t('ADMIN_STATISTICS.SIDE_MENU[3]') }}</a>
            </li>
            <li v-if="enableOffline"><a class="dropdown-item"
                @click="onMainMenuClickEvent(5, 'client.offlinePerDay')">{{ t('ADMIN_STATISTICS.SIDE_MENU[4]') }}</a>
            </li>
          </ul>
        </div>
      </li>
      <li class="dropdown-submenu">
        <a class="dropdown-item dropdown-toggle" @mouseover="onMouseOverEvent(2)">{{ t('CONTEXT_MENU.DEVICE[6]') }}</a>
        <div v-if="showSubMenu[2]" @mouseleave="onMouseLeaveEvent(2)">
          <ul class="dropdown-menu dropdown-menu-sm fleetMenuContent">
            <li><a class="dropdown-item" @click="onMainMenuClickEvent(6, 0)">{{ t('MONITOR.TABS[1]') }}</a></li>
            <li><a class="dropdown-item" @click="onMainMenuClickEvent(6, 1)">{{ t('MONITOR.TABS[2]') }}</a></li>
          </ul>
        </div>
      </li>
      <li v-if="permissions[1] === 1 && accountType === 2" class="dropdown-submenu">
        <a class="dropdown-item dropdown-toggle" @mouseover="onMouseOverEvent(3)">{{ t('CONTEXT_MENU.DEVICE[7]') }}</a>
        <div v-if="showSubMenu[3]" @mouseleave="onMouseLeaveEvent(3)">
          <ul class="dropdown-menu dropdown-menu-sm fleetMenuContent">
            <li v-for="(fleet, index) in accountFleetList" :key="index">
              <a class="dropdown-item" @click="onMainMenuClickEvent(7, fleet.id)">{{ fleet.alias }}</a>
            </li>
          </ul>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import { computed, onMounted, ref } from '@vue/runtime-core'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'
export default {
  props: ['posX', 'posY'],
  emits: ['rightMenuCallback'],
  setup (props, { emit }) {
    const { t } = useI18n()
    const store = useStore()
    const posTop = computed(() => props.posY)
    const posLeft = computed(() => props.posX)

    const accountType = computed(() => store.getters.getUserAccountType)
    const permissions = computed(() => store.getters.getClientUserPermissions)

    const enableOverSpeed = computed(() => store.getters['setting/getClientShowOverSpeed'])
    const enableOffline = computed(() => store.getters['setting/getClientShowOffline'])

    const showSubMenu = ref([
      false, false, false, false
    ])

    const showRemoteSubMenu = ref(false)

    const selectDevice = ref({})

    const accountFleetList = computed(() => store.getters['clientAccount/accountMenuFleetList'])

    const showMenu = ref(false)

    function showRightClickMenu (itemData) {
      selectDevice.value = Object.assign({}, itemData)
      onMouseOverEvent(-1)
      onMouseOverSubRemoteEvent(false)
      showMenu.value = true
    }

    function onHideRightMenuClick () {
      showMenu.value = false
    }

    function onHideRightClickEvent (event) {
      showMenu.value = false
      event.preventDefault()
    }

    function onMouseOverEvent (index) {
      for (let i = 0; i < showSubMenu.value.length; i++) {
        showSubMenu.value[i] = i === index
      }
    }

    function onMouseLeaveEvent (index) {
      showSubMenu.value[index] = false
    }

    function onMouseOverSubRemoteEvent (isShow) {
      showRemoteSubMenu.value = isShow
    }

    function onMouseLeaveSubRemoteEvent (isShow) {
      showRemoteSubMenu.value = isShow
    }

    function onMainMenuClickEvent (mainIndex, subIndex) {
      const callbackData = (mainIndex === 1 || mainIndex === 4) ? selectDevice.value : selectDevice.value.data
      emit('rightMenuCallback', mainIndex, subIndex, callbackData)
    }

    onMounted(() => {

    })

    return {
      t,
      permissions,
      accountType,
      enableOverSpeed,
      enableOffline,
      showMenu,
      showSubMenu,
      showRemoteSubMenu,
      posTop,
      posLeft,
      selectDevice,
      accountFleetList,
      showRightClickMenu,
      onHideRightMenuClick,
      onHideRightClickEvent,
      onMouseOverEvent,
      onMouseLeaveEvent,
      onMouseOverSubRemoteEvent,
      onMouseLeaveSubRemoteEvent,
      onMainMenuClickEvent
    }
  }
}

</script>

<style lang="stylus" scoped>
.rightClickMenu {
  .fleetMenuContent {
    display: block
    font-size: 0.7rem
  }
}
.dropdown-submenu {
  position: relative;
}

.dropdown-submenu a::after {
  transform: rotate(-90deg);
  position: absolute;
  right: 6px;
  top: .8em;
}

.dropdown-submenu .dropdown-menu {
  top: 0;
  left: 100%;
  margin-left: .1rem;
  margin-right: .1rem;
}
.dropdown-item:active {
  background #F00
}

</style>
