<template>
  <div class="rightClickMenu" v-if="showMenu" @click="onHideRightMenuClick" @contextmenu="onHideRightClickEvent">
    <ul class="dropdown-menu dropdown-menu-sm fleetMenuContent" :style="{ top: posTop + 'px', left: posLeft + 'px' }" id="context-menu">
      <li v-if="permissions[1] === 1"><a class="dropdown-item" @click="onMainMenuClickEvent(0, 0)" @mouseover="onMouseOverEvent(-1)">{{ t('CONTEXT_MENU.FLEET[4]') }}</a></li>
      <li v-if="permissions[2]" class="dropdown-submenu">
        <a class="dropdown-item dropdown-toggle" @mouseover="onMouseOverEvent(0)" href="#">{{ t('CONTEXT_MENU.FLEET[1]') }}</a>
        <div v-if="showSubMenu[0]" @mouseleave="onMouseLeaveEvent(0)">
          <ul class="dropdown-menu dropdown-menu-sm fleetMenuContent">
            <li><a class="dropdown-item" @click="onMainMenuClickEvent(1, false)">{{ t('CONTEXT_MENU.SUN_FLEET_REMOTE[0]') }}</a></li>
            <li><a class="dropdown-item" @click="onMainMenuClickEvent(1, true)">{{ t('CONTEXT_MENU.SUN_FLEET_REMOTE[1]') }}</a></li>
          </ul>
        </div>
      </li>
      <li class="dropdown-submenu">
        <a class="dropdown-item dropdown-toggle" @mouseover="onMouseOverEvent(1)" href="#">{{ t('CONTEXT_MENU.FLEET[2]') }}</a>
        <div v-if="showSubMenu[1]" @mouseleave="onMouseLeaveEvent(1)">
          <ul class="dropdown-menu dropdown-menu-sm fleetMenuContent">
            <li><a class="dropdown-item" @click="onMainMenuClickEvent(2, 'client.allPerDay')">{{ t('ADMIN_STATISTICS.SIDE_MENU[0]') }}</a></li>
            <li><a class="dropdown-item" @click="onMainMenuClickEvent(2, 'client.mileagePerDay')">{{ t('ADMIN_STATISTICS.SIDE_MENU[1]') }}</a></li>
            <li><a class="dropdown-item" @click="onMainMenuClickEvent(2, 'client.warningPerDay')">{{ t('ADMIN_STATISTICS.SIDE_MENU[2]') }}</a></li>
            <li v-if="enableOverSpeed"><a class="dropdown-item" @click="onMainMenuClickEvent(2, 'client.overspeedPerDay')">{{ t('ADMIN_STATISTICS.SIDE_MENU[3]') }}</a></li>
            <li v-if="enableOffline"><a class="dropdown-item" @click="onMainMenuClickEvent(2, 'client.offlinePerDay')">{{ t('ADMIN_STATISTICS.SIDE_MENU[4]') }}</a></li>
          </ul>
        </div>
      </li>
      <li class="dropdown-submenu">
        <a class="dropdown-item dropdown-toggle" @mouseover="onMouseOverEvent(2)">{{ t('CONTEXT_MENU.FLEET[3]') }}</a>
        <div v-if="showSubMenu[2]" @mouseleave="onMouseLeaveEvent(2)">
          <ul class="dropdown-menu dropdown-menu-sm fleetMenuContent">
            <li><a class="dropdown-item" @click="onMainMenuClickEvent(3, 0)">{{ t('MONITOR.TABS[1]') }}</a></li>
            <li><a class="dropdown-item" @click="onMainMenuClickEvent(3, 1)">{{ t('MONITOR.TABS[2]') }}</a></li>
          </ul>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import { computed, onMounted, ref } from '@vue/runtime-core'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'
export default {
  props: ['posX', 'posY'],
  emits: ['rightMenuCallback'],
  setup (props, { emit }) {
    const { t } = useI18n()
    const store = useStore()
    const posTop = computed(() => props.posY)
    const posLeft = computed(() => props.posX)

    const permissions = computed(() => store.getters.getClientUserPermissions)

    const enableOverSpeed = computed(() => store.getters['setting/getClientShowOverSpeed'])
    const enableOffline = computed(() => store.getters['setting/getClientShowOffline'])

    const showSubMenu = ref([
      false, false, false
    ])

    const selectFleetData = ref({})

    const showMenu = ref(false)

    function showRightClickMenu (itemData) {
      selectFleetData.value = Object.assign({}, itemData)
      onMouseOverEvent(-1)
      showMenu.value = true
    }

    function onHideRightMenuClick () {
      showMenu.value = false
    }

    function onHideRightClickEvent (event) {
      showMenu.value = false
      event.preventDefault()
    }

    function onMouseOverEvent (index) {
      for (let i = 0; i < showSubMenu.value.length; i++) {
        showSubMenu.value[i] = i === index
      }
    }

    function onMouseLeaveEvent (index) {
      showSubMenu.value[index] = false
    }

    function onMainMenuClickEvent (mainIndex, subIndex) {
      emit('rightMenuCallback', mainIndex, subIndex, selectFleetData.value)
    }

    onMounted(() => {

    })

    return {
      t,
      enableOverSpeed,
      enableOffline,
      permissions,
      showMenu,
      showSubMenu,
      selectFleetData,
      posTop,
      posLeft,
      showRightClickMenu,
      onHideRightMenuClick,
      onHideRightClickEvent,
      onMouseOverEvent,
      onMouseLeaveEvent,
      onMainMenuClickEvent
    }
  }
}

</script>

<style lang="stylus" scoped>
.rightClickMenu {
  .fleetMenuContent {
    display: block
    font-size: 0.7rem
  }
}
.dropdown-submenu {
  position: relative;
}

.dropdown-submenu a::after {
  transform: rotate(-90deg);
  position: absolute;
  right: 6px;
  top: .8em;
}

.dropdown-submenu .dropdown-menu {
  top: 0;
  left: 100%;
  margin-left: .1rem;
  margin-right: .1rem;
}

</style>
