<template>
  <div class="container-fluid clientLayout">
    <div class="row mt-2">
      <div class="col border-end sideMenu">
        <left-side-menu/>
      </div>
      <div class="col">
        <router-view/>
      </div>
    </div>
  </div>
</template>

<script>
import LeftSideMenu from './Statistic/LeftSideMenu'
export default {
  setup () {

  },
  components: {
    LeftSideMenu
  }
}
</script>

<style lang="stylus" scoped>
.sideMenu {
  max-width: 10rem
  padding-right: 0
}
</style>
