import { request } from '../../../api/axios'

const state = {
  categoryType: 0,
  pageIndex: 0,
  pageRows: 100,
  total: 0,
  financeList: [],
  recordList: []
}

const getters = {
  getCategoryType (state) {
    return state.categoryType
  },
  getFinanceList (state) {
    const startIndex = state.pageIndex * state.pageRows
    const endIndex = (state.pageIndex + 1) * state.pageRows
    return state.financeList.slice(startIndex, (endIndex > state.total) ? state.total : endIndex)
  },
  getRecordList (state) {
    const startIndex = state.pageIndex * state.pageRows
    const endIndex = (state.pageIndex + 1) * state.pageRows
    return state.recordList.slice(startIndex, (endIndex > state.total) ? state.total : endIndex)
  },
  getAllRecordList (state) {
    return state.recordList
  },
  getAllFinanceList (state) {
    return state.financeList
  },
  pageIndex (state) {
    return state.pageIndex
  },
  pageRows (state) {
    return state.pageRows
  },
  totalCount (state) {
    return state.total
  }
}

const mutations = {
  setFinanceList (state, data) {
    state.financeList.length = 0
    state.total = data.total
    data.rows.forEach(element => {
      state.financeList.push(element)
    })
  },
  setRecordList (state, data) {
    state.recordList.length = 0
    state.total = data.total
    data.rows.forEach(element => {
      state.recordList.push(element)
    })
  },
  setPageIndex (state, index) {
    state.pageIndex = index
  },
  setCategoryType (state, type) {
    state.categoryType = type
  }
}

const actions = {
  async getFinanceList ({ state, rootState }) {
    const params = {
      userToken: rootState.userToken,
      pageIndex: state.pageIndex,
      pageRows: state.pageRows
    }
    return request('/api/getPlanOrders', JSON.stringify(params))
  },
  async getSearchFinanceList ({ state, rootState }, data) {
    let params = {
      userToken: rootState.userToken,
      pageIndex: state.pageIndex,
      pageRows: 0,
      deleteData: 'NULL'
      // paymentResultNotLike: '[card6No] => 400022'
    }
    params = { ...params, ...data }
    return request('/api/getPlanOrders', JSON.stringify(params))
  },
  async abolitionInvoice ({ state, rootState }, abortOrderNumber) {
    const params = {
      userToken: rootState.userToken,
      orderNumber: abortOrderNumber
    }
    return request('/api/abolitionInvoice', JSON.stringify(params))
  },
  async refundPlanOrder ({ rootState }, refundOrderNumber) {
    const params = {
      userToken: rootState.userToken,
      orderNumber: refundOrderNumber
    }
    return request('/api/refundPlanOrder', JSON.stringify(params))
  },
  async getManualAdjustServiceLogs ({ state, rootState }, data) {
    let params = {
      userToken: rootState.userToken,
      pageIndex: state.pageIndex,
      pageRows: 0
    }
    params = { ...params, ...data }
    return request('/api/getManualAdjustServiceLogs', JSON.stringify(params))
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
