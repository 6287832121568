<template>
  <router-view/>
</template>

<script>
import { inject, onMounted } from '@vue/runtime-core'
import { useCookies } from 'vue3-cookies'
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'
export default {
  setup () {
    const { t } = useI18n()
    const configData = inject('configData')
    const { cookies } = useCookies()
    const store = useStore()
    const checkCookies = () => {
      const cookieData = cookies.get(configData.cookieName)
      if (cookieData) {
        store.commit('updateUserData', cookieData)
      }
    }

    onMounted(() => {
      document.title = t('GENERAL.TITLE')
      checkCookies()
    })

    return {
      checkCookies
    }
  }
}
</script>

<style lang="stylus">
#app
  font-family Avenir, Helvetica, Arial, sans-serif
  -webkit-font-smoothing antialiased
  -moz-osx-font-smoothing grayscale
  text-align center
  color #2c3e50
  margin-top 60px
</style>
