import { request } from '../../../api/axios'

const state = {
  allFleetList: [],
  statisticsList: [],
  allTableList: [],
  mileageTableList: [],
  warningTableList: [],
  overSpeedTableList: [],
  offlineTableList: [],
  selectMainIndex: 0,
  selectSubIndex: 0,
  onlineStatisticData: {},
  onlineTableIndex: 0,
  onlineTableRows: 100,
  onlineTableTotlaCount: 0,
  onlineTableData: [],
  offlineDeviceIndex: 0,
  offlineDeviceRows: 200,
  offlineDeviceList: [],
  offlineDeviceTotalCount: 0
}

const getters = {
  getStatisticList (state) {
    return state.statisticsList
  },
  getAllTableList (state) {
    return state.allTableList
  },
  getMileageTableList (state) {
    return state.mileageTableList
  },
  getWarningTableList (state) {
    return state.warningTableList
  },
  getOverSpeedTableList (state) {
    return state.overSpeedTableList
  },
  getOfflineTableList (state) {
    return state.offlineTableList
  },
  getSelectMainIndex (state) {
    return state.selectMainIndex
  },
  getAllFleetList (state) {
    return state.allFleetList
  },
  getOnlineStatisticData (state) {
    return state.onlineStatisticData
  },
  getOnlineIndex (state) {
    return state.onlineTableIndex
  },
  getOnlineRows (state) {
    return state.onlineTableRows
  },
  getOnlineTable (state) {
    return state.onlineTableData
  },
  getOnlineTableTotalCount (state) {
    return state.onlineTableTotlaCount
  },
  getOfflineDeviceIndex (state) {
    return state.offlineDeviceIndex
  },
  getOfflineDeviceRows (state) {
    return state.offlineDeviceRows
  },
  getOfflineDeviceTable (state) {
    return state.offlineDeviceList
  },
  getOfflineDeviceTotalCount (state) {
    return state.offlineDeviceTotalCount
  }
}

const mutations = {
  setSelectMainIndex (state, index) {
    state.selectMainIndex = index
  },
  setAllTableList (state, data) {
    state.allTableList.length = 0
    data.rows.forEach(element => {
      element.statistics.forEach(statisticData => {
        const item = { ...statisticData, ...{ deviceId: element.deviceId, deviceSerialNumber: element.deviceSerialNumber, deviceAlias: element.deviceAlias } }
        state.allTableList.push(item)
      })
    })
  },
  setMileageTableList (state, data) {
    state.mileageTableList.length = 0
    data.rows.forEach(element => {
      element.statistics.forEach(statisticData => {
        const item = { ...statisticData, ...{ deviceId: element.deviceId, deviceSerialNumber: element.deviceSerialNumber, deviceAlias: element.deviceAlias } }
        state.mileageTableList.push(item)
      })
    })
  },
  setWarningTableList (state, data) {
    state.warningTableList.length = 0
    data.rows.forEach(element => {
      element.statistics.forEach(statisticData => {
        const item = { ...statisticData, ...{ deviceId: element.deviceId, deviceSerialNumber: element.deviceSerialNumber, deviceAlias: element.deviceAlias } }
        state.warningTableList.push(item)
      })
    })
  },
  setOverSpeedTableList (state, data) {
    state.overSpeedTableList.length = 0
    data.rows.forEach(element => {
      element.statistics.forEach(statisticData => {
        const item = { ...statisticData, ...{ deviceId: element.deviceId, deviceSerialNumber: element.deviceSerialNumber, deviceAlias: element.deviceAlias } }
        state.overSpeedTableList.push(item)
      })
    })
  },
  setOfflineTableList (state, data) {
    state.offlineTableList.length = 0
    data.rows.forEach(element => {
      element.statistics.forEach(statisticData => {
        const item = { ...statisticData, ...{ deviceId: element.deviceId, deviceSerialNumber: element.deviceSerialNumber, deviceAlias: element.deviceAlias } }
        state.offlineTableList.push(item)
      })
    })
  },
  setStatisticList (state, data) {
    state.statisticsList.length = 0
    data.rows.forEach(element => {
      element.statistics.forEach(statisticData => {
        const item = { ...statisticData, ...{ deviceId: element.deviceId, deviceSerialNumber: element.deviceSerialNumber, deviceAlias: element.deviceAlias } }
        state.statisticsList.push(item)
      })
    })
  },
  addStatisticList (state, data) {
    data.rows.forEach(element => {
      element.statistics.forEach(statisticData => {
        const item = { ...statisticData, ...{ deviceId: element.deviceId, deviceSerialNumber: element.deviceSerialNumber, deviceAlias: element.deviceAlias } }
        state.statisticsList.push(item)
      })
    })
  },
  clearStatisticList (state) {
    state.statisticsList.length = 0
    state.allTableList.length = 0
    state.mileageTableList.length = 0
    state.warningTableList.length = 0
    state.overSpeedTableList.length = 0
    state.offlineTableList.length = 0
  },
  setAllFleetsAccount (state, data) {
    state.allFleetList.length = 0
    state.allFleetList.push({ account: 'All' })
    data.rows.forEach(fleet => {
      state.allFleetList.push(fleet)
    })
  },
  setOnlineStatisticsData (state, data) {
    state.onlineStatisticData = Object.assign({}, data)
    let total = 0
    Object.values(data).forEach(count => {
      total += count
    })
    state.onlineStatisticData.totalCount = total
  },
  setOnlineTableIndex (state, index) {
    state.onlineTableIndex = index
  },
  setOnlineTableData (state, data) {
    state.onlineTableTotlaCount = data.total
    state.onlineTableData.length = 0
    data.rows.forEach(device => {
      state.onlineTableData.push(device)
    })
  },
  clearOnlineTable (state) {
    state.onlineTableData.length = 0
    state.onlineTableIndex = 0
  },
  setOfflineDeviceIndex (state, index) {
    state.offlineDeviceIndex = index
  },
  setOffliceDeviceData (state, data) {
    state.offlineDeviceTotalCount = data.total
    state.offlineDeviceList.length = 0
    data.rows.forEach(device => {
      state.offlineDeviceList.push(device)
    })
  },
  clearOffliceDeviceData (state) {
    state.offlineDeviceTotalCount = 0
    state.offlineDeviceIndex = 0
    state.offlineDeviceList.length = 0
  }
}

const actions = {
  async getOfflinePerDay ({ commit, rootState }, data) {
    let params = {
      userToken: rootState.userToken
    }
    params = { ...params, ...data }
    return request('/api/offlineDayStatistics', JSON.stringify(params))
  },
  async getOfflineMultiDay ({ commit, rootState }, data) {
    let params = {
      userToken: rootState.userToken
    }
    params = { ...params, ...data }
    return request('/api/offlineMultiDaysStatistics', JSON.stringify(params))
  },
  async getWarningPerDay ({ commit, rootState }, data) {
    let params = {
      userToken: rootState.userToken
    }
    params = { ...params, ...data }
    return request('/api/warningDayStatistics', JSON.stringify(params))
  },
  async getWarningMultiDay ({ commit, rootState }, data) {
    let params = {
      userToken: rootState.userToken
    }
    params = { ...params, ...data }
    return request('/api/warningMultiDaysStatistics', JSON.stringify(params))
  },
  async getOverSpeedPerDay ({ commit, rootState }, data) {
    let params = {
      userToken: rootState.userToken
    }
    params = { ...params, ...data }
    return request('/api/overspeedDayStatistics', JSON.stringify(params))
  },
  async getOverSpeedMultiDay ({ commit, rootState }, data) {
    let params = {
      userToken: rootState.userToken
    }
    params = { ...params, ...data }
    return request('/api/overspeedMultiDaysStatistics', JSON.stringify(params))
  },
  async getMileagePerDay ({ commit, rootState }, data) {
    let params = {
      userToken: rootState.userToken
    }
    params = { ...params, ...data }
    return request('/api/mileageDayStatistics', JSON.stringify(params))
  },
  async getMileageMultiDay ({ commit, rootState }, data) {
    let params = {
      userToken: rootState.userToken
    }
    params = { ...params, ...data }
    return request('/api/mileageMultiDaysStatistics', JSON.stringify(params))
  },
  async getAllPerDay ({ commit, rootState }, data) {
    let params = {
      userToken: rootState.userToken
    }
    params = { ...params, ...data }
    return request('/api/allDayStatistics', JSON.stringify(params))
  },
  async getAllMultiDay ({ commit, rootState }, data) {
    let params = {
      userToken: rootState.userToken
    }
    params = { ...params, ...data }
    return request('/api/allMultiDaysStatistics', JSON.stringify(params))
  },
  async getAllFleetAccount ({ rootState }) {
    const params = {
      userToken: rootState.userToken,
      pageIndex: 0,
      pageRows: 0
    }
    return request('/api/getFleets', JSON.stringify(params))
  },
  async getLastOfflineDeviceList ({ state, rootState }, data) {
    let params = {
      userToken: rootState.userToken,
      pageIndex: state.offlineDeviceIndex,
      pageRows: state.offlineDeviceRows
    }
    params = { ...params, ...data }
    return request('api/queryOfflineDevices', JSON.stringify(params))
  },
  async getFetchOnlineStatistics ({ rootState }, searchFleetId) {
    const params = {
      userToken: rootState.userToken,
      fleetId: searchFleetId
    }
    return request('api/fetchOnlineStatistics', JSON.stringify(params))
  },
  async getDeviceList ({ state, rootState }, data = {}) {
    let params = {
      userToken: rootState.userToken,
      pageIndex: state.onlineTableIndex,
      pageRows: state.onlineTableRows
    }
    params = { ...params, ...data }
    return request('/api/getDevices', JSON.stringify(params))
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
