
const state = {
  warningList: [],
  pageIndex: 0,
  pageRows: 50,
  totalCount: 0
}

const getters = {
  pageIndex (state) {
    return state.pageIndex
  },
  pageRows (state) {
    return state.pageRows
  },
  totalCount (state) {
    return state.totalCount
  },
  warningDeviceList (state) {
    const startIndex = state.pageIndex * state.pageRows
    const endIndex = startIndex + state.pageRows
    return state.warningList.slice(startIndex, endIndex)
  }
}

const mutations = {
  setPageIndex (state, index) {
    state.pageIndex = index
  },
  updateWarningList (state, deviceList) {
    state.warningList.length = 0
    deviceList.rows.forEach(device => {
      if (device.warnings && device.warnings.length > 0) {
        const warningContent = {}
        device.warnings.forEach(warning => {
          if (!warningContent[warning.warningMessage]) {
            warningContent[warning.warningMessage] = []
          }
          warningContent[warning.warningMessage].push(warning)
        })
        Object.keys(warningContent).forEach(key => {
          const data = warningContent[key]
          const warningData = {
            deviceSerialNumber: device.deviceSerialNumber,
            carName: device.carName,
            lastSyncDate: data[data.length - 1].timestampStr,
            locateType: device.locateType,
            speed: device.speed,
            latitude: data[data.length - 1].latitude,
            longitude: data[data.length - 1].longitude,
            isProtected: device.isProtected,
            warningMessage: key,
            warningCount: data.length,
            warningData: data
          }
          state.warningList.push(warningData)
        })
      }
    })
    state.totalCount = state.warningList.length
  }
}

const actions = {

}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
